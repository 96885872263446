import { ArtworkOfTheDay } from "./artworkOfTheDay";
import { BarnesFocus } from "./barnesFocus";
import { HeroBanner } from "../shared/heroBanner";
import { HorizontalCard } from "../../components/shared/horizontalCard";
import React from "react";
import { generateImgixUrl } from "../../util/generateImgixUrl";

const backgroundSrc = generateImgixUrl(
  "sharedBackgroundImages/BF388",
  "fit=crop&auto=compress&w=1500&h=350"
);
const imgSrc = generateImgixUrl(
  "sharedHomepageImages/KBYG+Photo+Update+2023",
  "fit=crop&fm=pjpg&fp-x=0.5&fp-y=0.5&h=560&ixlib=php-2.1.1&w=1220"
);

export const Home = () => (
  <div className="wrapper" id="home">
    <HeroBanner src={backgroundSrc} header="Welcome"></HeroBanner>
    <div className="container">
      <div className="horizontal-card-list">
        <SafetyBump />
        <BarnesFocus />
        <ArtworkOfTheDay />
      </div>
    </div>
  </div>
);

const SafetyBump = () => {
  const subtitle = `We are committed to providing the best possible experience for all our guests.`;
  return (
    <HorizontalCard
      key="Know Before You Go"
      title="Know Before You Go"
      subtitle={subtitle as string}
      src={imgSrc}
      link="/user/events"
      internalLink
    >
      <p>
        Please{" "}
        <strong>
          <a href="/user/events" className="a-basic-link">
            book your tickets
          </a>
        </strong>{" "}
        in advance. Your tickets can be used for 2 consecutive days. Limited
        walk-up tickets are available on a first-come, first-served basis.
      </p>
    </HorizontalCard>
  );
};
