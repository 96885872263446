import { APIPayload } from "../../common/payloads";

// Additional params to keep a consistent option bag approach.
type AdditionalFetchParams = {
  url: string;
  data?: { [key: string]: any };
  isRedirect?: boolean;
};

/**
 * Wrapper around the Fetch browser API.
 * @param {RequestInit & AdditionalFetchParams} init Request object with additional params for convenience.
 * @returns {Promise<T>} Object of generic type from URL endpoint.
 */
export const executePayloadRequest = async <T>(
  request: RequestInit & AdditionalFetchParams
): Promise<T> => {
  try {
    const res: Response = await fetch(request.url, {
      // Default values for request.
      body: request.data ? JSON.stringify(request.data) : undefined,
      headers: { "Content-Type": "application/json" },
      method: "POST",
      // Overwrite any defaults in option bag.
      ...request,
    });

    if (request.isRedirect) {
      return;
    } else {
      // Catch 500 from server.
      if (res.status === 500) {
        // eslint-disable-next-line
        throw { message: '500 response from server.', status: 403 };
      }

      // Catch 404 from server.
      if (res.status === 404) {
        // eslint-disable-next-line
        throw { message: '404 response from server.', status: 404 };
      }
    }

    // Catch 400 from server.
    if (res.status === 400) {
      // eslint-disable-next-line
      throw (await res.json());
    }

    // Type json as potential error object.
    const json = (await res.json()) as { status?: number; message?: string };

    // If status and status is 403, throw new error.
    if (json && json.status && json.status === 403) {
      // eslint-disable-next-line
      throw { message: json.message || '403 error in HTTP service.', status: 403 };
    }

    // Cast as <T> and return.
    return json as T;
  } catch (e) {
    throw e;
  }
};

/**
 * Wrapper around the Fetch browser API.
 * @param {RequestInit & AdditionalFetchParams} init Request object with additional params for convenience.
 * @returns {Promise<Payload>} Object of generic type from URL endpoint or an error payload.
 */
export const executeStatusPayloadRequest = async <T>(
  request: RequestInit & AdditionalFetchParams
): Promise<APIPayload<T>> => {
  try {
    const res: Response = await fetch(request.url, {
      // Default values for request.
      body: request.data ? JSON.stringify(request.data) : undefined,
      headers: { "Content-Type": "application/json" },
      method: "POST",
      // Overwrite any defaults with options passed as args
      ...request,
    });

    if (request.isRedirect) {
      return;
    } else {
      const status = res.status;
      const data = (await res.json()) as T;

      return { status, data };
    }
  } catch (error) {
    const source = `Error in ${request.url} ${
      request.method || "POST"
    } request`;
    console.log(source, error);

    return {
      status: 500,
      data: {
        source,
        error,
      },
    };
  }
};
