import React from "react";

import { AccordionStep } from "../../../constants/enum";
import { CreditCardInputFields } from "../../../hooks/useCreditCardForm";
import { Form, FormSection } from "../../shared/form/form";
import { ReactComponent as LockIcon } from "../../eventDetail/icons/lock.svg";
import { RenewalItem } from "./renewalItem";

type PaymentFormType = {
  creditCardInputs: CreditCardInputFields;
  autoPopulate: (any) => void;
  autoPopulateVal: boolean;
  validationError: string;
  onClick: (data: any) => void;
  accordionStep: number;
  setAccordionStep: (accordionStep: number) => void;
};

export const PaymentForm: React.FC<PaymentFormType> = ({
  creditCardInputs,
  autoPopulate,
  autoPopulateVal,
  validationError,
  onClick,
  accordionStep,
  setAccordionStep,
}: PaymentFormType) => {
  return (
    <RenewalItem
      title={"Enter Method of Payment"}
      isActive={accordionStep <= AccordionStep.PaymentInfo}
      onClick={() => {
        setAccordionStep(AccordionStep.PaymentInfo);
      }}
      isDeactivated={
        accordionStep < AccordionStep.PaymentInfo ||
        accordionStep === AccordionStep.RenewalConfirmation
      }
      className={
        accordionStep === AccordionStep.Start
          ? "renewal__item--no-height"
          : null
      }
      id="payment"
    >
      <Form
        header="Payment Method"
        icon={<LockIcon className="input-form__icon input-form__icon--lock" />}
        className="form__payment-method"
      >
        <FormSection fields={Object.values(creditCardInputs.fields.cc)} />
        <FormSection
          fields={Object.values(creditCardInputs.fields.address)}
          autoPopulateText="Use same address as primary cardholder."
          autoPopulate={autoPopulate}
          autoPopulateVal={autoPopulateVal}
        />
      </Form>
      <div className="error-message">{validationError} </div>
      <button className="btn-barnes renewal__item--continue" onClick={onClick}>
        Continue
      </button>
    </RenewalItem>
  );
};
