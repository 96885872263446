import {
  APIPayload,
  CreateShoppingCartPayload,
  EventSummaryPayload,
  GuestPasses,
  MembershipDetails,
  MembershipLevelOptionsPayload,
  SessionUpdatePayload,
  UserOrdersPayload,
} from "../../common/payloads";
import {
  executePayloadRequest,
  executeStatusPayloadRequest,
} from "./httpService";

import { ShoppingCartObject } from "acme-ticketing-client";
import { acmeFormatDate } from "../util/formatting";
import config from "../config";
import querystring from "querystring";
import { MemberDataType } from "../../common/types";

type GuestPassArgs = {
  membershipId: number | string;
  startDate?: Date | string;
  endDate?: Date | string;
};
/**
 *
 * @param {GuestPassArgs} param - option bag, membershipId required.
 * @returns {GuestPass[]} - array of guest passes associated with membershipId and optional date range.
 * @throws - @see httpService
 */
export const getGuestPasses = async (
  args: GuestPassArgs
): Promise<GuestPasses> => {
  const { membershipId, startDate, endDate } = args;

  const guestPassKey = `guest-passes-${membershipId}`;
  const storedGuestPasses = false;

  // If we have guest passes in session storage - just return those
  if (storedGuestPasses) {
    return JSON.parse(storedGuestPasses);
  } else {
    // Set up the config
    const guestPassConfig = {
      url: `${config.guestPassUrl}/?${querystring.stringify({
        membershipId,
        startDate: startDate && endDate ? acmeFormatDate(startDate) : null,
        endDate: startDate && endDate ? acmeFormatDate(endDate) : null,
      })}`,
      method: "GET",
    };

    // Get the guest passes and store them in session storage
    const guestPasses = await executePayloadRequest<GuestPasses>(
      guestPassConfig
    );
    sessionStorage.setItem(guestPassKey, JSON.stringify(guestPasses));

    return guestPasses;
  }
};

/**
 * Get user payload, membershipId is included with request.session which is parsed on server.
 * @returns {MembershipDetails} - Membership details object associated with membershipId.
 * @throws - @see httpService
 */
export const getUserInfo: () => Promise<MembershipDetails> = async () =>
  executePayloadRequest<MembershipDetails>({
    url: "/api/user/home",
    method: "GET",
  });

/**
 * Get current orders payload (Date > today + 4 weeks out), membershipId is included with request.session which is parsed on server.
 * @returns {OrderPayload[]} - Membership details object associated with membershipId.
 * @throws - @see httpService
 */
export const getOrders: () => Promise<UserOrdersPayload> = async () =>
  executePayloadRequest<UserOrdersPayload>({
    url: "/api/user/orders",
    method: "GET",
  });

/**
 * Get past orders (Date < today.)
 * @returns {OrderPayload[]} - Membership details object associated with membershipId.
 * @throws - @see httpService
 */
export const getPastOrders: () => Promise<UserOrdersPayload> = async () =>
  executePayloadRequest<UserOrdersPayload>({
    url: "/api/user/orders/past",
    method: "GET",
  });

/**
 * Get ticket events from server.
 * @return {{ admission: EventSummaryPayload[]; other: EventSummaryPayload[] }} - Event summary payload object of ticket events.
 */
export const getEvents: () => Promise<{
  admission: EventSummaryPayload[];
  other: EventSummaryPayload[];
}> = async () =>
  executePayloadRequest<{
    admission: EventSummaryPayload[];
    other: EventSummaryPayload[];
  }>({
    url: "/api/event",
    method: "GET",
  });

/**
 * Get classes from server.
 * @return {EventSummaryPayload[]} - Event summary payload object of classes.
 */
export const getClasses: () => Promise<EventSummaryPayload[]> = async () =>
  executePayloadRequest<EventSummaryPayload[]>({
    url: "/api/event/classes",
    method: "GET",
  });

/**
 * Create shopping cart as part of membership renewal
 * @return {string | ErrorPayload}
 */
export const createCart = async (
  cart: ShoppingCartObject
): Promise<APIPayload<CreateShoppingCartPayload>> =>
  executeStatusPayloadRequest<CreateShoppingCartPayload>({
    url: "/api/renew/cart/new",
    body: JSON.stringify({ cart }),
  });

/**
 * Update existing shopping cart for membership renewal
 */
export const updateCart = async (
  id: string,
  cart: ShoppingCartObject
): Promise<APIPayload<ShoppingCartObject>> =>
  executeStatusPayloadRequest<ShoppingCartObject>({
    url: "/api/renew/cart",
    body: JSON.stringify({ id, cart }),
  });

/**
 * Gets membership levels for renewal process
 */
export const getLevels = async () =>
  executeStatusPayloadRequest<MembershipLevelOptionsPayload>({
    url: "/api/renew/levels",
    method: "GET",
  });

/**
 * Submits user input to renew membership
 */
export const submitRenewal = async (cartId, paymentDetails, acmeReqUuid) =>
  executeStatusPayloadRequest<any>({
    url: "/api/renew/submit",
    body: JSON.stringify({ cartId, paymentDetails, acmeReqUuid }),
  });

/**
 * Reset server session with updated user data
 */
export const updateUserSession = async (member: MemberDataType) =>
  executeStatusPayloadRequest<SessionUpdatePayload>({
    url: "/api/user/session",
    body: JSON.stringify(member),
  });
