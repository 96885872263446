import React, { useEffect, useState } from "react";

import { AccordionStep } from "../../../../constants/enum";
import { CreditCardInputFields } from "../../../../hooks/useCreditCardForm";
import { MembershipLevelInput } from "../../../../../common/types";
import { RenewalItem } from "../renewalItem";
import { SpinnerSuspense } from "../../../shared/spinner";
import { DonationForm } from "../donation/donationForm";
import { formatDollarAmount } from "../../../../util/formatting";

type RenewSummaryProps = {
  membershipCards: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    streetAddress1?: string;
    streetAddress2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
  }[];
  selectedLevel: MembershipLevelInput;
  creditCardInputs: CreditCardInputFields;
  includeSecondCardHolder: boolean;
  standardPrice: string;
  offerPrice: string;
  offerCode?: string;
  onClick: (data: any) => void;
  isSubmitted: boolean;
  isLoading: boolean;
  accordionStep: number;
  setAccordionStep: (accordionStep: number) => void;
  handleAddDonation: (data: any) => void;
  donationAmount: string;
  setDonationAmount: (donationAmount: string) => void;
};
export const RenewSummary: React.FC<RenewSummaryProps> = ({
  membershipCards,
  selectedLevel,
  creditCardInputs,
  includeSecondCardHolder,
  standardPrice,
  offerPrice,
  offerCode,
  onClick,
  isSubmitted,
  isLoading,
  accordionStep,
  setAccordionStep,
  handleAddDonation,
  donationAmount,
  setDonationAmount,
}: RenewSummaryProps) => {
  const [offerApplied, setOfferApplied] = useState(false);

  useEffect(() => {
    setOfferApplied(offerCode && offerPrice !== standardPrice);
  }, [offerCode, setOfferApplied, offerPrice, standardPrice]);

  const [includesDonation, setIncludesDonation] = useState(false);
  const [totalToRenew, setTotalToRenew] = useState(Number(standardPrice));

  // Format OfferPrice, StandardPrice, and Donation Amount
  let formattedOfferPrice;
  let formattedStandardPrice;
  let formattedDonationAmount;

  if (Number(offerPrice) > 0) {
    formattedOfferPrice = formatDollarAmount(offerPrice);
  }
  if (Number(standardPrice) > 0) {
    formattedStandardPrice = formatDollarAmount(standardPrice);
  }
  if (Number(donationAmount) > 0) {
    formattedDonationAmount = formatDollarAmount(donationAmount);
  }

  // Calculates and sets Total to Renew, includes donation and/or offer if applied
  useEffect(() => {
    let total = offerApplied
      ? Number(formattedOfferPrice)
      : Number(formattedStandardPrice);
    if (includesDonation) total = total + Number(formattedDonationAmount);
    setTotalToRenew(total);
  }, [
    formattedOfferPrice,
    formattedStandardPrice,
    formattedDonationAmount,
    offerPrice,
    standardPrice,
    donationAmount,
    offerApplied,
    includesDonation,
    setTotalToRenew,
  ]);

  const handleEdit = (accordionStep) => {
    setAccordionStep(accordionStep);
  };

  let buttonClassName = "btn-barnes";
  isLoading
    ? (buttonClassName = `${buttonClassName} btn-barnes--disabled`)
    : (buttonClassName = "btn-barnes");

  const formattedTotalToRenew = formatDollarAmount(totalToRenew.toString());

  return (
    <RenewalItem
      title={"Renewal Summary"}
      isActive={accordionStep <= AccordionStep.RenewalSummary}
      onClick={() => {
        setAccordionStep(AccordionStep.RenewalSummary);
      }}
      isDeactivated={
        accordionStep < AccordionStep.RenewalSummary ||
        accordionStep === AccordionStep.RenewalConfirmation
      }
      className={
        accordionStep === AccordionStep.Start
          ? "renewal__item--no-height"
          : null
      }
    >
      <div className="renewal__summary">
        <div className="renewal__summary-wrapper">
          <div className="renewal__summary-content">
            <div className="column-1">
              <h3>Renewal Level</h3>
              {selectedLevel && (
                <div>
                  <p>{selectedLevel.name}</p>
                  {offerApplied && <p>Offer applied: {offerCode}</p>}
                  {(!offerCode || !offerApplied) && (
                    <a
                      className="a-brand-link"
                      href="#coupon-code"
                      onClick={() => handleEdit(AccordionStep.MembershipLevel)}
                    >
                      Have a coupon code? Enter it here.
                    </a>
                  )}
                </div>
              )}
              <a
                href="#membership-level"
                onClick={() => handleEdit(AccordionStep.MembershipLevel)}
              >
                Edit
              </a>
              {creditCardInputs && (
                <div>
                  <h3>Billing Details</h3>
                  <p>
                    {creditCardInputs.values.firstName}{" "}
                    {creditCardInputs.values.lastName}
                  </p>
                  <p>{creditCardInputs.values.email}</p>
                  <p>{creditCardInputs.values.streetAddress1}</p>
                  <p>{creditCardInputs.values.streetAddress2}</p>
                  <p>
                    {creditCardInputs.values.city},{" "}
                    {creditCardInputs.values.state}
                  </p>
                  <p>{creditCardInputs.values.zip}</p>
                  <span onClick={() => handleEdit(AccordionStep.PaymentInfo)}>
                    Edit
                  </span>
                  <h3>Payment Method</h3>
                  <p>{creditCardInputs.fields.cc.number.maskedValue}</p>
                  <a
                    href="#payment-method"
                    onClick={() => handleEdit(AccordionStep.PaymentInfo)}
                  >
                    Edit
                  </a>
                </div>
              )}
            </div>

            <div className="column-2">
              {membershipCards && (
                <>
                  {/* Primary Cardholder */}
                  <div id="primary-cardholder">
                    <h3>Primary Cardholder</h3>
                    <p>
                      {membershipCards[0].firstName}{" "}
                      {membershipCards[0].lastName}
                    </p>
                    <p>{membershipCards[0].email}</p>
                    <p>{membershipCards[0].phoneNumber}</p>
                    <p>{membershipCards[0].streetAddress1}</p>
                    <p>{membershipCards[0].streetAddress2}</p>
                    <p>
                      {membershipCards[0].city}, {membershipCards[0].state}
                    </p>
                    <p>{membershipCards[0].zipCode}</p>
                    <a
                      href="#member-information"
                      onClick={() => handleEdit(AccordionStep.MemberInfo)}
                    >
                      Edit
                    </a>
                  </div>
                  {/* Secondary Cardholder */}
                  {includeSecondCardHolder && membershipCards[1] && (
                    <div id="secondary-cardholder">
                      <h3>Secondary Cardholder</h3>
                      <p>
                        {membershipCards[1].firstName}{" "}
                        {membershipCards[1].lastName}
                      </p>
                      <p>{membershipCards[1].email}</p>
                      <p>{membershipCards[1].phoneNumber}</p>
                      <a
                        href="#member-information"
                        onClick={() => handleEdit(AccordionStep.MemberInfo)}
                      >
                        Edit
                      </a>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="summary-total">
            <br />
            <hr />
            <div className="donation-form">
              <DonationForm
                includesDonation={includesDonation}
                setIncludesDonation={setIncludesDonation}
                donationAmount={donationAmount}
                setDonationAmount={setDonationAmount}
                handleAddDonation={handleAddDonation}
              ></DonationForm>{" "}
            </div>
            <div className="total-to-renew">
              {offerApplied ? (
                includesDonation ? (
                  // Offer applied and donation applied
                  <div className="offer-subtotal">
                    <h3>
                      Subtotal: $<del>{formattedStandardPrice}</del> <br />
                    </h3>
                    <h2> With offer: ${formattedOfferPrice}</h2>
                    <h2> + Donation: ${formattedDonationAmount}</h2>
                    <hr />
                    <h1>Total to renew: ${formattedTotalToRenew}</h1>{" "}
                  </div>
                ) : (
                  // Offer applied
                  <div className="offer-subtotal">
                    <h3>
                      Subtotal: $<del>{formattedStandardPrice}</del>
                    </h3>
                    <h2>Total to renew with offer: ${formattedOfferPrice}</h2>{" "}
                  </div>
                )
              ) : includesDonation ? (
                // Donation applied
                <div className="offer-subtotal-no-offer">
                  <h2>Total to renew: ${formattedStandardPrice}</h2>
                  <h2> + Donation: ${formattedDonationAmount}</h2>
                  <hr />
                  <h2>Total: ${formattedTotalToRenew}</h2>
                </div>
              ) : (
                // No offer or donation
                <div className="offer-subtotal-no-offer">
                  <h2>Total to renew: ${formattedStandardPrice}</h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="submit-button">
        <button
          className={buttonClassName}
          onClick={onClick}
          disabled={isLoading}
        >
          <SpinnerSuspense isLoaded={isSubmitted} className="check-in__spinner">
            Confirm Renewal
          </SpinnerSuspense>
        </button>
      </div>
    </RenewalItem>
  );
};
