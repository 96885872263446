import React, { useCallback, useEffect, useState } from "react";
import "react-day-picker/lib/style.css";
import {
  EventTimeObject,
  OrderPayload,
  ShoppingCartObject,
} from "acme-ticketing-client";
import { IdleTimerProvider } from "react-idle-timer";
import {
  APIPayload,
  EventDetailPayload,
  MembershipLevelIdentifier,
  PurchaseItem,
} from "../../../common/payloads";
import { formatTimeString } from "../../util/formatting";
import { useScrollTop } from "../../hooks/customHooks";
import { EventDetailTimeSelect } from "../eventDetail/eventDetailTimeSelect";
import {
  createReservation,
  updateReservation,
} from "../../services/kioskService";
import { ReservationObject } from "../../../common/types";
import { AccordionContainer } from "../accordionContainer";
import { EventDetailKioskConfirmation } from "./eventDetailKioskConfirmation";
import { EventDetailKioskPayment } from "./eventDetailKioskPayment";
import { EventDetailKioskCheckIn } from "./eventDetailKioskCheckIn";
import {
  useKiosk,
  AccordionStep,
  UPDATE_RESERVATION,
  UPDATE_CART,
  UPDATE_TIME,
} from "../../contexts/kioskContext";
import { KioskModals } from "./kioskModals";
import { useQueryParams } from "../../hooks/useQueryParams";
import { getOrder } from "../../services/apiRequestor";

type EventDetailKioskProps = {
  eventDetailPayload?: EventDetailPayload;
  membershipLevels?: MembershipLevelIdentifier[];
};

export const EventDetailKiosk: React.FC<EventDetailKioskProps> = ({
  eventDetailPayload,
  membershipLevels,
}: EventDetailKioskProps) => {
  useScrollTop();

  const {
    showCCInstructions,
    setShowTimeoutError,
    accordionStep,
    setStepTime,
    setStepTickets,
    setStepPayment,
    setStepConfirmation,
    setPreviousStep,
    setNextStep,
    authPurchase,
    fetchKioskTimes,
    selectedDay,
    times,
    setShowErrorModal,
    resetKiosk,
    orderState,
    orderDispatch,
    setAccordionStep,
  } = useKiosk();

  // TODO: see if we can combine kioskOrder and order
  const [kioskOrder, setKioskOrder] = useState<
    OrderPayload & { smsLink: string }
  >(null);
  const [totalTicketCount, setTotalTicketCount] = useState(0);
  const [showAvailabilityError, setShowAvailabilityError] = useState(
    orderState.selectedTime
      ? totalTicketCount >= orderState.selectedTime.availableSeats
      : false
  );
  const [optIn, setOptIn] = useState(false);
  const { orderId, step } = useQueryParams();

  const toggleOptIn = () => {
    setOptIn(!optIn);
  };

  /** Updates the selected time slot in state and advances form to next accordion step
   * @param {EventTimeObject} time Selected time slot
   */
  const handleSelectTime = (time: EventTimeObject) => {
    orderDispatch({ type: UPDATE_TIME, payload: { selectedTime: time } });
    setStepTickets();
  };

  /** Reserves the total count of tickets for the selected date and time, and
   * advances to next form step when reservation is successfully created
   */
  const handleReservation = useCallback(async (): Promise<void> => {
    const cart: ShoppingCartObject = {
      items: orderState.cartItems,
    };

    if (totalTicketCount > 0) {
      // Reserve tickets for this event
      try {
        let resp: APIPayload<ReservationObject>;
        if (orderState.reservationId) {
          resp = await updateReservation(
            orderState.reservationId,
            orderState.selectedTime.id,
            totalTicketCount,
            { ...cart, id: orderState.cartId }
          );
        } else {
          resp = await createReservation(
            orderState.selectedTime.id,
            totalTicketCount,
            cart
          );
        }

        if (resp.status !== 200) {
          setShowErrorModal(true);
        } else {
          const payload = resp.data as ReservationObject;
          orderDispatch({
            type: UPDATE_RESERVATION,
            payload: {
              reservationId: payload.reservation.id,
              cartId: payload.cart.id,
            },
          });
          setStepPayment();
        }
      } catch (e) {
        console.log("error reserving tickets", e);
        setShowErrorModal(true);
      }
    }
  }, [
    totalTicketCount,
    orderDispatch,
    orderState.reservationId,
    orderState.cartId,
    orderState.selectedTime,
    orderState.cartItems,
    setStepPayment,
    setShowErrorModal,
  ]);

  const handleTicketSelectContinue = useCallback(async () => {
    await handleReservation();
    authPurchase();
  }, [authPurchase, handleReservation]);

  /** Fetch admission event for current day or date passed in query params and
   * set selected time to the next available time slot.
   */
  useEffect(() => {
    if (eventDetailPayload) {
      fetchKioskTimes(eventDetailPayload);
    }
    // Disable linting because we want this to trigger on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Checks ticket availability */
  useEffect(() => {
    setShowAvailabilityError(
      orderState.selectedTime
        ? totalTicketCount >= orderState.selectedTime.availableSeats
        : false
    );
  }, [totalTicketCount, orderState.selectedTime]);

  /** Fetches existing order info and goes to order confirmation step when
   * orderId query param is present */
  useEffect(() => {
    const fetchOrder = async () => {
      const orderPayload = await getOrder(orderId);
      setKioskOrder(orderPayload);
      setStepConfirmation();
    };

    if (orderId) fetchOrder();
  }, [orderId, setStepConfirmation]);

  // Handles canceling an order
  const handleCancelOrder = () => {
    setShowAvailabilityError(false);
    resetKiosk(eventDetailPayload);
  };

  return (
    <div className="wrapper" id="event-detail">
      <div className="event-detail">
        <KioskModals
          showAvailabilityError={showAvailabilityError}
          setShowAvailabilityError={setShowAvailabilityError}
          eventDetailPayload={eventDetailPayload}
        />
        {/* Time selection */}
        <AccordionContainer
          title="Select time of arrival"
          isActive={accordionStep === AccordionStep.ArrivalTime}
          isDeactivated={
            accordionStep < AccordionStep.ArrivalTime ||
            accordionStep === AccordionStep.OrderConfirmation
          }
          onClick={setStepTime}
          selectionText={
            orderState.selectedTime &&
            formatTimeString(orderState.selectedTime.time)
          }
          className="kiosk"
          childClassName="kiosk"
        >
          {selectedDay && (
            <EventDetailTimeSelect
              times={times}
              selectedTime={orderState.selectedTime}
              setSelectedTime={handleSelectTime}
              isActive={accordionStep === AccordionStep.ArrivalTime}
            />
          )}
        </AccordionContainer>

        {/* Ticket type selection */}
        <AccordionContainer
          title="Select ticket types"
          isActive={accordionStep === AccordionStep.TicketType}
          isDeactivated={
            accordionStep < AccordionStep.TicketType ||
            accordionStep === AccordionStep.OrderConfirmation
          }
          className="kiosk"
          childClassName="kiosk"
          selectionText={
            totalTicketCount > 0 &&
            `${totalTicketCount} Ticket${totalTicketCount === 1 ? "" : "s"}`
          }
          onClick={setStepTickets}
        >
          {selectedDay && orderState.selectedTime && (
            <EventDetailKioskCheckIn
              membershipLevels={membershipLevels}
              setTotalTicketCount={setTotalTicketCount}
              totalTicketCount={totalTicketCount}
              onContinue={handleTicketSelectContinue}
              onClickBack={setPreviousStep}
              handleCancelOrder={handleCancelOrder}
            />
          )}
        </AccordionContainer>

        <AccordionContainer
          title="Payment"
          isActive={accordionStep === AccordionStep.Payment}
          isDeactivated={
            accordionStep < AccordionStep.Payment ||
            accordionStep === AccordionStep.OrderConfirmation
          }
          className="kiosk"
          childClassName="kiosk"
          selectionText={
            orderState.orderTotal > 0
              ? `Total: $${orderState.orderTotal}.00`
              : ""
          }
        >
          {((totalTicketCount > 0 && !showCCInstructions) ||
            step === AccordionStep.Payment) && (
            // Idle timer limits displaying the payment form for 60000ms (1 minute) and then displays the timeout modal
            // increased from 30 seconds by request of front line staff
            <IdleTimerProvider
              timeout={60000}
              onIdle={() => setShowTimeoutError(true)}
            >
              {((selectedDay &&
                orderState.selectedTime &&
                orderState.cartItems) ||
                step === AccordionStep.Payment) && (
                <EventDetailKioskPayment
                  setOrder={setKioskOrder}
                  onClickNext={setNextStep}
                  onClickBack={setPreviousStep}
                  reservationId={orderState.reservationId}
                  optIn={optIn}
                  toggleOptIn={toggleOptIn}
                  handleCancelOrder={handleCancelOrder}
                />
              )}
            </IdleTimerProvider>
          )}
        </AccordionContainer>

        {/* Completed order confirmation */}
        <AccordionContainer
          title="Order confirmation"
          isActive={accordionStep === AccordionStep.OrderConfirmation}
          isDeactivated={accordionStep < AccordionStep.OrderConfirmation}
          className="kiosk"
          childClassName="kiosk"
        >
          {/* Idle timer limits displaying the confirmation page with buttons to print tickets for 30000ms (30 seconds) and then
          displays the timeout modal */}
          <IdleTimerProvider
            timeout={30000}
            onIdle={() => setShowTimeoutError(true)}
          >
            {kioskOrder && (
              <EventDetailKioskConfirmation
                order={kioskOrder}
                onClickBack={setPreviousStep}
                eventDetailPayload={eventDetailPayload}
              />
            )}
          </IdleTimerProvider>
        </AccordionContainer>
      </div>
    </div>
  );
};
