import { useState } from "react";
import { ShoppingCartObject } from "acme-ticketing-client";

import { useCardHolderForm, CardHolderFields } from "./useCardHolderForm";
import { useCreditCardForm, CreditCardInputFields } from "./useCreditCardForm";
import { MembershipDetails } from "../../common/payloads";
import { MembershipRenewType, MembershipLevelInput } from "../../common/types";
import { getSecondCardHolderData } from "../util/renewalHelpers";

type RenewType = {
  cart: ShoppingCartObject;
  setCart: React.Dispatch<React.SetStateAction<ShoppingCartObject>>;
  renewType: MembershipRenewType;
  setRenewType: React.Dispatch<React.SetStateAction<MembershipRenewType>>;
  donationAmount: string;
  setDonationAmount: React.Dispatch<React.SetStateAction<string>>;
  selectedLevel: MembershipLevelInput;
  setSelectedLevel: React.Dispatch<React.SetStateAction<MembershipLevelInput>>;
  handleUpdateCartData: () => ShoppingCartObject;
  primaryForBilling: boolean;
  primaryCardholderInputs: CardHolderFields;
  secondaryCardholderInputs: CardHolderFields;
  includeSecondCardHolder: boolean;
  setIncludeSecondCardHolder: React.Dispatch<React.SetStateAction<boolean>>;
  creditCardInputs: CreditCardInputFields;
  usePrimaryForBilling: () => void;
};

/**
 * Contains state and logic for the Renew component
 */
export const useRenew = (user: MembershipDetails): RenewType => {
  // Cart State
  const [cart, setCart] = useState<ShoppingCartObject>(null);
  // Renewal type
  const [renewType, setRenewType] =
    useState<MembershipRenewType>("MembershipRenewal");
  // Donation amount
  const [donationAmount, setDonationAmount] = useState("0");
  // Selected membership level
  const [selectedLevel, setSelectedLevel] =
    useState<MembershipLevelInput>(null);
  // Member information state
  const primaryCardholderInputs = useCardHolderForm();
  const secondaryCardholderInputs = useCardHolderForm();
  // State for managing whether or not the selected level includes a second card holder
  const [includeSecondCardHolder, setIncludeSecondCardHolder] = useState(false);
  // Payment information state
  const creditCardInputs = useCreditCardForm();
  const [primaryForBilling, setPrimaryForBilling] = useState(false);

  /**
   * Handler for auto populating primary cardholder for billing address.
   */
  const usePrimaryForBilling = () => {
    const usePrimary = !primaryForBilling;

    if (usePrimary) {
      for (const [fieldName, field] of Object.entries(
        creditCardInputs.fields.address
      )) {
        field.setValue(primaryCardholderInputs.values[fieldName]);
      }

      // Set values where field names do not match
      creditCardInputs.fields.address.phoneNumber.setValue(
        primaryCardholderInputs.values.phone
      );
      creditCardInputs.fields.address.address1.setValue(
        primaryCardholderInputs.values.streetAddress1
      );
      creditCardInputs.fields.address.address2.setValue(
        primaryCardholderInputs.values.streetAddress2
      );
      creditCardInputs.fields.address.zipCode.setValue(
        primaryCardholderInputs.values.zipCode
      );
    } else {
      Object.values(creditCardInputs.fields.address).map((field) =>
        field.setValue("")
      );
    }

    setPrimaryForBilling(usePrimary);
  };

  /**
   * Handler for updating cart data
   */
  const handleUpdateCartData = (): ShoppingCartObject => {
    // Create cart item from local state
    const membershipCards = [
      {
        cardType: "primary",
        ...primaryCardholderInputs.values,
        constituentImportId: user.cardholders[0].constituentImportId,
      },
    ];

    // Add secondary card holder if membership includes 2 cardholders and info has been given for second card holder
    if (includeSecondCardHolder) {
      const secondaryMemberCard = getSecondCardHolderData(
        secondaryCardholderInputs.values,
        primaryCardholderInputs.values.firstName,
        primaryCardholderInputs.values.lastName,
        user.cardholders[1]
      );

      membershipCards.push(secondaryMemberCard);
    }

    const items: any[] = [
      {
        quantity: 1,
        itemType: renewType,
        membershipInfo: {
          ...selectedLevel,
          membershipCards,
        },
      },
    ];

    if (donationAmount !== "0") {
      items.push({ amount: donationAmount });
    }

    const data: ShoppingCartObject = {
      ...cart,
      items,
    };

    return data;
  };

  return {
    cart,
    setCart,
    renewType,
    setRenewType,
    donationAmount,
    setDonationAmount,
    selectedLevel,
    setSelectedLevel,
    handleUpdateCartData,
    primaryForBilling,
    primaryCardholderInputs,
    secondaryCardholderInputs,
    includeSecondCardHolder,
    setIncludeSecondCardHolder,
    creditCardInputs,
    usePrimaryForBilling,
  };
};
