// meta tag content
export const META_TITLE = "Barnes Digital Membership";
export const META_IMAGE =
  "https://s3.amazonaws.com/barnes-image-repository/images/5003_92ad85ffeb13d697_n.jpg";
export const META_DESCRIPTION =
  "The Barnes Foundation in Philadelphia is home to one of the world's greatest collections of impressionist, post-impressionist and early modern paintings.";
export const META_PLACENAME = "Barnes Foundation";
export const CANONICAL_ROOT = "https://members.barnesfoundation.org";

// Email domains included in the kiosk keyboard
export const EMAIL_DOMAINS = [
  "@gmail.com",
  "@yahoo.com",
  "@comcast.net",
  "@aol.com",
  "@hotmail.com",
  "@verizon.net",
];
