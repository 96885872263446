import React, { useContext, useEffect, useState } from "react";

import { HeroBanner } from "../shared/heroBanner";
import { Link } from "react-router-dom";
import { MembershipDetails } from "../../../common/payloads";
import { AuthContext } from "../../contexts/authContext";
import {
  getContactInfo,
  ContactInfoType,
} from "../../services/graphCmsService";
import { generateImgixUrl } from "../../util/generateImgixUrl";

const backgroundSrc = generateImgixUrl(
  "sharedBackgroundImages/BF1179",
  "fit=crop&auto=compress&w=1500&h=350"
);

type SettingProps = { user: MembershipDetails };
export const Settings: React.FC<SettingProps> = ({ user }: SettingProps) => {
  const {
    cardholders: [
      {
        name,
        membershipId,
        streetAddress1,
        state,
        zipCode,
        email,
        phoneNumber,
      },
    ],
  } = user;
  // const { promptForPushNotifications } = useContext(PushNotificationContext);
  const { permissions } = useContext(AuthContext);
  const [isEdit, setIsEdit] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState({
    edit: phoneNumber,
    save: "",
  });
  const [contactInfo, setContactInfo] = useState<ContactInfoType>();
  const { permsGroup } = useContext(AuthContext);

  useEffect(() => {
    const handleExit = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setIsEdit(false);
      }
    };

    window.addEventListener("keydown", handleExit);

    return () => {
      window.removeEventListener("keydown", handleExit);
    };
  }, [isEdit]);

  useEffect(() => {
    const fetchContactInfo = async () => {
      const contact = await getContactInfo(permsGroup);
      setContactInfo(contact);
    };

    permsGroup && fetchContactInfo();
  }, [setContactInfo, permsGroup]);

  return (
    <div className="wrapper" id="settings">
      <HeroBanner src={backgroundSrc} header="Settings" />
      {/** User information. */}
      <div className="container">
        <div className="settings">
          <p className="settings__text">
            To update your membership contact information, please email us
            at&nbsp;
            <a
              className="a-brand-link"
              href={`mailto:${
                contactInfo ? contactInfo.email : "members@barnesfoundation.org"
              }?subject=Update Membership Contact Information: ${membershipId}`}
            >
              {contactInfo ? contactInfo.email : "members@barnesfoundation.org"}
              &nbsp;
            </a>
            or call&nbsp;
            <a
              className="a-brand-link"
              href={`tel:${contactInfo ? contactInfo.phone : "215-278-7100"}`}
            >
              {contactInfo ? contactInfo.phone : "215-278-7100"}
            </a>
            .
          </p>
          <span className="settings__description settings__description--title">
            Member Information
          </span>
          {name && streetAddress1 && state && zipCode ? (
            <>
              <span className="settings__description settings__description--sub-header settings__description--border-top">
                Address
              </span>
              <span className="settings__description settings__description--detail ">
                {name}
              </span>
              <span className="settings__description settings__description--detail">
                {streetAddress1}
              </span>
              <span className="settings__description settings__description--detail">
                {state}, {zipCode}
              </span>
            </>
          ) : (
            <span className="settings__description settings__description--sub-header settings__description--border-top">
              No address on file
            </span>
          )}
          <span className="settings__description settings__description--sub-header settings__description--border-top">
            Contact
          </span>
          <span className="settings__description settings__description--detail">
            <strong>Phone #: </strong>
            {isEdit ? (
              <input
                className="settings__input"
                type="tel"
                pattern="[0-9\s]{11}"
                maxLength={11}
                inputMode="numeric"
                onChange={({ target: { value } }) =>
                  setNewPhoneNumber((phoneNumber) => ({
                    ...phoneNumber,
                    edit: value,
                  }))
                }
                value={newPhoneNumber.edit}
              />
            ) : (
              newPhoneNumber.save || phoneNumber
            )}
          </span>
          <span className="settings__description settings__description--detail">
            <strong>email: </strong>
            {email}
          </span>
          {isEdit && (
            <button
              className="btn-barnes settings__button settings__button--save"
              onClick={() => {
                // Update edit phone number to be saved number.
                setNewPhoneNumber((phoneNumber) => ({
                  ...phoneNumber,
                  save: phoneNumber.edit,
                }));
                setIsEdit(false);
              }}
            >
              Save
            </button>
          )}
          {/* Filter out renewal link if permissions.renewal is false */}
          {permissions.renewal && (
            <span className="settings__description settings__description--sub-header settings__description--border-top">
              <Link to="/user/settings/renew">Renew Membership</Link>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
