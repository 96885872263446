import React from "react";
import { IPromotion } from "../../../../common/vipPayloads";

export const PromotionMessage = ({
  promotion,
  transactionQualifies,
}: {
  promotion: IPromotion;
  transactionQualifies: boolean;
}) => {
  const messageText = transactionQualifies
    ? promotion.promotionQualifiesMessage
    : promotion.promotionHintMessage;

  const qClass = transactionQualifies ? "qualifies" : "n_qualifies";

  return (
    <>
      {
        /** Display the add on once the transaction qualifies */
        transactionQualifies && (
          <p className="check-in__availability">
            {promotion.redemptionItems.quantity} x redemption for promotion{" "}
            {promotion.offer}
          </p>
        )
      }
      <span className={`check-in__availability ${qClass}`}>{messageText}</span>
    </>
  );
};
