import React, { useEffect, useRef, useState } from "react";

type CardHeaderProps = {
  imageWrapperHeight: number | void;
  header?: string;
  subheader?: string;
  link?: string;
  internalLink?: boolean;
};
export const CardHeader: React.FC<CardHeaderProps> = ({
  imageWrapperHeight,
  header,
  subheader,
  link,
  internalLink,
}: CardHeaderProps) => {
  const ref = useRef<HTMLDivElement>();

  const [isResizeFont, setIsResizeFont] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  // Set is loaded after first render.
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  // On mount, see if text fits in container, if not then setState to true.
  useEffect(() => {
    if (isLoaded) {
      // If text is bigger than it's current boundary
      const isResized =
        imageWrapperHeight !== null &&
        ref.current &&
        imageWrapperHeight < ref.current.offsetHeight &&
        isLoaded;

      setIsResizeFont(isResized);
    }
  }, [isLoaded, imageWrapperHeight]);

  let headerStyle: React.CSSProperties = {};
  if (isResizeFont) {
    headerStyle = {
      ...headerStyle,
      fontSize: "27.5px",
    };
  }

  return (
    <>
      {header && (
        <h1
          className="card__title"
          style={{
            ...headerStyle,
            opacity: isLoaded ? 1 : 0,
          }}
          ref={ref}
        >
          {link ? (
            <a
              href={link}
              target={!internalLink && "_blank"}
              rel="noopener noreferrer"
            >
              {header}
            </a>
          ) : (
            <>{header}</>
          )}
        </h1>
      )}
      {subheader && (
        <h2
          className="card__subtitle"
          style={{
            ...headerStyle,
            opacity: isLoaded ? 1 : 0,
          }}
          ref={ref}
        >
          {subheader}
        </h2>
      )}
    </>
  );
};
