import cardValidator from "card-validator";

/**
 * @param {string} number - credit card number to be validated
 * @returns {boolean} - whether or not the given credit card number is valid
 */
export const validateNumber = (number: string): boolean => {
  return cardValidator.number(number).isValid;
};

/**
 * @param {string} expirationDate - expiration date to be validated
 * @returns {boolean} - whether or not the given date is valid
 */
export const validateExpirationDate = (expirationDate: string): boolean => {
  return cardValidator.expirationDate(expirationDate).isValid;
};

/**
 * @param {string} number - credit card number associated with the cvc
 * @param {string} cvc - cvc number to be validated
 * @returns {boolean} - whether or not the given cvc is valid
 */
export const validateCVC = ({
  number,
  cvc,
}: {
  number: string;
  cvc: string;
}): boolean => {
  const card = cardValidator.number(number);
  const cvcLength = card.card && card.card.type === "american-express" ? 4 : 3;
  return cardValidator.cvv(cvc, cvcLength).isValid;
};

/**
 * @param {string} number - credit card number to be validated
 * @param {string} expDate - expiration date associated with the credit card
 * @param {string} cvc - cvc associated with the credit card
 * @returns {boolean} - whether or not the credit card, exp date, and cvc are all valid
 */
export const validateCreditCard = (
  number: string,
  expDate: string,
  cvc: string
): boolean => {
  const isCCNumberValid = number && validateNumber(number);
  const isExpDateValid = expDate && validateExpirationDate(expDate);
  const isCVCValid = cvc && validateCVC({ number, cvc });

  return isCCNumberValid && isExpDateValid && isCVCValid;
};

/**
 * Validates the presence of text for an input field
 * @param {string} text - text to be validated
 * @returns {boolean} - whether or not the text is valid
 */
export const validateText = (text: string): boolean => {
  return text.length > 0;
};

/**
 * @param {string} email - email to be validated
 * @returns {boolean} - whether or not the email is valid
 */
export const validateEmail = (email: string): boolean => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

/**
 * @param {string} zipCode - zip code to be validated
 * @returns {boolean} - whether or not the zip code is valid
 */
export const validateZip = (zipCode: string): boolean => {
  return zipCode.length >= 3;
};

type Field = {
  value: string;
  validate: () => boolean;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
};

type CardHolder = {
  primary: boolean;
  firstName: Field;
  lastName: Field;
  email?: Field;
  phone?: Field;
  address1?: Field;
  address2?: Field;
  city?: Field;
  state?: Field;
  zip?: Field;
};

/**
 * Validates the fields for cardholder information
 * @param {CardHolder} cardholder
 * @returns {boolean} - whether or not the cardholder information is valid
 */
export const validateCardHolder = ({
  primary,
  firstName,
  lastName,
  email,
  phone,
  address1,
  address2,
  city,
  state,
  zip,
}: CardHolder): boolean => {
  const shouldValidate = (field: Field): boolean =>
    field.value && field.value.length > 0;

  const validate = (field: Field, required?: boolean): boolean => {
    if (shouldValidate(field) || required) {
      const valid = field.validate();
      field.setIsValid(valid);
      return valid;
    } else {
      return true;
    }
  };

  const required =
    primary || shouldValidate(firstName) || shouldValidate(lastName);

  const firstNameValid = validate(firstName, required);
  const lastNameValid = validate(lastName, required);
  const emailValid = validate(email, primary);
  const phoneValid = validate(phone, primary);
  const address1Valid = validate(address1, primary);
  const address2Valid = validate(address2, false);
  const cityValid = validate(city, primary);
  const stateValid = validate(state, primary);
  const zipValid = validate(zip, primary);

  return (
    firstNameValid &&
    lastNameValid &&
    emailValid &&
    phoneValid &&
    address1Valid &&
    address2Valid &&
    cityValid &&
    stateValid &&
    zipValid
  );
};

/**
 * Validates dollar amount
 * @param {string} amount - string that represents a dollar amount to be validated
 * @returns {boolean} - whether or not the string is a valid dollar amount, decimal and commas optional
 */
export const validateDollarAmount = (amount: string): boolean => {
  return !!amount.match(
    /^\$?([1-9]\d{0,2}(,\d{3})*(\.\d{2})?|[1-9]\d*(\.\d{2})?|0?\.(?!00)\d{2})$/
  );
};

/**
 * Validates the fields for cardholder information
 * @param {CardHolder} cardholder
 * @returns {boolean} - whether or not the cardholder information is valid
 */
export const validatePII = ({
  addressRequired,
  firstName,
  lastName,
  email,
  phone,
  address1,
  address2,
  city,
  state,
  zip,
}: Omit<CardHolder, "primary"> & { addressRequired: boolean }): boolean => {
  const shouldValidate = (field: Field): boolean =>
    field.value && field.value.length > 0;

  const validate = (field: Field, required?: boolean): boolean => {
    if (shouldValidate(field) || required) {
      const valid = field.validate();
      field.setIsValid(valid);
      return valid;
    } else {
      return true;
    }
  };

  const required =
    addressRequired || shouldValidate(firstName) || shouldValidate(lastName);

  const firstNameValid = validate(firstName, true);
  const lastNameValid = validate(lastName, true);
  const emailValid = validate(email, true);
  const phoneValid = validate(phone, true);
  const address1Valid = validate(address1, addressRequired);
  const address2Valid = validate(address2, false);
  const cityValid = validate(city, addressRequired);
  const stateValid = validate(state, addressRequired);
  const zipValid = validate(zip, true);

  return (
    firstNameValid &&
    lastNameValid &&
    emailValid &&
    phoneValid &&
    address1Valid &&
    address2Valid &&
    cityValid &&
    stateValid &&
    zipValid
  );
};
