import { PaymentMethod } from "../eventDetailCheckIn/usePaymentMethod";
import cardValidator from "card-validator";

// "Email Regex that 99.99% works."
// eslint-disable-next-line
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Possible keys where we can have validation errors.
 * This is all of PaymentMethod except creditCardType, name, and address.
 */
type PaymentMethodErrorKeys = keyof Omit<
  PaymentMethod["payment"],
  "firstName" | "lastName"
>;
type PaymentMethodErrors = {
  payment: {
    [key in PaymentMethodErrorKeys]: boolean;
  };

  contact: {
    email: boolean;
  };

  membership: {
    email: boolean;
  };
};

/**
 * Map over all qualities of payment method that have validation flags.
 * @param {PaymentMethod} paymentMethod - payment method object.
 */
export const validateCreditCard = ({
  payment: { creditCard, expirationDate, cvc },

  contact: { email: contactEmail },

  membership: { email: membershipEmail },
}: PaymentMethod) => {
  let isValid: PaymentMethodErrors = {
    payment: {
      creditCard: true,
      expirationDate: true,
      cvc: true,
    },

    contact: {
      email: true,
    },

    membership: {
      email: true,
    },
  };

  const card = cardValidator.number(creditCard.value);

  // Validate credit card.
  if (creditCard.value && !card.isValid) {
    isValid.payment.creditCard = false;
  }

  // Validate expiry date.
  if (
    expirationDate.value &&
    !cardValidator.expirationDate(expirationDate.value).isValid
  ) {
    isValid.payment.expirationDate = false;
  }

  const cvcLength = card.card && card.card.type === "american-express" ? 4 : 3;
  // Validate cvc.
  if (cvc.value && !cardValidator.cvv(cvc.value, cvcLength).isValid) {
    isValid.payment.cvc = false;
  }

  // Test contact email
  if (contactEmail.value && !EMAIL_REGEX.test(contactEmail.value)) {
    isValid.contact.email = false;
  }

  // Test regex email
  if (membershipEmail.value && !EMAIL_REGEX.test(membershipEmail.value)) {
    isValid.membership.email = false;
  }

  return isValid;
};

/**
 * Utility method to check if payment method has been completed.
 * @param {PaymentMethod} paymentMethod - payment method, all keys except for creditCardType are checked.
 * @returns {boolean} true if completed, otherwise false.
 */
export const checkPaymentMethodComplete = ({
  membership,
  ...paymentMethod
}: PaymentMethod) =>
  // Check over all attributes in payment method and insure that they have a value.
  // For anything that has an isValid flag, check if is !== false.
  // This prevents false positives for isValid when undefined.
  Object.values(paymentMethod).every((field) =>
    Object.values(field).every(
      (attribute: any) => attribute.isValid !== false && attribute.value
    )
  );

/**
 * Utility function to mask credit card number.
 * @param {string} creditCardNumber - credit card number to mask.
 * @returns {string} masked credit card number in form of "•••• •••• •••• 1234"
 */
export const maskCreditCard = (creditCardNumber: string) => {
  const mask = creditCardNumber
    .substring(0, creditCardNumber.length - 4)
    .replace(/[0-9]/g, "•");
  const lastFourDigits = creditCardNumber.substring(
    creditCardNumber.length - 4,
    creditCardNumber.length
  );

  return `${mask}${lastFourDigits}`;
};
