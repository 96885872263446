import { escapeQuotes } from "../../../util/formatting";

interface OrderConfirmationData {
  dataLayer: {
    event: "purchase";
    ecommerce: {
      purchase: {
        actionField: {
          id: string;
          affiliation: string;
          revenue: string; //no commas for orders over 1000
          tax: string;
          shipping: string;
          coupon: string; //if applicable, else ''
        };
        products: {
          name: string; //special characters must be escaped
          id: string;
          price: string; //unit price
          brand: string;
          category: string;
          variant: string;
          quantity: number;
        }[];
      };
    };
  };
}

export const getOrderConfirmationData = (
  checkoutResponse,
  promotion
): OrderConfirmationData => {
  return {
    dataLayer: {
      event: "purchase",
      ecommerce: {
        purchase: {
          actionField: {
            id: checkoutResponse.orderNumber,
            affiliation: "",
            revenue: checkoutResponse.paidAmount,
            tax: "",
            shipping: "",
            coupon: promotion ? promotion.offer : "",
          },
          products: checkoutResponse.orderItems.map((item) => ({
            name:
              item.ticketingTypeName === "Enrollment"
                ? "Program Ticket"
                : item.eventName,
            id: item.itemId ? item.itemId : "",
            price: item.discountedUnitPrice ? item.discountedUnitPrice : "",
            brand: `${escapeQuotes(item.eventName)}`,
            category:
              item.ticketingTypeName === "Enrollment"
                ? "Member/Ticket"
                : `${item.eventName}\/${item.displayName}`,
            variant:
              item.ticketingTypeName === "Enrollment"
                ? "Ticket"
                : item.displayName,
            quantity: item.quantity,
          })),
        },
      },
    },
  };
};
