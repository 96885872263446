import React, { Fragment, useCallback, useEffect, useState } from "react";

import {
  MembershipLevelIdentifier,
  MembershipPurchase,
  PurchaseItem,
} from "../../../common/payloads";
import { CheckInCounter } from "../eventDetail/eventDetailCheckIn/checkInCounter";
import { EventDetailConversion } from "../eventDetail/eventDetailCheckIn/eventDetailConversion";
import {
  filterKioskUpsellPurchaseItems,
  filterPurchaseItems,
  getDiscountQuantity,
  getStandardQuantity,
} from "../eventDetail/helpers/updatePurchaseItemHelper";
import { KioskModal } from "./kioskModals";
import {
  UPDATE_CART,
  UPDATE_UPSELL,
  useKiosk,
} from "../../contexts/kioskContext";
import { upsellPriceCalculator } from "../eventDetail/helpers/upsellPriceCalculator";
import { KioskButtons } from "./kioskButtons";
import { EventDetailMembershipUpsellKiosk } from "./eventDetailMembershipUpsellKiosk";

type EventDetailCheckInProps = {
  onContinue: (purchase: {
    items: (PurchaseItem | MembershipPurchase)[];
  }) => void;
  membershipLevels?: MembershipLevelIdentifier[];
  setTotalTicketCount;
  totalTicketCount: number;
  onClickBack: () => void;
  handleCancelOrder: () => void;
};

export const EventDetailKioskCheckIn: React.FC<EventDetailCheckInProps> = ({
  membershipLevels,
  setTotalTicketCount,
  totalTicketCount,
  onContinue,
  onClickBack,
  handleCancelOrder,
}: EventDetailCheckInProps) => {
  const { orderState, orderDispatch } = useKiosk();
  const {
    availableSeats,
    event: {
      priceList: { prices },
      id: eventId,
      name: eventName,
      startTime: eventTime,
      customFields,
    },
  } = orderState.selectedTime;

  const [availabilityError, setAvailabilityError] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [subTotal, setSubTotal] = useState(0);

  // This reduce function creates an object where all potential Ticket Types have a corresponding PurchaseItem.
  const [purchaseItems, setPurchaseItems] = useState<{
    [key: string]: PurchaseItem;
  }>(
    prices.reduce(
      (acc, { personType: { name, id }, price }) => ({
        ...acc,
        [name]: {
          eventId,
          eventName,
          eventTime,
          itemType: "Event",
          ignoreEntitlements: false,
          eventCategory: customFields.find((field) => field.name === "Category")
            .value,
          ticketingTypeName: name,
          ticketingTypeId: id,
          quantity: 0,
          unitPrice: `${price}.00`,
        },
      }),
      {}
    )
  );

  /** Update quantity for specific ticket type, which is lexically scoped inside
   * the update callback.
   * @param {string} name Type of ticket to update
   * @param {number} quantity New ticket quantity
   */
  const updatePurchaseItems = useCallback(
    (name: string, quantity: number) => {
      let items = { ...purchaseItems }; // Current list of purchase items, before adding new ticket total
      items[name] = { ...purchaseItems[name], quantity }; // Update quantity of the item to the new total
      setPurchaseItems(items);
      const cart = filterPurchaseItems(items, false);
      orderDispatch({ type: UPDATE_CART, payload: { cartItems: cart.items } });
    },
    [purchaseItems, orderDispatch]
  );

  // Get individual ticket totals
  const getQuantity = useCallback(
    (name: string, type: "Standard" | "Discount" | "Combined"): number => {
      switch (type) {
        case "Standard":
          return getStandardQuantity(purchaseItems, name);
        case "Discount":
          return getDiscountQuantity(purchaseItems, name);
        case "Combined":
          return purchaseItems[name] ? purchaseItems[name].quantity : 0;
      }
    },
    [purchaseItems]
  );

  const handleContinue = () => {
    let purchase: { items: (MembershipPurchase | PurchaseItem)[] };

    if (orderState.upsell) {
      const items = filterKioskUpsellPurchaseItems(
        purchaseItems,
        orderState.upsell.info.name
      );

      purchase = {
        items: [
          {
            quantity: 1,
            itemType: "MembershipPurchase",
            membershipInfo: {
              membershipCategoryId:
                orderState.upsell.identifiers.membershipCategoryId,
              membershipOfferingId:
                orderState.upsell.identifiers.membershipOfferingId,
              pricePointId: orderState.upsell.identifiers.pricePointId,
              isGift: false,
              membershipCards: [
                {
                  cardType: "primary",
                  city: "",
                  country: "",
                  email: "",
                  firstName: "",
                  lastName: "",
                  phoneNumber: "",
                  state: "",
                  streetAddress1: "",
                  streetAddress2: "",
                  zipCode: "",
                },
              ],
            },
          },
          ...items.items,
        ],
      };
    } else {
      const items = filterPurchaseItems(purchaseItems, false);
      purchase = items;
    }

    onContinue(purchase);
  };

  // Determine if user is over available ticket quantity and calculate the total
  useEffect(() => {
    const purchase = filterPurchaseItems(purchaseItems);
    const totalCount = purchase.items.reduce(
      (acc, { quantity }) => acc + quantity,
      0
    );
    setTotalTicketCount(totalCount);

    const totalCost = purchase.items.reduce(
      (acc, { unitPrice, quantity }) => acc + parseInt(unitPrice) * quantity,
      0
    );
    setSubTotal(totalCost);

    if (orderState.upsell) {
      const {
        event: {
          priceList: { prices },
          id: eventId,
          name: eventName,
          startTime: eventTime,
          customFields,
        },
      } = orderState.selectedTime;

      const purchaseItems: {
        [key: string]: PurchaseItem;
      } = prices.reduce((acc, { personType: { name, id }, price }) => {
        const item = purchase.items.find(
          (item: PurchaseItem) => item.ticketingTypeName === name
        );

        return {
          ...acc,
          [name]: {
            eventId,
            eventName,
            eventTime,
            itemType: "Event",
            ignoreEntitlements: false,
            eventCategory: customFields.find(
              (field) => field.name === "Category"
            ).value,
            ticketingTypeName: name,
            ticketingTypeId: id,
            quantity: item ? item.quantity : 0,
            unitPrice: `${price}.00`,
          },
        };
      }, {});

      const totalCost =
        parseInt(orderState.upsell.info.price) +
        upsellPriceCalculator(purchaseItems, orderState.upsell.info.name).price;

      setSubTotal(totalCost);
    } else {
      const totalCost = purchase.items
        ? purchase.items.reduce(
            (acc, { unitPrice, quantity }) =>
              acc + parseInt(unitPrice) * quantity,
            0
          )
        : 0;

      setSubTotal(totalCost);
    }
  }, [
    purchaseItems,
    setTotalTicketCount,
    setSubTotal,
    orderState.selectedTime,
    orderState.upsell,
  ]);

  // Update purchase item object when cart items change
  useEffect(() => {
    if (purchaseItems) {
      const updatedPurchaseItems = {};

      for (const ticketType in purchaseItems) {
        updatedPurchaseItems[ticketType] = {
          ...purchaseItems[ticketType],
          eventId,
          eventTime,
        };
      }
      setPurchaseItems(updatedPurchaseItems);
    }
    // Disabling linting for this line because we do not want to include purchaseItems
    // in the deps array because that causes too many triggers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, eventTime]);

  return availableSeats ? (
    <Fragment>
      <div className="event-detail__select-area check-in">
        <div className="input-form__header-wrapper">
          <h2 className="input-form__header">
            The next available time slot has {availableSeats || "no"} ticket
            {availableSeats === 1 ? "" : "s"} available.
            <br /> {/* This is an alternative to the back button */}
            {/* {isKioskCheckout && (
            <p>
              Interested in a later time slot?{" "}
              <a onClick={setAccordionStep(AccordionStep.TicketTime)}>
                Select time of arrival.
              </a>
            </p>
          )} */}
          </h2>
        </div>

        {prices.map(
          ({ personType: { name, description, id }, price, discount }) => {
            let checkInTicketTypeClassName = "check-in__ticket-type";
            if (totalTicketCount === availableSeats) {
              checkInTicketTypeClassName = `${checkInTicketTypeClassName} check-in__ticket-type--max-quantity-error`;
            }

            return (
              <Fragment key={name}>
                <CheckInCounter
                  update={(quantity) => {
                    updatePurchaseItems(name, quantity);
                  }}
                  value={purchaseItems[name].quantity}
                  availableSeats={availableSeats}
                  totalTicketCount={totalTicketCount}
                  name={name}
                  description={description}
                  price={price}
                  discount={discount}
                  className={checkInTicketTypeClassName}
                  applyTicketLimit={false}
                  ticketQuantityLimit={availableSeats}
                  getQuantity={getQuantity}
                  errors={errors[id]}
                  isKiosk={true}
                />
              </Fragment>
            );
          }
        )}
      </div>
      {/** Membership Upsell */}
      {membershipLevels && (
        <EventDetailMembershipUpsellKiosk
          purchaseItems={purchaseItems}
          totalPrice={subTotal}
          setUpsell={(upsell) =>
            orderDispatch({ type: UPDATE_UPSELL, payload: { upsell } })
          }
          membershipLevels={membershipLevels}
          onContinue={handleContinue}
        />
      )}

      <EventDetailConversion
        purchaseItems={purchaseItems}
        upsellInfo={orderState.upsell ? orderState.upsell.info : undefined}
        promoItem={null}
        isKioskCheckout={true}
      />
      <div className="event-detail__select-area check-in">
        <div className="check-in__disclaimer kiosk">
          <div className="check-in__total kiosk">Total: ${subTotal}.00</div>
        </div>
        {Object.values(errors).length > 0 && (
          <span className="check-in__error-message">
            There was a problem with the order: please check your ticket
            selection.
          </span>
        )}
        {availabilityError && (
          <span className="check-in__error-message">
            Your order of {totalTicketCount} tickets exceeds the number of
            available tickets for this time. There are {availableSeats} tickets
            remaining. Please lower the number of tickets or select another
            time.
          </span>
        )}

        <KioskButtons
          handleContinue={handleContinue}
          onClickBack={onClickBack}
          disabled={totalTicketCount > availableSeats || totalTicketCount === 0}
          continueText="Continue to Payment"
          backText="Back to Select Time of Arrival"
          handleReset={handleCancelOrder}
          isLoaded={true} // no spinner needed here
        />
      </div>
    </Fragment>
  ) : (
    <KioskModal
      header={"There are no available tickets at this time."}
      body={
        "Please try another time slot, or check in with the box office to determine options for your visit. Thank you!"
      }
      onContinue={onClickBack}
      continueText={"View available time slots"}
      dismissable={true}
    />
  );
};
