import classnames from "classnames";
import React, { Fragment } from "react";
import { useKiosk } from "../../contexts/kioskContext";
import { formatSingleDate } from "../../util/formatting";
import { OrderPayload } from "acme-ticketing-client";

export type TicketItem = {
  ticketTypeName: string;
  quantity: number;
};

type KioskTicketDetailProps = {
  eventName?: string;
  eventDate?: Date | string;
  checkInDate?: string;
  ticketItems: TicketItem[];
  orderNumber?: string;
  className?: string;
  includeTotal?: boolean;
};

type KioskReceiptDetailProps = {
  order: OrderPayload;
};

export const KioskTicketDetail: React.FC<KioskTicketDetailProps> = ({
  eventName,
  eventDate,
  checkInDate,
  ticketItems,
  orderNumber,
  className,
  includeTotal = true,
}: KioskTicketDetailProps) => {
  const { orderState } = useKiosk();

  let ticketCardClassName = "kiosk-ticket-details";

  return (
    <div className={classnames(ticketCardClassName, className)}>
      <div className={`${ticketCardClassName}__content`}>
        {orderNumber && (
          <Fragment>
            <h3 className={`${ticketCardClassName}__header`}>
              Order #{orderNumber}
            </h3>
            <br />
          </Fragment>
        )}

        {eventName && (
          <h2 className={`${ticketCardClassName}__header`}>{eventName}</h2>
        )}

        {eventDate && (
          <h4 className={`${ticketCardClassName}__date`}>
            {formatSingleDate(eventDate)} at{" "}
            {formatSingleDate(eventDate, "h:MM TT")}
          </h4>
        )}

        {ticketItems.map((orderItem) => (
          <div
            className={`${ticketCardClassName}__item`}
            key={orderItem.ticketTypeName}
          >
            <h4 className={`${ticketCardClassName}__detail`}>
              {orderItem.quantity} {orderItem.ticketTypeName}
            </h4>
          </div>
        ))}

        {includeTotal && orderState.orderTotal && (
          <h3>Total Paid: ${orderState.orderTotal}.00</h3>
        )}

        {checkInDate && (
          <span className={`${ticketCardClassName}__detail`}>
            Redeemed on: {formatSingleDate(checkInDate)}
          </span>
        )}
      </div>
    </div>
  );
};

export const KioskReceiptDetail: React.FC<KioskReceiptDetailProps> = ({
  order,
}: KioskReceiptDetailProps) => {
  const { orderState } = useKiosk();
  const ticketCardClassName = "";

  return (
    <div className={classnames(ticketCardClassName, "receipt")}>
      <div className={`${ticketCardClassName}__content`}>
        {/* Itemized list */}
        <div className="receipt__section">
          <div className="receipt__section__header">
            --Items----------------------------------------------------------------------------------------------------
          </div>
          <div className="receipt__section__content">
            {order.eventItems.map((eventItem) => {
              return eventItem.items.map((item, i) => {
                return (
                  <div key={i} className="receipt__section__content--row">
                    <div>
                      {item.quantity} {item.ticketTypeName}
                    </div>
                    <div>${item.discountedSubtotal}</div>
                  </div>
                );
              });
            })}
          </div>
        </div>

        {/* Total */}
        <div className="receipt__section">
          <div className="receipt__section__header">
            --Total----------------------------------------------------------------------------------------------------
          </div>
          <div className="receipt__section__content">
            <div className="receipt__section__content--row">
              <div>Total:</div>
              <div>${orderState.orderTotal}.00</div>
            </div>
          </div>
        </div>

        {/* Payment info */}
        <div className="receipt__section">
          <div className="receipt__section__header">
            --Payments----------------------------------------------------------------------------------------------------
          </div>
          <div className="receipt__section__content">
            <div className="receipt__section__content--row center">
              {formatSingleDate(order.createdOn, "yyyy-mm-dd hh:MM:ss TT")}
            </div>
            <div className="receipt__section__content--row">
              <div>Credit Card</div>
              <div>${orderState.orderTotal}.00</div>
            </div>
            <div className="receipt__section__content--row">
              <div>
                {orderState.ccType ? orderState.ccType : "Visa"}{" "}
                {orderState.maskedCC ? orderState.maskedCC : "************4242"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
