import classnames from "classnames";
import React, { useRef, useState, useEffect, useContext } from "react";
import { ReactComponent as CloseIcon } from "../shared/icons/close.svg";
import { ModalContext } from "../../contexts/modalContext";

export interface ModalProps {
  dismissible?: boolean; // Dismissing the modal updates display to `none`
  dismissText?: string; // Text for dismiss button
  buttonText?: string; // Text for button that triggers the onClick handler
  onClick?: () => void; // On click handler for button, this fn will not close the modal, but can be a redirect or another action
  children?: any; // Modal content excluding footer buttons
}

export const Modal: React.FC<ModalProps> = ({
  dismissible,
  dismissText,
  buttonText,
  onClick,
  children,
}: ModalProps) => {
  const modalWrapper = useRef<HTMLDivElement>();
  const [modalStyle, setModalStyle] = useState({});
  const { showModal, setShowModal } = useContext(ModalContext);

  // Styling for full screen modal wrapper
  useEffect(() => {
    setModalStyle({ top: "0" });
  }, [modalWrapper]);

  const handleDismiss = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div className="modal" style={modalStyle} ref={modalWrapper}>
          <div className="modal__container">
            {dismissible && (
              <button className="modal__close" onClick={handleDismiss}>
                <CloseIcon />
              </button>
            )}
            <div className="modal__content">
              {children && children}

              {(dismissible || (buttonText && onClick)) && (
                <div className="modal__footer">
                  {dismissible && (
                    <button className="btn-barnes" onClick={handleDismiss}>
                      {dismissText ? dismissText : "Close"}
                    </button>
                  )}

                  {buttonText && onClick && (
                    <button
                      className={classnames("btn-barnes", {
                        "btn-barnes-secondary": dismissible,
                      })}
                      onClick={onClick}
                    >
                      {buttonText}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
