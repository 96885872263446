import React, { useEffect, useRef, useState } from "react";
import { SpinnerSuspense } from "./spinner";

type HeroBannerProps = {
  header?: string;
  src?: string;
  alt?: string;
  className?: string;
  imageOffset?: number;
};
export const HeroBanner: React.FC<HeroBannerProps> = ({
  header,
  src,
  alt,
  className,
}: HeroBannerProps) => {
  const imageWrapperRef = useRef<HTMLDivElement>();

  const [isLoaded, setIsLoaded] = useState(!src);
  const [isError, setIsError] = useState(!src);

  // If SRC changes, reset state.
  useEffect(() => {
    if (src) {
      setIsLoaded(false);
      setIsError(false);
    }
  }, [src]);

  let heroBannerClassName = "hero-banner";
  let overlayClassName = "hero-banner__image-overlay";

  if (isError) {
    heroBannerClassName = `${heroBannerClassName} hero-banner--no-image`;
  }

  if (isLoaded) {
    overlayClassName = `${overlayClassName} hero-banner__image-overlay--loaded`;
  }

  return (
    <div className={`${heroBannerClassName} ${className || ""}`}>
      <div className="hero-banner__header-wrapper">
        <SpinnerSuspense className="hero-banner__spinner" isLoaded={isLoaded}>
          <BannerHeader
            imageWrapperHeight={
              imageWrapperRef.current
                ? imageWrapperRef.current.offsetHeight
                : null
            }
            header={header}
          />
        </SpinnerSuspense>
      </div>
      <div className="hero-banner__image-wrapper" ref={imageWrapperRef}>
        {src && (
          <img
            className="hero-banner__image"
            src={src}
            alt={alt || header}
            style={{
              opacity: isLoaded && !isError ? 1 : 0,
            }}
            // Wrap in STO to insure load animation fires.
            onLoad={() => setTimeout(() => setIsLoaded(true), 0)}
            onError={() => {
              setIsLoaded(true);
              setIsError(true);
            }}
          />
        )}
        <div className={overlayClassName}></div>
      </div>
    </div>
  );
};

type BannerHeaderProps = {
  imageWrapperHeight: number | void;
  header?: string;
};
const BannerHeader: React.FC<BannerHeaderProps> = ({
  imageWrapperHeight,
  header,
}: BannerHeaderProps) => {
  const ref = useRef<HTMLDivElement>();

  const [isResizeFont, setIsResizeFont] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  // Set is loaded after first render.
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  // On mount, see if text fits in container, if not then setState to true.
  useEffect(() => {
    if (isLoaded) {
      // If text is bigger than it's current boundary
      const isResized =
        imageWrapperHeight !== null &&
        ref.current &&
        imageWrapperHeight < ref.current.offsetHeight &&
        isLoaded;

      setIsResizeFont(isResized);
    }
  }, [isLoaded, imageWrapperHeight]);

  let headerStyle: React.CSSProperties = {};
  if (isResizeFont) {
    headerStyle = {
      ...headerStyle,
      fontSize: "27.5px",
    };
  }

  return (
    <h1
      className="hero-banner__header"
      style={{
        ...headerStyle,
        opacity: isLoaded ? 1 : 0,
      }}
      ref={ref}
    >
      {header}
    </h1>
  );
};
