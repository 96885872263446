import { AxiosRequestConfig } from "axios";
import { v4 as uuid } from "uuid";
import url from "url";
import crypto from "crypto";
import config from "../config";

const headerAlgo = (algorithm: string) => "tp-hmac-" + algorithm;

/** UTF-8 encodes a given string, then hashes it using the given algorithm, and
 * finally hex encodes the hash
 * @param {string} data - The string to be encoded and hashed
 * @param {string} algorithm - The algorithm to use for hashing
 * @returns {string} Hashed and encoded data
 */
export const generateHash = (data: string, algorithm: string): string =>
  crypto.createHash(algorithm).update(Buffer.from(data, "utf8")).digest("hex");

/** UTF-8 encodes a given string, then hashes it using the given algorithm and
 * utf-8 encoded key, and finally hex encodes the hash
 * @param {string} key - Key to use for hashing the data
 * @param {string} data - The string to be encoded and hashed
 * @param {string} algorithm - The algorithm to use for hashing
 * @returns {string} Hashed and encoded data
 */
export const generateKeyHash = (
  key: string,
  data: string,
  algorithm: string
): string =>
  crypto
    .createHmac(algorithm, Buffer.from(key, "utf8"))
    .update(Buffer.from(data, "utf8"))
    .digest("hex");

/** Calculate request body hash
 * @param {any} data - JSON object of request data
 * @param {string} algorithm - The algorithm to use for hashing
 * @returns {string} Hashed and encoded body
 */
export const generateRequestBodyHash = (
  data: any,
  algorithm: string
): string => {
  const body = JSON.stringify(data);
  return generateHash(body, algorithm);
};

/** Returns a tuple with a list of all headers with the "tp" headers removed and
 * an object with only unique header keys and value arrays
 * @param {AxiosRequestConfig["headers"]} headers
 * @returns {[string[], { [key: string]: string[] }]}
 */
export const filterHeaders = (
  headers: AxiosRequestConfig["headers"]
): [string[], { [key: string]: string[] }] => {
  // exclude "tp" headers -> alphabetize -> create semi-colon separated string of all header keys
  const allHeaderKeys = [];
  const uniqueHeaders = {};
  const uniqHeaderKeys = [];

  for (const k in headers) {
    // Do not include any headers with "tp-" prefix in the canonical headers
    if (!k.includes("tp-")) {
      allHeaderKeys.push(k);

      if (uniqHeaderKeys.indexOf(k) === -1) {
        // Create new key/value pair in the uniqueHeaders ob
        uniqHeaderKeys.push(k);
        uniqueHeaders[k] = [headers[k]];
      } else {
        // Add value to existing uniqueHeaders for that key
        uniqueHeaders[k].push(headers[k]);
      }
    }
  }

  return [allHeaderKeys, uniqueHeaders];
};

/** Generates the canonical request hash
 * @param {string} method - Request method
 * @param {string} uri - Request uri path
 * @param {string} query - Query string from the request url
 * @param {string} headerStr - Line delimited string of header keys and values
 * @param {string} signedHeaders - Semicolon delimited string of header keys
 * @param {string} bodyHash - Hashed request body generated from `generateRequestBodyHash`
 * @param {string} algorithm - The algorithm to use for hashing
 * @returns {string} Hashed and encoded data
 */
export const generateCanonicalRequestHash = (
  method: string,
  uri: string,
  query: string,
  headerStr: string,
  signedHeaders: string,
  bodyHash: string,
  algorithm: string
): string => {
  // 8. Generate canonical request
  // line delimit HTTP method, canonical url, canonical query string, canonical headers, canonical signed headers, request body hash
  let canonicalRequest = method + "\n";
  canonicalRequest += uri + "\n";
  canonicalRequest += query === null ? "\n" : query + "\n";
  canonicalRequest += headerStr + "\n";
  canonicalRequest += signedHeaders + "\n";
  canonicalRequest += bodyHash;

  // 9. Generate canonical request hash
  // utf-8 encode canonical request -> hash using algorithm -> hex encode hash
  return generateHash(canonicalRequest, algorithm);
};

/** line delimited string with algorithm, request date, dev key, canonical request hash
 * @param {string} algorithm - Algorithm used for hashing
 * @param {string} requestDate - Date of the request as an ISO string
 * @param {string} devKey - triPOS developer key
 * @param {string} canonicalRequestHash - Hashed request generated from `generateCanonicalRequestHash`
 * @returns {string} - Concatenated string for the signature hashing
 */
export const generateUnhashedSignature = (
  algorithm: string,
  requestDate: string,
  devKey: string,
  canonicalRequestHash: string
): string => {
  var unhashedSignature =
    headerAlgo(algorithm).toUpperCase() +
    "\n" +
    requestDate +
    "\n" +
    devKey +
    "\n" +
    canonicalRequestHash;

  return unhashedSignature;
};

/** Generates the hashed signature for the headers
 * @param {string} algorithm - Algorithm that should be used for hashing
 * @param {string} requestDate - Date of the request as an ISO string
 * @param {string} nonce - Unique identifying string for the request
 * @param {AxiosRequestConfig} config - Request configuration
 * @param {[string[], { [key: string]: string[] }]} filteredHeaders - Tuple with
 * an array of unique header keys and an object with header key/value pairs
 * @returns {string} - Hashed signature data
 */
export const generateSignature = (
  algorithm: string,
  requestDate: string,
  nonce: string,
  reqConfig: AxiosRequestConfig,
  filteredHeaders: [string[], { [key: string]: string[] }]
): string => {
  const [canonicalSignedHeadersArray, canonicalHeaders] = filteredHeaders;

  // 2. Get request method and url
  const parsedUrl = url.parse(reqConfig.url);
  const method = reqConfig.method;

  // 3. Create request body hash
  const bodyHash = generateRequestBodyHash(reqConfig.data, algorithm);

  var canonicalSignedHeaders = canonicalSignedHeadersArray.sort().join(";");

  // 5. Generate canonical headers
  // create line delimited string in same order as the headers generated in step 4 -> keys with multiple values should comma separate the values
  var canonicalHeaderStr = canonicalSignedHeadersArray
    .map((header) => header + ":" + canonicalHeaders[header].join(", "))
    .sort()
    .join("\n");

  // 6. Generate canonical URI
  const canonicalUri = parsedUrl.path;

  // 7. Generate canonical query string;
  const canonicalQueryStr = parsedUrl.query;

  // 9. Generate canonical request hash
  const canonicalRequestHash = generateCanonicalRequestHash(
    method,
    canonicalUri,
    canonicalQueryStr,
    canonicalHeaderStr,
    canonicalSignedHeaders,
    bodyHash,
    algorithm
  );

  // 10. Generate key signature hash
  // utf-8 encode concatenated nonce + dev secret to use as hash data -> utf-8 encode date in iso format to use as hash key -> hash data using the key -> hex encode hash
  const data = nonce + config.triPosDevSecret;
  var keySignatureHash = generateKeyHash(requestDate, data, algorithm);

  // 11. Generate unhashed signature
  var unhashedSignature = generateUnhashedSignature(
    algorithm,
    requestDate,
    config.triPosDevKey,
    canonicalRequestHash
  );

  // 12. Generate signature
  // utf-8 encode the unhashed signature to use as data -> utf-8 encode the key signature hash to use as the key -> hash data with algo using key -> hex encode hash
  const signature = generateKeyHash(
    keySignatureHash,
    unhashedSignature,
    algorithm
  );

  return signature;
};

/** Steps through generating the HMAC authentication header as described in the
 * triPOS Integration Guide, page 32. Several steps have been broken out into
 * helper functions, but they are still labeled with the corresponding step in comments
 * @param {AxiosRequestConfig} reqConfig
 * @returns {string} - Concatenated string to be included in the triPOS auth header
 */
export const generateTriPosHeaders = (
  reqConfig: AxiosRequestConfig
): string => {
  const requestDate = new Date().toISOString();
  const nonce = uuid();

  // 1. Select HMAC algorithm
  const algorithm = "md5";

  // 4. Generate canonical signed headers
  // alphabetize -> create semi-colon separated string of all header keys
  const filteredHeaders = filterHeaders(reqConfig.headers);
  const [canonicalSignedHeadersArray, canonicalHeaders] = filteredHeaders;

  var canonicalSignedHeaders = canonicalSignedHeadersArray.sort().join(";");

  // 12. Generate signature
  const signature = generateSignature(
    algorithm,
    requestDate,
    nonce,
    reqConfig,
    filteredHeaders
  );

  // 13. Generate authorization signature header
  // comma delimited version, algorithm, credential, signed headers, nonce, request date, signature
  var tpAuthorization = [
    "Version=1.0",
    "Algorithm=" + headerAlgo(algorithm).toUpperCase(),
    "Credential=" + config.triPosDevKey,
    "SignedHeaders=" + canonicalSignedHeaders,
    "Nonce=" + nonce,
    "RequestDate=" + requestDate,
    "Signature=" + signature,
  ].join(",");

  return tpAuthorization;
};
