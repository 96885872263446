import React, { createContext } from "react";
import { META_TITLE } from "../constants";

export type HelmetData = {
  title: string;
};

type HelmetContextType = {
  helmetData: HelmetData;
  setHelmetData?: React.Dispatch<React.SetStateAction<HelmetData>>;
};
export const HelmetContext = createContext<HelmetContextType>({
  helmetData: {
    title: META_TITLE,
  },
  setHelmetData: null,
});
