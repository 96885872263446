import React from "react";
import { useHistory, Link } from "react-router-dom";

type BackLinkProps = {
  text: string;
  backLinkOverride?: string;
};
export const BackLink: React.FC<BackLinkProps> = ({
  text,
  backLinkOverride,
}: BackLinkProps) => {
  const history = useHistory();

  return (
    <div>
      {backLinkOverride ? (
        <Link className="event-items__back a-basic-link" to={backLinkOverride}>
          {text}
        </Link>
      ) : (
        <button
          className="event-items__back a-basic-link"
          onClick={() => {
            history.goBack();
          }}
        >
          {text}
        </button>
      )}
    </div>
  );
};
