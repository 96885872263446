import React from "react";
import { getCreditCardIcon } from "../icons/paymentIcons";

/**
 * Credit card logo for input.
 */
type CreditCardIconProps = { type: string };
export const CreditCardIcon: React.FC<CreditCardIconProps> = ({ type }) => {
  const cardElement = getCreditCardIcon(type);

  return cardElement
    ? React.cloneElement(cardElement, {
        className: "input-form__credit-card-logo",
      })
    : null;
};
