import React, { useEffect, useState } from "react";
import classnames from "classnames";

type CheckInCounterProps = {
  update: (quantity: number) => void;
  value: number;
  availableSeats: number;
  totalTicketCount: number;
  name: string;
  description: string;
  price: number;
  discount: number;
  className: string;
  applyTicketLimit: boolean;
  ticketQuantityLimit: number;
  getQuantity: (
    name: string,
    type: "Standard" | "Discount" | "Combined"
  ) => number;
  errors?: string[];
  isKiosk: boolean;
};

/**
 * Counter mechanism for selecting ticket quantities.
 */
export const CheckInCounter: React.FC<CheckInCounterProps> = ({
  update,
  value,
  availableSeats,
  totalTicketCount,
  name,
  description,
  price,
  discount,
  className,
  applyTicketLimit,
  ticketQuantityLimit,
  getQuantity,
  errors,
  isKiosk,
}: CheckInCounterProps) => {
  const [noAvailability, setNoAvailability] = useState(false);

  useEffect(() => {
    if (totalTicketCount >= availableSeats) {
      setNoAvailability(true);
    } else setNoAvailability(false);
  }, [setNoAvailability, noAvailability, totalTicketCount, availableSeats]);

  return (
    <div className={classnames(className, { kiosk: isKiosk })}>
      <div
        className={classnames("check-in__ticket-type-child", {
          kiosk: isKiosk,
        })}
      >
        {" "}
        <span
          className={classnames("check-in__field check-in__name", {
            kiosk: isKiosk,
          })}
        >
          {name}
        </span>
        <span
          className={classnames("check-in__field check-in__description", {
            kiosk: isKiosk,
          })}
        >
          {description || "Membership"}
        </span>
      </div>
      <div
        className={classnames("check-in__ticket-type-child", {
          kiosk: isKiosk,
        })}
      >
        {" "}
        <div className={classnames("check-in__counter", { kiosk: isKiosk })}>
          <button
            className={classnames(
              "check-in__counter-button",
              "check-in__counter-button--minus",
              {
                kiosk: isKiosk,
              }
            )}
            onClick={() => update(Math.max(value - 1, 0))}
            disabled={value === 0}
          >
            <span
              className={classnames(
                "check-in__counter-button-content check-in__counter-button-content--minus",
                {
                  kiosk: isKiosk,
                }
              )}
            >
              -
            </span>
          </button>
          <input
            className={classnames("check-in__counter-input", {
              kiosk: isKiosk,
            })}
            value={value}
            readOnly
          />
          <button
            className={classnames(
              "check-in__counter-button",
              "check-in__counter-button--plus",
              {
                kiosk: isKiosk,
              }
            )}
            onClick={() => update(Math.min(value + 1, availableSeats))}
            disabled={
              noAvailability ||
              (isKiosk && totalTicketCount === 0 && price === 0)
            }
          >
            <span
              className={classnames("check-in__counter-button-content", {
                kiosk: isKiosk,
              })}
            >
              +
            </span>
          </button>
        </div>
      </div>

      <div
        className={classnames("check-in__ticket-type-child", {
          kiosk: isKiosk,
        })}
      >
        <div className="check-in__price-wrapper">
          <div className="check-in__price">
            <span className="check-in__field">
              <div>
                {discount > 0 &&
                  applyTicketLimit &&
                  totalTicketCount > ticketQuantityLimit && (
                    <>{getQuantity(name, "Discount")} at </>
                  )}
                {discount === 0 &&
                  applyTicketLimit &&
                  totalTicketCount > ticketQuantityLimit && (
                    <>{getQuantity(name, "Combined")} at </>
                  )}
                {discount > 0 && (
                  <span className="check-in__x-out-price">
                    ${price + discount}.00
                  </span>
                )}
                ${price}.00
                {discount > 0 && (
                  <span className="check-in__discount">Member Price</span>
                )}
              </div>

              {discount > 0 &&
                applyTicketLimit &&
                totalTicketCount > ticketQuantityLimit && (
                  <div>
                    {getQuantity(name, "Standard")} at ${price + discount}.00
                    <span className="check-in__discount">Standard Price</span>
                  </div>
                )}

              {errors && (
                <span className="check-in__field check-in__error-message check-in__error-message--margin-top">
                  {errors.join("\n")}
                </span>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
