import React from "react";
import { useParams } from "react-router-dom";
import { SliderCard, Description } from "../shared/slider";
import { formatSingleDate } from "../../util/formatting";
import { useQR } from "../../hooks/customHooks";

type TicketOrderProps = {
  uuid: string;
  eventName: string;
  eventDate: Date | string;
  subHeader?: string;
};
export const TicketOrder: React.FC<TicketOrderProps> = ({
  uuid,
  eventName,
  eventDate,
  subHeader = "",
}: TicketOrderProps) => {
  const params = useParams() as { ticket: string }; // Get ticket uuid from url, this will later make call to server.
  const ticketQR = useQR(JSON.stringify({ uuid: params.ticket || uuid }));

  const descriptions: Description[] = [
    { value: eventName, modifier: "title" },
    { value: subHeader, modifier: "sub-header" },
    {
      fieldName: "Ticket Date",
      value: formatSingleDate(eventDate),
      modifier: "detail",
    },
  ];

  return <SliderCard qr={ticketQR} descriptions={descriptions} />;
};
