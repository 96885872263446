import {
  EventDetailPayload,
  MembershipLevelIdentifier,
} from "../../../common/payloads";
import React, { useContext, useEffect, useState } from "react";
import {
  getAdmissionEvent,
  getMembershipList,
} from "../../services/vipService";

import { EventDetailKiosk } from "./eventDetailKiosk";
import { HelmetContext } from "../../contexts/helmetContext";
import { ReactComponent as SadFace } from "../shared/icons/sad-face.svg";
import { SpinnerSuspense } from "../shared/spinner";
import { Logos } from "../shared/logos/logo";
import { useQueryParams } from "../../hooks/useQueryParams";
import { generateImgixUrl } from "../../util/generateImgixUrl";

// BF397 -- Henri Rousseau, Monkeys and Parrot in the Virgin Forest
const backgroundSrc = generateImgixUrl(
  "sharedBackgroundImages/BF397",
  "fit=crop&h=1920&min-w=1080"
);

export const Kiosk = () => {
  const [admissionEventDetail, setAdmissionEventDetail] =
    useState<EventDetailPayload | void>(null);
  const [membershipLevels, setMembershipLevels] = useState<
    MembershipLevelIdentifier[] | void
  >(null);
  const [isError, setIsError] = useState(false); // HTTP error on setup
  const { setHelmetData } = useContext(HelmetContext); // Site title tags

  // Fetch events via VIP API endpoint.
  useEffect(() => {
    const fetchAdmission = async () => {
      try {
        const [admissionEvent, memberships] = await Promise.all([
          getAdmissionEvent(),
          getMembershipList(),
        ]);

        setAdmissionEventDetail(admissionEvent);
        setMembershipLevels(memberships);
      } catch (e) {
        console.log(e);
        setIsError(true);
      }
    };

    fetchAdmission();
  }, []);

  // Alter site title tags
  // # TODO => This can be broken into a separate hook that is a value given to
  // the provider in App.tsx.
  useEffect(() => {
    setHelmetData({ title: "Kiosk Checkout - The Barnes Foundation" });
  }, [setHelmetData]);

  return (
    <div className="app__content">
      <div className="title-bar">
        <header
          data-behavior="header"
          role="banner"
          className="site-header site-header--vip"
        >
          <div className="header-container">
            <Logos link="#" />
          </div>
        </header>
      </div>

      <div className="kiosk-container">
        <img className="splash__background" src={backgroundSrc} alt="" />
        {
          // On error, present sad face. ☹
          !isError ? (
            <SpinnerSuspense isLoaded={Boolean(admissionEventDetail)}>
              <EventDetailKiosk
                eventDetailPayload={admissionEventDetail as EventDetailPayload}
                membershipLevels={
                  membershipLevels as MembershipLevelIdentifier[]
                }
              />
            </SpinnerSuspense>
          ) : (
            <div className="general__error">
              <SadFace className="general__sad-face" />
              <span className="general__error-text">
                There are currently no available events.
              </span>
            </div>
          )
        }
      </div>
    </div>
  );
};
