import { NavLink, useHistory } from "react-router-dom";
import React, { useContext, useState, useRef } from "react";

import AuthenticateService from "../../services/authenticationService";

import { Hamburger, LogoutIcon } from "./icons";

import { MenuContext } from "../../contexts/menuContext";
import { AuthContext } from "../../contexts/authContext";
import { UserContext } from "../../contexts/userContext";
import { TOP_NAV_LINKS } from "../../constants/navLinks";

export const SiteHeader: React.FC = () => {
  const { setIsSideMenuActive, setSiteHeaderStatus } = useContext(MenuContext);
  const { membershipStanding } = useContext(UserContext);
  const [isScrolling, setIsScrolling] = useState(false);
  const [height, setHeight] = useState(0);
  const upperBoundary = 50;
  const lowerBoundary = 250;
  const TIMEOUT = 100;
  const authService = useRef(new AuthenticateService());
  const history = useHistory();
  const { permissions } = useContext(AuthContext);

  // Logout mechanism.
  const logout = async () => {
    try {
      await authService.current.logout();
      history.push("/");
    } catch (e) {
      console.log(e);
    }
  };

  const FILTERED_TOP_NAV_LINKS = TOP_NAV_LINKS.filter((navLink) => {
    if (navLink.permission) {
      // get the user's permissions for the nav link's permission
      return permissions[navLink.permission];
    } else {
      // default to returning true if there are no permissions set for this nav item
      return true;
    }
  });

  if (!isScrolling) {
    setIsScrolling(true); // Set firing status to true.

    setTimeout(() => {
      const previousScrollHeight = height;
      const currentScrollHeight = window.pageYOffset;
      setSiteHeaderStatus("default");
      if (
        currentScrollHeight > upperBoundary &&
        previousScrollHeight < currentScrollHeight
      ) {
        setSiteHeaderStatus("unlocked"); // Translate off screen.
      } else if (currentScrollHeight > lowerBoundary) {
        setSiteHeaderStatus("locked"); // Translate on screen.
      }
      setHeight(currentScrollHeight); // Update scrollState height variable.
      setIsScrolling(false); // Reset firing status.
    }, TIMEOUT);
  }

  return (
    <nav className="site-header__nav">
      {membershipStanding === "active" ? (
        <>
          {FILTERED_TOP_NAV_LINKS.map(({ href, name, ...props }) => (
            <NavLink
              key={name}
              to={href}
              className="site-header__nav__link"
              activeClassName="menu__item--active"
              // Spread props, if they exist.
              {...props}
            >
              {name}
            </NavLink>
          ))}

          <div className="menu-hamburger-wrapper">
            <button
              onClick={() => {
                setIsSideMenuActive(true);
              }}
              className="menu-hamburger"
              data-nav-show
              type="button"
              aria-labelledby="nav-open-title"
            >
              <Hamburger />
            </button>
          </div>
        </>
      ) : (
        <button
          className="log-out menu__item menu__item--log-out"
          onClick={logout}
        >
          <LogoutIcon className="log-out__icon" />
          <span className="log-out__text">Log Out</span>
        </button>
      )}
    </nav>
  );
};
