import { OrderValidationPayload } from "../../../../common/payloads";

export const handleAcmeErrors: (
  acmeValidation: OrderValidationPayload["acmeValidation"]
) => { [key: string]: string[] } = (acmeValidation) => {
  return acmeValidation.results
    .filter(({ valid }) => !valid)
    .reduce((acc, result) => {
      const { ticketingTypeId } = result.items[0];

      let error: string;
      if (result.requestedQuantity > result.allowedQuantity) {
        const { ticketingTypeName } = result.items[0];

        error = `${result.requestedQuantity} ${ticketingTypeName} tickets were requested, but only ${result.allowedQuantity} can be fulfilled in one day.`;
      } else if (
        result.rulesApplied.length &&
        result.rulesApplied.some(({ id }) => id.includes("Adult_"))
      ) {
        error = `There is a maximum of ${result.allowedQuantity} tickets per day, this order will exceed the amount you currently have on file.`;
      }

      return error
        ? {
            ...acc,
            [ticketingTypeId]: acc[ticketingTypeId]
              ? [...acc[ticketingTypeId], error]
              : [error],
          }
        : acc;
    }, {});
};
