import { PaymentMethod, PaymentMethodHandlers } from "./usePaymentMethod";
import React, { useEffect, useRef } from "react";

import { CreditCardIcon } from "./creditCardIcon";
import { ReactComponent as LockIcon } from "../icons/lock.svg";
import { MembershipIcon } from "../../menu/icons/index";
import { PaymentError } from "../helpers/paymentErrorHandler";
import { Toggle } from "../../shared/toggle";
import { Checkbox } from "./checkbox";
import Config from "../../../config";
import classnames from "classnames";

type EventDetailPaymentFormProps = {
  mustBeValidated: boolean;
  isVIPCheckout: boolean;
  isUpsell: boolean;
  hasBillingMatchMembership: boolean;
  contactOnly: boolean; // if mustBeValidated is false, and isVIPCheckout is true, and totalTicketCount > 0 then only collect contact info
  // Payment details
  paymentMethod: PaymentMethod;
  paymentError: PaymentError;
  isMemberRenewal: boolean;
  optIn: boolean;
  toggleOptIn: () => void;
} & PaymentMethodHandlers;
export const EventDetailPaymentForm: React.FC<EventDetailPaymentFormProps> = ({
  mustBeValidated,
  isVIPCheckout,
  isUpsell,
  hasBillingMatchMembership,
  contactOnly,
  paymentMethod,
  paymentError,
  isMemberRenewal,
  optIn,
  toggleOptIn,
  ...payMethodCallbacks
}: EventDetailPaymentFormProps) => {
  // Destructure payment error
  const [paymentErrorKey, paymentErrorMessage] = paymentError || [
    undefined,
    undefined,
  ];

  // Set up scroll ref, this will map to each section of the payment form.
  // This will be used to scroll the errored field into view.
  const scrollRef = useRef<
    { [key in keyof Partial<PaymentMethod>]: HTMLDivElement }
  >({});
  useEffect(() => {
    if (paymentErrorKey && scrollRef.current[paymentErrorKey]) {
      (scrollRef.current[paymentErrorKey] as HTMLDivElement).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [paymentErrorKey]);

  // Destructure payment method as these are handled a bit differently
  // depending on context.
  // For non-VIP, only mainPaymentFormFields are shown.
  // Depending on if an upsell is required, membership will be rendered in a separate card.
  const {
    membership,
    contact,

    // mainPaymentFormFields
    payment,
    address,
  } = paymentMethod;

  // If autofill for membership is on.
  let membershipSectionClassName = "input-form__membership-section";
  if (hasBillingMatchMembership) {
    membershipSectionClassName = `${membershipSectionClassName} input-form__membership-section--disabled`;
  }

  return (
    <form
      className={classnames("input-form", {
        "payment-form--deactivated": !mustBeValidated && !contactOnly,
      })}
    >
      {/** Header */}
      <div className="input-form__header-wrapper">
        <h2 className="input-form__header">
          {contactOnly ? "Customer Information" : "Payment Method"}
          <LockIcon className="input-form__icon input-form__icon--lock" />
        </h2>
      </div>

      {/** General items form section */}
      <div className="input-form__form">
        {
          // Form fields, omit contact for membership checkout.
          Object.entries(
            // { contact } as Partial<PaymentMethod>
            contactOnly
              ? ({ contact, address } as Partial<PaymentMethod>)
              : ({ contact, payment, address } as Partial<PaymentMethod>)
          ).map(([paymentSectionName, paymentSectionForm]) => (
            <FormSection
              key={paymentSectionName}
              ref={(ref) => (scrollRef.current[paymentSectionName] = ref)}
              // Set up button for address
              button={
                paymentSectionName === "address" &&
                !isVIPCheckout &&
                !isMemberRenewal ? (
                  <FormButton
                    onClick={payMethodCallbacks.autofillCardholderInformation}
                  >
                    Autofill Address from Membership
                  </FormButton>
                ) : null
              }
              // Payment form and error information
              paymentSectionName={paymentSectionName}
              paymentSectionForm={
                paymentSectionForm as PaymentMethod["contact"]
              }
              paymentErrorMessage={
                paymentErrorKey === paymentSectionName
                  ? paymentErrorMessage
                  : undefined
              }
              {...payMethodCallbacks}
            />
          ))
        }

        {/** Render the Marketing SMS Opt-In checkboxb only if the SMS Opt-In URL is available in the environment
         * This will enable us to quickly turn off the checkbox, if needed, with a redeploy
         */}
        {Config.smsOptInUrl ? (
          <Checkbox
            id={"sms-opt-in-checkbox"}
            value={"sms-opt-in-checkbox"}
            checked={optIn}
            onChange={toggleOptIn}
            label={"Sign me up to receive special offers via SMS"}
          />
        ) : null}
      </div>

      {/** Membership upsell section. */}
      {isUpsell && isVIPCheckout && (
        <>
          <div className="input-form__header-wrapper input-form__header-wrapper--upsell">
            <h2 className="input-form__header">
              Membership Details
              <MembershipIcon className="input-form__icon input-form__icon--upsell" />
            </h2>
          </div>

          <div className="input-form__form">
            <p className="input-form__copy">
              This information will be used for your membership cards.
            </p>
            <div className="input-form__membership-toggle">
              Use billing information to fill out membership form.
              <Toggle
                className="toggle--right"
                isOn={hasBillingMatchMembership}
                onClick={() => {
                  payMethodCallbacks.setHasBillingMatchMembership(
                    (hasBillingMatchMembership) => !hasBillingMatchMembership
                  );
                }}
              />
            </div>
            <div className={membershipSectionClassName}>
              <FormSection
                paymentSectionName="membership"
                paymentSectionForm={membership}
                {...payMethodCallbacks}
              />
            </div>
          </div>
        </>
      )}
    </form>
  );
};

const FormButton: React.FC<{ onClick: () => any }> = ({
  children,
  onClick,
}: any) => (
  <button
    className="btn-barnes input-form__button"
    onClick={(e) => {
      // Prevent upwards propagation to form submission.
      e.preventDefault();
      e.stopPropagation();

      // Execute callback
      onClick();
    }}
  >
    {children}
  </button>
);

type FormSectionProps = {
  button?: React.ReactElement;
  paymentSectionName: string;
  paymentSectionForm: PaymentMethod["contact"]; // This is the more relaxed typeset.
  paymentErrorMessage?: string;
} & PaymentMethodHandlers;
// eslint-disable-next-line react/display-name
const FormSection = React.forwardRef<HTMLDivElement, FormSectionProps>(
  (
    {
      button,

      paymentSectionName,
      paymentSectionForm,
      paymentErrorMessage,

      // autofillCardholderInformation, the other PaymentMethodHandler, is not needed here.
      updatePaymentMethod,
      validatePaymentMethod,
      resetPaymentMethodValidation,
    }: FormSectionProps,
    ref
  ) => (
    <div className="input-form__body" ref={ref}>
      {
        // For address field, when not VIP add autoFill button.
        Boolean(button) && button
      }
      {Object.entries(paymentSectionForm)
        .sort(([, a], [, b]) => a.sort - b.sort)
        .map(
          ([
            key,
            {
              label,
              value,
              sort, // For cc info.
              isValid,
              maskedValue,
              creditCardType,
              errorMessage, // Any additional style props
              style, // for spreading over DOM properties
              additionalProps,
            },
          ]) => (
            <label
              className="input-form__label"
              key={`${paymentSectionName}${key}${sort}`}
              style={style}
            >
              {label}

              <div className="input-form__input-wrapper">
                <input
                  className={
                    // If this specific field has an error or
                    // if there is an error message re: whole section
                    isValid !== false && !paymentErrorMessage
                      ? "input-form__input"
                      : "input-form__input input-form__input--error"
                  }
                  // Default to type="text"
                  // This may be overwritten in spread.
                  type="text"
                  // controlled component props
                  value={maskedValue || value}
                  // This may be overwritten in spread.
                  onChange={({ target: { value } }) => {
                    updatePaymentMethod(paymentSectionName, key, value);
                  }}
                  onBlur={validatePaymentMethod}
                  onFocus={() => resetPaymentMethodValidation(key)}
                  // Spread additional DOM properties, autocomplete information
                  // and event handlers which may overwrite default input state.
                  {...additionalProps}
                  required
                />

                {creditCardType && <CreditCardIcon type={creditCardType} />}
              </div>

              {isValid === false && (
                <span className="input-form__warning">{errorMessage}</span>
              )}
            </label>
          )
        )}
      {Boolean(paymentErrorMessage) && (
        <span className="input-form__warning">{paymentErrorMessage}</span>
      )}
    </div>
  )
);
