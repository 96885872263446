import { EventTimeObject } from "acme-ticketing-client";
import {
  MembershipLevelIdentifier,
  MembershipPurchase,
  PurchaseItem,
} from "../../../../common/payloads";

// Ticket levels to be exchanged.
const EXCHANGE_TICKET_LEVELS = ["Adult", "Senior"];
const MEMBER_CHILD_TICKET_LEVELS = ["Child", "Youth"];
export type UpsellTicketTypes = "Adult" | "Child" | "Senior" | "Youth";
export type UpsellMembershipTypes = "Patron" | "Contributor" | "Supporter"; // # TODO => This should be tied to server.

/**
 * @see vipController for original of this.
 */
type MemberUpsellTicketCounts = {
  allowedGuestsQuota: number;
  allowedGuests: number;
  namedAdults: number;
};
const membershipLevelMap: {
  [key in "Patron" | "Contributor" | "Supporter"]: MemberUpsellTicketCounts;
} = {
  Patron: {
    allowedGuestsQuota: 2,
    allowedGuests: 2,
    namedAdults: 1,
  },

  Contributor: {
    allowedGuestsQuota: 2,
    allowedGuests: 2,
    namedAdults: 2,
  },

  Supporter: {
    allowedGuestsQuota: 2,
    allowedGuests: 4,
    namedAdults: 2,
  },
};

export type UpsellTicketItems = Partial<
  {
    [key in
      | "Member Adult"
      | "Guest Pass"
      | "Adult"
      | "Member Child"
      | "Child"
      | "Youth"]: {
      quantity: number;
      unitPrice?: string;
    };
  }
>;
/**
 * Calculate final price and types of conversions that will occur on the server.
 * @param {{ [key: string]: PurchaseItem }} purchaseItems - current shopping cart.
 * @param {string} membershipLevel - level of upsell.
 * @returns {UpsellTicketItems & { price: number }} counts of tickets to be converted.
 */
export const upsellPriceCalculator = (
  purchaseItems: { [key: string]: PurchaseItem },
  membershipLevel: string
): UpsellTicketItems & { price: number } => {
  // Get ticket quota map
  const ticketQuotas: MemberUpsellTicketCounts =
    membershipLevelMap[membershipLevel];

  // Calculate number of Adult | Senior tickets.
  const tickets = EXCHANGE_TICKET_LEVELS.reduce(
    (acc, ticketLevel) =>
      acc +
      (purchaseItems[ticketLevel] ? purchaseItems[ticketLevel].quantity : 0),
    0
  );

  // Calculate total of tickets to be converted to Member Child.
  const memberChildTickets = MEMBER_CHILD_TICKET_LEVELS.reduce(
    (acc, ticketLevel) =>
      acc +
      (purchaseItems[ticketLevel] ? purchaseItems[ticketLevel].quantity : 0),
    0
  );

  const namedAdults = Math.min(ticketQuotas.namedAdults, tickets);
  const guestPasses = Math.min(
    ticketQuotas.allowedGuestsQuota,
    tickets - namedAdults
  );
  const remainder = tickets - (namedAdults + guestPasses);

  const childTickets =
    membershipLevel !== "Patron"
      ? { "Member Child": { quantity: memberChildTickets } }
      : {
          Child: { quantity: purchaseItems["Child"].quantity },
          Youth: {
            quantity: purchaseItems["Youth"].quantity,
            unitPrice: "5.00",
          },
        };

  return {
    "Member Adult": { quantity: namedAdults },
    "Guest Pass": { quantity: guestPasses },
    Adult: { quantity: remainder, unitPrice: "10.00" },
    ...childTickets,
    price:
      remainder * 10 +
      // Tabulate price according to membership level price.
      // For Patron, add youth cost.
      (membershipLevel !== "Patron" ? 0 : purchaseItems["Youth"].quantity * 5),
  };
};

/**
 * Calculate the total for an order
 * @param {(PurchaseItem | MembershipPurchase)[]} cartItems - current shopping cart
 * @param {EventTimeObject} selectedTime - Acme event object for the order
 * @param {MembershipLevelIdentifier} upsell - Optional membership upsell information
 */
export const calcOrderTotal = (
  cartItems: (PurchaseItem | MembershipPurchase)[],
  selectedTime: EventTimeObject,
  upsell?: MembershipLevelIdentifier
) => {
  if (upsell) {
    const {
      event: {
        priceList: { prices },
        id: eventId,
        name: eventName,
        startTime: eventTime,
        customFields,
      },
    } = selectedTime;

    const purchaseItems: {
      [key: string]: PurchaseItem;
    } = prices.reduce((acc, { personType: { name, id }, price }) => {
      const item = cartItems.find(
        (item: PurchaseItem) => item.ticketingTypeName === name
      );

      return {
        ...acc,
        [name]: {
          eventId,
          eventName,
          eventTime,
          itemType: "Event",
          ignoreEntitlements: false,
          eventCategory: customFields.find((field) => field.name === "Category")
            .value,
          ticketingTypeName: name,
          ticketingTypeId: id,
          quantity: item ? item.quantity : 0,
          unitPrice: `${price}.00`,
        },
      };
    }, {});

    return (
      parseInt(upsell.info.price) +
      upsellPriceCalculator(purchaseItems, upsell.info.name).price
    );
  }

  return cartItems
    ? (cartItems as PurchaseItem[]).reduce(
        (acc, { unitPrice, quantity }) => acc + parseInt(unitPrice) * quantity,
        0
      )
    : 0;
};

/**
 * Determine if an upsell should take place based on cart, if so return nature of upsell.
 * @param {PurchaseItem} purchaseItems current cart.
 * @return {[UpsellMembershipTypes, UpsellMembershipTypes] | [UpsellMembershipTypes] | void} - if
 * our current cart matches an upsell case, the types of upsell.  Otherwise, return null.
 */
export const isUpsellCheckout = (
  purchaseItems: { [key in UpsellTicketTypes]: PurchaseItem }
):
  | [UpsellMembershipTypes, UpsellMembershipTypes]
  | [UpsellMembershipTypes]
  | void => {
  const totalTickets =
    purchaseItems.Adult.quantity + purchaseItems.Senior.quantity;

  // # TODO => Flesh this heuristic out a bit more.
  if (totalTickets > 8) return null;
  if (totalTickets >= 6) return ["Supporter"];
  if (totalTickets >= 4) return ["Contributor"];
  if (totalTickets >= 1) return ["Patron", "Contributor"];

  // If no case is matched, return void.
  return null;
};

/**
 * Generate pitch to present to user and calculate total price..
 * @param {MembershipLevelIdentifier} - level of membership.
 * @return {(cartPrice: number) => string} return string.
 */
export const generateMembershipPitch = (
  membershipLevel: MembershipLevelIdentifier
) => {
  const membershipPrice = parseFloat(membershipLevel.info.price);

  if (membershipLevel.info.name === "Supporter") {
    return (cartPrice: number) =>
      `SUPPORTER: The best way to visit the Barnes is as a member. Supporters give $${membershipPrice} for:`;
  }

  if (membershipLevel.info.name === "Contributor") {
    return (
      cartPrice: number,
      purchaseItems: { [key: string]: PurchaseItem }
    ) =>
      `CONTRIBUTOR: For an additional $${
        membershipPrice -
        (cartPrice - upsellPriceCalculator(purchaseItems, "Contributor").price)
      }, become a Contributor at the Barnes!`;
  }

  // Remove cost of youth items for Patron
  if (membershipLevel.info.name === "Patron") {
    return (
      cartPrice: number,
      purchaseItems: { [key: string]: PurchaseItem }
    ) => `PATRON: For an additional
            $${
              membershipPrice -
              (cartPrice -
                purchaseItems["Youth"].quantity *
                  parseInt(purchaseItems["Youth"].unitPrice))
            }, become
            a Patron at the Barnes!`;
  }
};
