import React, { useEffect, useMemo, useState } from "react";

import { HorizontalCard } from "../../components/shared/horizontalCard";
import { SpinnerSuspense } from "../shared/spinner";
import { getArtworkOfTheDay } from "../../services/collectionService";
import { useAuthRequest } from "../../hooks/customHooks";

const IMGIX_URL = "https://d2r83x5xt28klo.cloudfront.net";
const COLLECTION_URL = "https://collection.barnesfoundation.org/objects";

const getImageURLs: (artObject: ArtObject) => { [key: string]: string } = ({
  id,
  imageSecret,
  imageOriginalSecret,
}) => {
  const imageIdReg = `${id}_${imageSecret}`;
  const imageIdOrig = `${id}_${imageOriginalSecret}`;

  return {
    imageURLSmall: `${IMGIX_URL}/${imageIdReg}_n.jpg`,
    imageURLOriginal: `${IMGIX_URL}/${imageIdOrig}_o.jpg`,
    imageURLLarge: `${IMGIX_URL}/${imageIdReg}_b.jpg`,
  };
};

// Cache artobject outside of component to prevent additional calls to server.
let storedArtObject: ArtObject;

export const ArtworkOfTheDay = () => {
  // Wrap all calls to api with authRequest hook, which will update error state on 403.
  const getArtworkOfTheDayAuth =
    useAuthRequest<() => Promise<ArtObject>>(getArtworkOfTheDay);

  // If we have a storedArtObject outside of component, consider component loaded and use.
  const [artObject, setArtObject] = useState<ArtObject>(
    storedArtObject || null
  );

  /** Fetch artwork of the day from server. */
  useEffect(() => {
    const fetchArtObject = async () => {
      try {
        if (!storedArtObject) {
          const artworkOfTheDay = await getArtworkOfTheDayAuth();

          // Update cache and state hook w/ call from server.
          storedArtObject = artworkOfTheDay;
          setArtObject(artworkOfTheDay);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchArtObject();
  }, [getArtworkOfTheDayAuth]);

  return (
    <SpinnerSuspense isLoaded={Boolean(artObject)}>
      {Boolean(artObject) && <ArtObjectImage artObject={artObject} />}
    </SpinnerSuspense>
  );
};

type ArtObjectImageProps = { artObject: ArtObject };
const ArtObjectImage: React.FC<ArtObjectImageProps> = ({
  artObject,
}: ArtObjectImageProps) => {
  const imageURLs = useMemo(
    () => (artObject ? getImageURLs(artObject) : null),
    [artObject]
  );

  const subtitle = `${artObject.people} — ${artObject.title}`;

  return (
    <HorizontalCard
      key={artObject.title}
      title="Artwork of the Day"
      subtitle={subtitle}
      src={imageURLs.imageURLLarge || null}
      link={`${COLLECTION_URL}/${artObject.id}`}
    >
      <p>
        {artObject.displayDate} {artObject.displayDate ? ", " : ""}
        {artObject.invno} {artObject.invno ? ". " : ""} {artObject.creditLine}
      </p>
      <p>{artObject.shortDescription}</p>
    </HorizontalCard>
  );
};
