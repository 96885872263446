import {
  EventTimeObject,
  CheckoutInputObject,
  Order,
} from "acme-ticketing-client";
import { executePayloadRequest } from "./httpService";
import {
  EventDetailPayload,
  MembershipLevelIdentifier,
  MembershipPurchase,
  PurchaseItem,
  OrderValidationPayload,
} from "../../common/payloads";
import { IPromotion } from "../../common/vipPayloads";
import Config from "../config";
import axios from "axios";

/**
 * Get ticket events from server.
 * @return {EventSummaryPayload} - Admission event summary object.
 */
export const getAdmissionEvent: () => Promise<EventDetailPayload> = async () =>
  executePayloadRequest<EventDetailPayload>({
    url: "/api/vip/event",
    method: "GET",
  });

/**
 * Get times associated w/ selected admission date.
 * @param {string} id - event id for admission
 * @param {Date} time - selected date for ticket purchase
 */
export const getAdmissionTime: (
  id: string,
  time: Date
) => Promise<EventTimeObject[]> = async (id, time) =>
  executePayloadRequest({
    url: `/api/vip/event/${id}?selected=${time.toISOString()}`,
    method: "GET",
  });

/**
 * Fetch all membership list.
 */
export const getMembershipList: () => Promise<MembershipLevelIdentifier[]> =
  async () =>
    executePayloadRequest({
      url: `/api/vip/membershipList`,
      method: "GET",
    });

export const validateOrder: (purchase: {
  items: PurchaseItem[] | MembershipPurchase[];
}) => Promise<OrderValidationPayload> = async (purchase) =>
  executePayloadRequest({
    url: `/api/vip/validate`,
    method: "POST",
    data: purchase,
  });

/**
 * Perform temporary server-side checkout.
 * @param {CheckoutInputObject} checkoutInput - checkout input object.
 * @param {string} eventTime - ISO9801 time of event.
 * @param {string} token - recaptcha token.
 */
export const performTemporaryCheckout: (
  checkoutInput: CheckoutInputObject,
  eventTime: string,
  token: string
) => Promise<Order> = async (checkoutInput, eventTime, token) =>
  executePayloadRequest({
    url: `/api/vip/temporaryCheckout?eventTime=${eventTime}`,
    method: "POST",
    data: {
      checkoutInput,
      token,
    },
  });

/** Retrieve the promotion for the provided offer code
 * @param {string} offerCode - The code for the offer to retrieve
 */
export const retrievePromotion = async (
  offerCode: string
): Promise<IPromotion> => {
  return await executePayloadRequest({
    url: `/api/vip/promotion?offer=${offerCode}`,
    method: "GET",
  });
};
/** Order Number we need to opt into the Temi-based Marketing Campaign
 * This makes a CORS request to the corresponding Lambda that handles opting in
 * @param {string} orderNumber - The code for the offer to retrieve
 */
export const performMarketingOptIn = async (orderNumber: string) => {
  await axios(Config.smsOptInUrl, {
    method: "POST",
    data: {
      orderNumber,
    },
  });
};
