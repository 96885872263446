import querystring from "querystring";
import { executePayloadRequest } from "./httpService";
import { LogoutPayload } from "../interfaces/logoutPayload";
import { MobileTicketingPayload } from "../interfaces/mobileTicketPayload";
import { AuthenticationPayload, LoginPayload } from "../../common/payloads";

class AuthenticateService {
  authenticateWithIdentifier = async (
    input: string,
    sendText = true,
    params?: string
  ): Promise<AuthenticationPayload | undefined> => {
    try {
      let response = await fetch(
        sendText ? "/api/authenticate" : "/api/authenticate?sendText=false",
        {
          method: "POST",
          body: JSON.stringify({ identifier: input, params }),
          headers: { "Content-Type": "application/json" },
        }
      );

      console.log("auth service " + input);

      let authenicateResponse =
        (await response.json()) as AuthenticationPayload;
      return authenicateResponse;
    } catch (error) {
      console.log(`Could not authenticate input due to ${error}`);
    }
  };

  authenticateWithMemberId = async (input: string, memberId: string) =>
    executePayloadRequest<LoginPayload>({
      url: `/api/loginMemberId`,
      method: "POST",
      data: { identifier: input, memberId },
    });

  logout = async (): Promise<LogoutPayload | undefined> => {
    let logoutResponse: LogoutPayload;

    try {
      let response = await fetch("/api/logout", { method: "GET" });

      logoutResponse = (await response.json()) as LogoutPayload;
      return logoutResponse;
    } catch (error) {
      console.log(`An error occurred when trying to logout ${error}`);
    }
  };

  mobileTicketing = async (
    input: string
  ): Promise<MobileTicketingPayload | undefined> => {
    try {
      let response = await fetch("/api/mobileTicketing", {
        method: "POST",
        body: JSON.stringify({ identifier: input }),
        headers: { "Content-Type": "application/json" },
      });

      console.log("auth service " + input);

      let MobileTicketingResponse =
        (await response.json()) as MobileTicketingPayload;
      return MobileTicketingResponse;
    } catch (error) {
      console.log(`Could not authenticate mobile ticket input due to ${error}`);
    }
  };
}

export default AuthenticateService;

/**
 * Authenticate user with uuid from querystring.
 * @param i - uuid from querystring.
 * @returns {void}
 * @throws - @see httpService
 */
export const getMobileTicketing = async (i: string): Promise<void> =>
  executePayloadRequest<void>({
    url: `/mobileLogin?${querystring.stringify({ i })}`,
    method: "GET",
    isRedirect: true,
  });

/**
 * Authenticate user with uuid from querystring.
 * @param u - encrypted member id
 * @param x - encrypted expiration date
 * @param r - optional redirect
 * @returns {void}
 * @throws - @see httpService
 */
export const getMarketingLogin = async (
  u: string,
  x: string,
  r?: string
): Promise<void> =>
  executePayloadRequest<void>({
    url: `/ml?${querystring.stringify({ u, x })}${r ? "&r=" + r : ""}`,
    method: "GET",
    isRedirect: true,
  });
