import { createContext } from "react";

type MenuContextType = {
  isSideMenuActive: boolean;
  setIsSideMenuActive: React.Dispatch<React.SetStateAction<boolean>>;
  siteHeaderStatus: string;
  setSiteHeaderStatus: React.Dispatch<React.SetStateAction<string>>;
};
export const MenuContext = createContext<MenuContextType>({
  isSideMenuActive: false,
  setIsSideMenuActive: null,
  siteHeaderStatus: "default",
  setSiteHeaderStatus: null,
});
