import React, { useRef, useState, useEffect } from "react";
import { ReactComponent as RoamIcon } from "../icons/roam.svg";

/**
 * Dynamic import on component load.
 * This is to break up bundle size, as we do not necessarily need this immediately.
 * @see scrollTo inside of Slider.
 */
(async () => {
  const { default: smoothscroll } = await import("smoothscroll-polyfill");
  smoothscroll.polyfill();
})();

export type Description = {
  fieldName?: string;
  value: string;
  modifier?: "title" | "sub-header" | "detail" | "armn";
};

type SliderCardProps = {
  qr: string;
  descriptions: Description[];
  includeRoamLogo?: boolean;
};
export const SliderCard: React.FC<SliderCardProps> = ({
  qr,
  descriptions,
  includeRoamLogo,
}: SliderCardProps) => {
  return (
    <div className="slider-card">
      {/* QR Code */}
      {qr && (
        <div className="slider-card__qr-wrapper">
          <img className="slider-card__qr" src={qr} alt="qrcode" />
        </div>
      )}

      {/** slider-card description. */}
      <div className="slider-card__information">
        {includeRoamLogo && <RoamIcon className="slider-card__armn-logo" />}

        {descriptions.map(({ fieldName, value, modifier }, i) => {
          let descriptionClassName = "slider-card__description";
          if (modifier) {
            descriptionClassName = `${descriptionClassName} slider-card__description--${modifier}`;
          }

          return (
            <div
              className={descriptionClassName}
              key={i}
              style={{
                width: "100%",
              }}
            >
              {fieldName && (
                <span className="slider-card__description-title">
                  {fieldName}:{" "}
                </span>
              )}
              <span className="slider-card__description-value">{value}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const Slider: React.FC = ({ children }: any) => {
  const ref = useRef<HTMLDivElement>(null);
  const sto = useRef<ReturnType<typeof setTimeout>>(null); // ref for scroll sto.

  const [visibleChildIndex, setVisibleChildIndex] = useState(0);

  const childCount = React.Children.count(children);
  const widthPercent = childCount === 1 ? 100 : 78 * childCount;

  // Listen to scroll event.
  // We wrap state update in an sto to throttle potential state updates.
  const checkVisibleChild = () => {
    if (ref.current) {
      // If we have a current timeout, clear it.
      if (sto.current) {
        clearTimeout(sto.current);
      }

      sto.current = setTimeout(() => {
        const visibleIndex = Array.from(ref.current.children).findIndex(
          (child) => {
            const { left, width } = child.getBoundingClientRect();

            return left >= -1 && left < width;
          }
        );

        // If a visibleIndex was located.
        if (visibleIndex > -1) {
          setVisibleChildIndex(visibleIndex);
        }
      }, 100);
    }
  };

  // Clean up sto on unmount.
  useEffect(() => {
    return () => {
      if (sto.current) {
        clearTimeout(sto.current);
      }
    };
  }, []);

  return (
    <>
      <div className="slider" onScroll={checkVisibleChild}>
        <div
          className="slider__content"
          style={{ width: `${widthPercent}%` }}
          ref={ref}
        >
          {children}
        </div>
      </div>
      {childCount > 1 && (
        <div className="slider__bubbles-wrapper">
          <div className="slider__bubbles">
            {[...Array(childCount)].map((x, i) => {
              let sliderBubbleClassName = "slider__bubble";
              if (i === visibleChildIndex) {
                sliderBubbleClassName = `${sliderBubbleClassName} slider__bubble--active`;
              }

              return (
                <button
                  key={i}
                  className={sliderBubbleClassName}
                  onClick={() => {
                    if (ref.current) {
                      const scrollTo = Array.from(ref.current.children)[i];

                      if (scrollTo) {
                        scrollTo.scrollIntoView({
                          behavior: "smooth",
                          block: "nearest",
                          inline: "center",
                        });
                        setVisibleChildIndex(i);
                      }
                    }
                  }}
                ></button>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
