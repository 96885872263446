import React from "react";

import { TitleBar } from "./titleBar";
import { HeroBanner } from "./shared/heroBanner";
import { HorizontalCard } from "./shared/horizontalCard";
import { generateImgixUrl } from "../util/generateImgixUrl";

const backgroundSrc = generateImgixUrl(
  "sharedBackgroundImages/BF221",
  "fit=crop&auto=compress&w=1500&h=350"
);

export const StyleGuide: React.FC = () => {
  return (
    <div className="app__content">
      <TitleBar />
      <div className="wrapper" id="style-guide">
        <HeroBanner header="Style Guide" src={backgroundSrc} />
      </div>

      <hr />
      <div id="cards" className="card-list">
        <h3>Cards</h3>
        <HorizontalCard
          title="Horizontal Card"
          subtitle="This card is horizontal"
          link="#"
          internalLink
        >
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </HorizontalCard>
      </div>
    </div>
  );
};
