import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { v4 as uuidv4 } from "uuid";
import {
  MembershipLevelObject,
  MembershipOfferingObject,
} from "acme-ticketing-client/dist/src/interfaces/acmeMembershipLevelsPayloads";
import { ShoppingCartObject } from "acme-ticketing-client";
import { useHistory } from "react-router-dom";

import {
  createCart,
  getLevels,
  submitRenewal,
  updateCart,
  updateUserSession,
} from "../../../services/membershipServices";
import { HeroBanner } from "../../shared/heroBanner";
import { Error } from "../../shared/error";
import { Modal, ModalProps } from "../../shared/modal";
import {
  MembershipDetails,
  MembershipLevelOptionsPayload,
  EligibleMembershipLevel,
} from "../../../../common/payloads";
import { MemberDataType } from "../../../../common/types";
import { AccordionStep } from "../../../constants/enum";
import { MembershipRenewType } from "../../../../common/types";
import { useRenew } from "../../../hooks/useRenew";
import { MemberInfo } from "./renewalItems/memberInfo";
import { RenewSummary } from "./renewalItems/renewSummary";
import { SpinnerSuspense } from "../../shared/spinner";
import { LevelSelector } from "./levelSelector";
import { PaymentForm } from "./paymentForm";
import { Confirmation } from "./confirmation";
import { UserContext } from "../../../contexts/userContext";
import { ModalContext } from "../../../contexts/modalContext";
import { AuthContext } from "../../../contexts/authContext";
import {
  getContactInfo,
  ContactInfoType,
} from "../../../services/graphCmsService";
import { generateImgixUrl } from "../../../util/generateImgixUrl";

const backgroundSrc = generateImgixUrl(
  "sharedBackgroundImages/BF1179",
  "fit=crop&auto=compress&w=1500&h=350"
);

type RenewProps = {
  user: MembershipDetails;
  fetchUserData: () => void;
};

export const Renew: React.FC<RenewProps> = ({
  user,
  fetchUserData,
}: RenewProps) => {
  const { membershipStanding, setMembershipStanding } = useContext(UserContext);
  const { permsGroup } = useContext(AuthContext);
  const membershipActive = useMemo(
    () => membershipStanding === "active",
    [membershipStanding]
  );

  const history = useHistory();

  // State for managing cart returned by the API
  const [cartId, setCartId] = useState<string>(null);

  // Unique id to be passed in header to ACME to prevent dupe charges
  const [acmeReqUuid, setAcmeReqUuid] = useState(uuidv4());

  // State of accordion
  const [accordionStep, setAccordionStep] = useState(AccordionStep.Start);

  const {
    // State for managing cart data
    cart,
    setCart,
    renewType,
    setRenewType,
    donationAmount,
    setDonationAmount,
    selectedLevel,
    setSelectedLevel,
    // Fn for handling updating cart data inside handleUpdateCart
    handleUpdateCartData,
    // State for managing member info
    primaryCardholderInputs,
    secondaryCardholderInputs,
    includeSecondCardHolder,
    setIncludeSecondCardHolder,
    // State for payment information
    creditCardInputs,
    primaryForBilling,
    // Fn for handling populating the credit card form with primary cardholder address
    usePrimaryForBilling,
  } = useRenew(user);

  const [eligibleLevels, setEligibleLevels] =
    useState<EligibleMembershipLevel[]>();

  // State of standard price and offer price
  const [standardPrice, setStandardPrice] = useState();
  const [offerPrice, setOfferPrice] = useState();
  const [offerCode, setOfferCode] = useState<string>();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(true);
  // state to determine confirmation button styling, if isLoading show spinner suspense and btn-barnes--disabled button styling, if !isLoading btn-barnes styling
  const [isLoading, setIsLoading] = useState(false);
  // State for Barnes contact information
  const [contactInfo, setContactInfo] = useState<ContactInfoType>();

  // State for managing errors
  const { setShowModal } = useContext(ModalContext);
  const defaultErrorMessage = `<p>An error ocurred while processing your membership renewal!</p><p>Please contact <a href="mailto:${
    contactInfo ? contactInfo.email : "members@barnesfoundation.org"
  }">${
    contactInfo ? contactInfo.email : "members@barnesfoundation.org"
  }</a> or <a href="tel:${contactInfo ? contactInfo.phone : "215-278-7100"}">${
    contactInfo ? contactInfo.phone : "215-278-7100"
  }</a> for assistance.</p>`;
  const defaultErrorModal = useMemo(
    () => ({
      dismissible: membershipActive,
      buttonText: membershipActive ? "Return to Member Portal" : undefined,
      onClick: () => history.push("/user/home"),
    }),
    [history, membershipActive]
  );
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);
  const [errorModal, setErrorModal] = useState<ModalProps>();
  const [validationError, setValidationError] = useState("");

  // Set error modal props when membership standing changes
  useEffect(() => {
    setErrorModal(defaultErrorModal);
    setShowModal(false);
  }, [defaultErrorModal, setShowModal]);

  // State for managing updated user details post renew
  const [issuedGuestPasses, setIssuedGuestPasses] = useState(false);
  const [updatedMember, setUpdatedMember] = useState<MemberDataType>();
  const [orderNumber, setOrderNumber] = useState<string>();

  /**
   * Call to API to create an empty cart for the renewal process
   */
  const handleCreateCart = useCallback(async () => {
    try {
      const response = await createCart({});

      if (response.status === 200) {
        setCartId(response.data["cartId"]);
        setCart({
          id: response.data["cartId"],
          tenantId: process.env.REACT_APP_ACME_TENANT_ID,
          membershipId: user.membershipId,
          membershipIds: [user.membershipId],
          items: [],
        });
      } else {
        setErrorMessage(defaultErrorMessage);
        setErrorModal(defaultErrorModal);
        setShowModal(true);
      }
    } catch (e) {
      console.log("Error:", e);
      setErrorMessage(defaultErrorMessage);
      setErrorModal(defaultErrorModal);
      setShowModal(true);
    }
  }, [
    user.membershipId,
    defaultErrorMessage,
    defaultErrorModal,
    setShowModal,
    setCart,
  ]);

  /**
   * Call to the API to update the existing cart with user input
   */
  const handleUpdateCart = useCallback(async () => {
    const data = handleUpdateCartData();

    try {
      const response = await updateCart(cartId, data);

      if (response.status === 200) {
        // Set cart state with API response
        setCart(response.data as ShoppingCartObject);
      } else {
        setErrorMessage(defaultErrorMessage);
        setErrorModal(defaultErrorModal);
        setShowModal(true);
      }
    } catch (e) {
      console.log("Error:", e);
      setErrorMessage(defaultErrorMessage);
      setErrorModal(defaultErrorModal);
      setShowModal(true);
    }
  }, [
    setCart,
    cartId,
    handleUpdateCartData,
    defaultErrorMessage,
    defaultErrorModal,
    setShowModal,
  ]);

  /**
   * Set selected level in state
   */
  const handleSelectLevel: (
    level: MembershipLevelObject,
    type: MembershipRenewType,
    offer?: MembershipOfferingObject,
    appliedOffer?: string
  ) => void = useCallback(
    (level, type, offer = undefined, appliedOffer = undefined) => {
      const standardOffer = level.offerings[0];
      setStandardPrice(standardOffer.priceLists[0].prices[0].price);
      const selectedOffer = offer ? offer : standardOffer;
      setOfferPrice(selectedOffer.priceLists[0].prices[0].price);

      setSelectedLevel({
        name: level.name,
        offer: selectedOffer !== standardOffer ? selectedOffer.name : "",
        membershipId: user.membershipId,
        membershipOfferingId: selectedOffer.id,
        membershipCategoryId: level.id,
        pricePointId: selectedOffer.priceLists[0].prices[0].pricePoint.id,
        isGift: false,
      });

      setIncludeSecondCardHolder(level.rules.numberOfCardHolders === 2);
      setRenewType(type);
      setOfferCode(appliedOffer);
    },
    [
      user.membershipId,
      setSelectedLevel,
      setRenewType,
      setIncludeSecondCardHolder,
    ]
  );

  const cardHoldersValid = useCallback(() => {
    const primaryCardHolderValid =
      primaryCardholderInputs.validateCardHolder(true);
    let secondaryCardHolderValid = true;

    // Validate secondary card holder if membership includes 2 cardholders
    if (includeSecondCardHolder) {
      secondaryCardHolderValid =
        secondaryCardholderInputs.validateCardHolder(false);
    }

    return primaryCardHolderValid && secondaryCardHolderValid;
  }, [
    primaryCardholderInputs,
    secondaryCardholderInputs,
    includeSecondCardHolder,
  ]);

  /**
   * Add donation info to cart
   */
  const handleAddDonation = useCallback(
    (data) => {
      setDonationAmount(data);
      handleUpdateCart();
    },
    [setDonationAmount, handleUpdateCart]
  );

  /**
   * Submit form data to the API
   */
  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    // Set this to indicate that submission is in progress
    setIsSubmitted(false);

    try {
      const paymentDetails = { ...creditCardInputs.values };
      const response = await submitRenewal(cartId, paymentDetails, acmeReqUuid);
      const { progress, member, confirmation } = response.data;

      // set state relating to user data that was updated during the API call
      setIssuedGuestPasses(progress.issueGuestPasses);
      const memberData = {
        ...member,
        membershipId: member.membershipId.toString(),
        memberCardId: member.id,
        memberEmail: member.email,
      };
      member && setUpdatedMember(memberData);
      member && setMembershipStanding(member.membershipStanding);
      confirmation &&
        confirmation.orderNumber &&
        setOrderNumber(confirmation.orderNumber);

      // Handle response based on status code
      if (response.status === 200) {
        // 200 -- renewal successful and all member data is updated
        return true;
      } else if (response.status === 299) {
        // 299 -- renewal successful, but not all member data updated
        console.log("Error when updating member data", response);

        // If the server session data was not updated, make another call to API to trigger update
        if (!progress.setSession) {
          updateUserSession(memberData);
        }

        return true;
      } else {
        // Handle error
        console.log("API error:", response);
        return false;
      }
    } catch (e) {
      console.log("Error:", e);
      return false;
    } finally {
      setIsLoading(false);
      // Set this to indicate that submission has completed
      setIsSubmitted(true);
      // Reset the ACME request ID once call to API complete
      setAcmeReqUuid(uuidv4());
    }
  }, [acmeReqUuid, cartId, creditCardInputs.values, setMembershipStanding]);

  /**
   * Helper function to validate when moving to next form section and setting form section
   */
  const validateAndContinue = useCallback(
    async (data?: any) => {
      switch (accordionStep) {
        case 0: // Start
          // Do nothing for preliminary step before content is loaded
          break;

        case 1: // MembershipLevel
          const { level, type, offer, appliedOffer } = data;
          handleSelectLevel(level, type, offer, appliedOffer);
          handleUpdateCart();
          setAccordionStep(AccordionStep.MemberInfo);
          break;

        case 2: // MemberInfo
          if (cardHoldersValid()) {
            handleUpdateCart();
            setValidationError(" ");
            setAccordionStep(AccordionStep.PaymentInfo);
          } else {
            setValidationError(
              "Please fill out all required fields to continue."
            );
          }
          break;

        case 3: // PaymentInfo
          if (creditCardInputs.validateCreditCard()) {
            setValidationError(" ");
            setAccordionStep(AccordionStep.RenewalSummary);
          } else {
            setValidationError("Invalid credit card info");
          }
          break;

        case 4: // RenewalSummary
          if (creditCardInputs.validateCreditCard() && cardHoldersValid()) {
            setValidationError(" ");
            const submitted = await handleSubmit();

            if (submitted) {
              setAccordionStep(AccordionStep.RenewalConfirmation);
            } else {
              setErrorMessage(
                `<p>An error ocurred while processing your membership renewal!</p><p>Please contact <a href="mailto:${
                  contactInfo
                    ? contactInfo.email
                    : "members@barnesfoundation.org"
                }">${
                  contactInfo
                    ? contactInfo.email
                    : "members@barnesfoundation.org"
                }</a> or <a href="tel:${
                  contactInfo ? contactInfo.phone : "215-278-7100"
                }">${
                  contactInfo ? contactInfo.phone : "215-278-7100"
                }</a> for assistance.</p>`
              );
              setErrorModal(defaultErrorModal);
              setShowModal(true);
            }
          } else {
            setValidationError(
              "Please check your cardholder and payment information."
            );
          }
          break;
        case 5: // RenewalConfirmation
          setValidationError(" ");
          // Do nothing for confirmation step
          break;
      }
    },
    [
      accordionStep,
      handleSelectLevel,
      handleUpdateCart,
      handleSubmit,
      cardHoldersValid,
      creditCardInputs,
      defaultErrorModal,
      setErrorModal,
      setShowModal,
      contactInfo,
    ]
  );

  /**
   * Load membership levels and create cart on page render
   */
  useEffect(() => {
    const getMembershipLevels = async () => {
      try {
        const response = await getLevels();

        if (response.status === 200) {
          setIsLoaded(true);

          const { membershipOptions } =
            response.data as MembershipLevelOptionsPayload;

          if (
            membershipOptions.eligibleMembershipLevels.length &&
            membershipOptions.sourceMembershipLevel
          ) {
            setEligibleLevels(membershipOptions.eligibleMembershipLevels);

            // Set default selected level to the member's current level
            handleSelectLevel(
              membershipOptions.sourceMembershipLevel,
              "MembershipRenewal"
            );
            setAccordionStep(AccordionStep.MembershipLevel);
          } else {
            setErrorMessage(
              `<p>Your membership is not eligible for renewal at this time.</p>`
            );
            setErrorModal({
              ...defaultErrorModal,
              dismissible: false,
            });
            setShowModal(true);
          }
        } else {
          setErrorMessage(defaultErrorMessage);
          setErrorModal(defaultErrorModal);
          setShowModal(true);
        }
      } catch (e) {
        console.log("Error:", e);
      }
    };

    getMembershipLevels();
    handleCreateCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Set Initial Card Holder Values
   */
  useEffect(() => {
    const primary = user.cardholders[0];
    const secondary = user.cardholders[1];

    primary && primaryCardholderInputs.setInitialValues(primary);
    secondary && secondaryCardholderInputs.setInitialValues(secondary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Updates modal if membership is not active
   */
  useEffect(() => {
    setErrorMessage(
      "<div><p>Your membership to the Barnes Foundation has expired.</p><p>Renew your membership today to continue enjoying your benefits.</p></div>"
    );
    setErrorModal({
      dismissText: "Renew Now!",
      dismissible: true,
    });
    setShowModal(membershipStanding && !membershipActive);
  }, [membershipActive, membershipStanding, setShowModal]);

  /**
   * Update the user's cached data on unmount
   */
  useEffect(() => {
    return function updateUserData() {
      fetchUserData();
    };
  }, [fetchUserData]);

  /**
   * Fetch Barnes contact info
   */
  useEffect(() => {
    const fetchContactInfo = async () => {
      const contact = await getContactInfo(permsGroup);
      setContactInfo(contact);
    };

    permsGroup && fetchContactInfo();
  }, [permsGroup, setContactInfo]);

  return (
    <div className="wrapper" id="settings">
      <HeroBanner src={backgroundSrc} header="Member Renewal" />
      <Modal {...errorModal}>
        <Error message={errorMessage} />
      </Modal>
      <SpinnerSuspense isLoaded={isLoaded}>
        {/* RENEWAL ITEM 1 */}
        <LevelSelector
          eligibleLevels={eligibleLevels}
          onClick={validateAndContinue}
          setErrorMessage={setErrorMessage}
          setErrorModal={setErrorModal}
          accordionStep={accordionStep}
          setAccordionStep={setAccordionStep}
        />
        {/* RENEWAL ITEM 2 */}
        <MemberInfo
          primaryCardholder={primaryCardholderInputs.fields}
          secondaryCardholder={secondaryCardholderInputs.fields}
          onClick={validateAndContinue}
          includeSecondCardHolder={includeSecondCardHolder}
          errorMessage={validationError}
          accordionStep={accordionStep}
          setAccordionStep={setAccordionStep}
        />
        {/* RENEWAL ITEM 3 */}
        <PaymentForm
          creditCardInputs={creditCardInputs}
          autoPopulate={usePrimaryForBilling}
          autoPopulateVal={primaryForBilling}
          validationError={validationError}
          onClick={validateAndContinue}
          accordionStep={accordionStep}
          setAccordionStep={setAccordionStep}
        />
        {/* RENEWAL ITEM 4 */}
        <RenewSummary
          membershipCards={
            cart &&
            cart.items &&
            cart.items[0] &&
            cart.items[0].membershipInfo.membershipCards
          }
          selectedLevel={selectedLevel}
          creditCardInputs={creditCardInputs}
          includeSecondCardHolder={includeSecondCardHolder}
          offerPrice={offerPrice}
          standardPrice={standardPrice}
          offerCode={offerCode}
          onClick={validateAndContinue}
          isSubmitted={isSubmitted}
          isLoading={isLoading}
          accordionStep={accordionStep}
          setAccordionStep={setAccordionStep}
          handleAddDonation={handleAddDonation}
          donationAmount={donationAmount}
          setDonationAmount={setDonationAmount}
        />
        {/* RENEWAL ITEM 5 */}
        <Confirmation
          updatedMember={updatedMember}
          accordionStep={accordionStep}
          setAccordionStep={setAccordionStep}
          issuedGuestPasses={issuedGuestPasses}
          orderNumber={orderNumber}
        />
      </SpinnerSuspense>
    </div>
  );
};
