import React, { Fragment } from "react";
import parse from "html-react-parser";

import { ReactComponent as SadFace } from "./icons/sad-face.svg";

type ErrorProps = {
  message?: string | JSX.Element;
};

export const Error: React.FC<ErrorProps> = ({ message }: ErrorProps) => {
  const defaultMessage = (
    <Fragment>
      <p className="upgrade__error-text">
        Oops! We&apos;re having trouble completing your request.
      </p>
      <p className="upgrade__error-text">
        Please contact <a href="tel:2152787200">215-278-7200</a> for assistance.
      </p>
    </Fragment>
  );

  const parsedMessage =
    !!message && typeof message === "string" ? parse(message) : message;

  return (
    <div className="general__error">
      <SadFace className="general__sad-face" />
      <div className="general__error-text">
        {!!message ? parsedMessage : defaultMessage}
      </div>
    </div>
  );
};
