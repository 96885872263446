import React, { useState } from "react";

import { AccordionStep } from "../../../../constants/enum";
import { CardHolderFields } from "../../../../hooks/useCardHolderForm";
import { Form, FormSection } from "../../../shared/form/form";
import { RenewalItem } from "../renewalItem";

type MemberInfoProps = {
  primaryCardholder: CardHolderFields["fields"];
  secondaryCardholder: CardHolderFields["fields"];
  onClick: () => void;
  includeSecondCardHolder: boolean;
  errorMessage: string;
  accordionStep: number;
  setAccordionStep: (accordionStep: number) => void;
};
export const MemberInfo: React.FC<MemberInfoProps> = ({
  primaryCardholder,
  secondaryCardholder,
  onClick,
  includeSecondCardHolder,
  errorMessage,
  accordionStep,
  setAccordionStep,
}: MemberInfoProps) => {
  const isGuestOf =
    secondaryCardholder &&
    (secondaryCardholder.contact.firstName.value === "" ||
      secondaryCardholder.contact.firstName.value.includes("Guest of"));

  const [showSecondaryCardholder, setShowSecondaryCardholder] = useState(
    !isGuestOf
  );

  const toggleSecondaryCardholder = () => {
    const showSecondary = !showSecondaryCardholder;
    setShowSecondaryCardholder(showSecondary);

    // When secondary cardholder is removed, clear the cardholder info
    if (!showSecondary) {
      Object.values(secondaryCardholder.contact).map((field) =>
        field.setValue("")
      );
    }
  };

  return (
    <RenewalItem
      title={"Confirm Member Information"}
      isActive={accordionStep <= AccordionStep.MemberInfo}
      onClick={() => {
        setAccordionStep(AccordionStep.MemberInfo);
      }}
      isDeactivated={
        accordionStep < AccordionStep.MemberInfo ||
        accordionStep === AccordionStep.RenewalConfirmation
      }
      className={
        accordionStep === AccordionStep.Start
          ? "renewal__item--no-height"
          : null
      }
      id="member-information"
    >
      <div className="member-info">
        <Form header="Primary Cardholder" className="form__primary-cardholder">
          <FormSection fields={Object.values(primaryCardholder.contact)} />
          <FormSection fields={Object.values(primaryCardholder.address)} />
        </Form>
        {/* Secondary Cardholder Section */}
        {includeSecondCardHolder && (
          <>
            <button
              onClick={toggleSecondaryCardholder}
              className="btn-barnes-secondary"
            >
              {!showSecondaryCardholder
                ? "+ Add Second Cardholder Details*"
                : "- Remove Second Cardholder Details*"}
            </button>
            <p>
              * If details for second cardholder are not provided, the card will
              default to &quot;Guest of{" "}
              {primaryCardholder.contact.firstName.value}{" "}
              {primaryCardholder.contact.lastName.value}&quot;
            </p>

            {showSecondaryCardholder && (
              <Form
                header="Secondary Cardholder"
                className="form__secondary-cardholder"
              >
                <FormSection
                  fields={Object.values(secondaryCardholder.contact)}
                />
              </Form>
            )}
          </>
        )}
      </div>
      <div className="error-message">{errorMessage} </div>
      <button className="btn-barnes renewal__item--continue" onClick={onClick}>
        Continue
      </button>
    </RenewalItem>
  );
};
