import { OrderPayload, ShoppingCartObject } from "acme-ticketing-client";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { v4 as uuid } from "uuid";
import { APIPayload, TriposCCAuth } from "../../common/payloads";
import {
  CustomerInfo,
  KioskPaymentInfo,
  ReservationObject,
} from "../../common/types";
import { executeStatusPayloadRequest } from "./httpService";
import { generateTriPosHeaders } from "../util/tripos";
import config from "../config";
import { addServerLog } from "./loggerService";

/** Creates a new reservation in ACME
 * @param {string} eventId ACME event instance ID
 * @param {number} quantity Number of tickets to reserve
 * @param {ShoppingCartObject} cart Shopping cart containing the order items
 * @returns {ReservationObject} Reservation details
 */
export const createReservation = async (
  eventId: string,
  quantity: number,
  cart: ShoppingCartObject
): Promise<APIPayload<ReservationObject>> =>
  executeStatusPayloadRequest<ReservationObject>({
    url: "/api/kiosk/reservation",
    body: JSON.stringify({ eventId, quantity, cart }),
  });

/** Updates a reservation in ACME
 * @param {number} reservationId ACME reservation ID
 * @param {string} eventId ACME event instance ID
 * @param {number} quantity Number of tickets to reserve
 * @param {ShoppingCartObject} cart Shopping cart containing the order items
 * @returns {ReservationObject} Reservation details
 */
export const updateReservation = async (
  reservationId: number,
  eventId: string,
  quantity: number,
  cart: ShoppingCartObject
): Promise<APIPayload<ReservationObject>> =>
  executeStatusPayloadRequest<ReservationObject>({
    url: "/api/kiosk/reservation",
    method: "PUT",
    body: JSON.stringify({ reservationId, eventId, quantity, cart }),
  });

/** Deletes a reservation in ACME
 * @param {number} reservationId ACME reservation ID
 * @param {string} cartId ACME shopping cart ID
 * @returns {ReservationObject} Reservation details
 */
export const deleteReservation = async (
  reservationId: number,
  cartId: string
): Promise<APIPayload<ReservationObject>> =>
  executeStatusPayloadRequest<ReservationObject>({
    url: "/api/kiosk/reservation",
    method: "DELETE",
    body: JSON.stringify({ reservationId, cartId }),
  });

/** Checks out a shopping cart for kiosk purchase
 * @param {CustomerInfo} customerInfo Information for the guest making the purchase
 * @param {ShoppingCartObject} shoppingCart Shopping cart with purchase information
 * @param {KioskPaymentInfo} paymentInfo CC information to complete purchase
 * @param {number} reservationId ACME reservation ID
 * @param {string} membershipLevel The name of the membership level for an upsell
 * @returns {OrderPayload & { smsLink: string }} Will call order detail object returned by ACME API
 */
export const kioskCheckout = async (
  customerInfo: CustomerInfo,
  shoppingCart: ShoppingCartObject,
  paymentInfo: KioskPaymentInfo,
  reservationId: number,
  membershipLevel?: string
): Promise<APIPayload<OrderPayload & { smsLink: string }>> =>
  executeStatusPayloadRequest<OrderPayload & { smsLink: string }>({
    url: "/api/kiosk/checkout",
    method: "POST",
    body: JSON.stringify({
      customerInfo,
      shoppingCart,
      paymentInfo,
      reservationId,
      membershipLevel,
    }),
  });

/** Authorizes credit card via triPOS
 * @param {number} laneId Lane id for the triPOS device
 * @param {string} transactionAmount Total amount for the transaction, formatted XXXX.XX
 * @returns {AxiosResponse<TriposCCAuth | { error: any; statusCode: string }>} TriPOS Authorization or error response
 */
export const authorizeCard = async (
  laneId: number,
  transactionAmount: string
): Promise<
  AxiosResponse<TriposCCAuth | { error: any; statusCode: string }>
> => {
  const body = {
    laneId,
    transactionAmount,
    ticketNumber: uuid(),
  };

  const headers = generateBaseHeaders();

  let config: AxiosRequestConfig = {
    url: "http://127.0.0.1:8080/api/v1/authorization",
    headers,
    method: "POST",
    data: body,
  };

  const authHeaders = generateTriPosHeaders(config);
  config.headers["tp-authorization"] = authHeaders;

  try {
    const res: AxiosResponse<TriposCCAuth> = await axios(
      "http://127.0.0.1:8080/api/v1/authorization",
      config
    );

    return res;
  } catch (error) {
    console.log(error);

    // Log error on the server side
    addServerLog({
      type: "ERROR",
      message: { message: "ERROR AUTHENTICATING CC", error },
    });

    return {
      data: { error, statusCode: "Error" },
      status: 500,
      statusText: "ERROR",
      headers,
      config,
    };
  }
};

const generateBaseHeaders = () => {
  const auth = "Version=1.0, Credential=" + config.triPosDevKey;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "tp-application-id": config.expressAppId,
    "tp-application-version": "1.0.0",
    "tp-authorization": auth,
    "tp-application-name": "BarnesKiosk",
    "tp-request-id": uuid(),
  };
  return headers;
};
