import React, { useCallback, useState } from "react";
import classnames from "classnames";
import Reaptcha from "reaptcha";
import { PurchaseItem } from "../../../../common/payloads";
import { SpinnerSuspense } from "../../shared/spinner";
import { checkPaymentMethodComplete } from "../helpers/validateCreditCard";
import { PaymentMethod } from "./usePaymentMethod";

type EventDetailRecaptchaButtonProps = {
  onBookClick: (token: string) => Promise<void>;
  purchase: { items: PurchaseItem[] };
  purchaseRequiresPayment: boolean;
  paymentMethod: PaymentMethod;
  recaptchaExecute: () => Promise<string>;
};

export const EventDetailRecaptchaButton: React.FC<EventDetailRecaptchaButtonProps> =
  ({
    onBookClick,
    purchase,
    purchaseRequiresPayment,
    paymentMethod,
    recaptchaExecute,
  }: EventDetailRecaptchaButtonProps) => {
    // Reflect loading state when booking is being processed.
    const [isSearching, setIsSearching] = useState(false);
    const [validationError, setValidationError] = useState("");
    const [disableSubmit, setDisableSubmit] = useState(true);

    const isOrderIncomplete = Boolean(
      !purchase.items.length ||
        (purchaseRequiresPayment && !checkPaymentMethodComplete(paymentMethod))
    );

    const onClick = useCallback(async () => {
      if (isOrderIncomplete) {
        setValidationError("Please fill out all required fields to continue.");
      } else {
        setValidationError("");
        try {
          setIsSearching(true);
          const token = await recaptchaExecute();
          await onBookClick(token);
        } catch (e) {
          console.log(e);
        } finally {
          setIsSearching(false);
        }
      }
    }, [onBookClick, recaptchaExecute, isOrderIncomplete]);

    return (
      <>
        <div className="error-message">{validationError} </div>
        <div className="complete-booking-btn">
          <div>
            <Reaptcha
              sitekey={process.env.REACT_APP_BARNES_RECAPTCHA_SITE}
              onVerify={() => setDisableSubmit(false)}
            />
            <button
              className="btn-barnes"
              disabled={disableSubmit}
              onClick={onClick}
            >
              <SpinnerSuspense
                isLoaded={!isSearching}
                className="check-in__spinner"
              >
                {purchaseRequiresPayment ? "Complete Booking" : "Book Tickets"}
              </SpinnerSuspense>
            </button>
          </div>
        </div>
      </>
    );
  };
