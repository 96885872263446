import { HorizontalCard } from "../../components/shared/horizontalCard";
import React from "react";
import { generateImgixUrl } from "../../util/generateImgixUrl";

const BARNES_FOCUS_SRC = generateImgixUrl(
  "sharedHomepageImages/BarnesFocus_3544",
  "fit=crop&fm=pjpg&fp-x=0.5&fp-y=0.5&h=560&ixlib=php-2.1.1&w=1220"
);
const FOCUS_URL = "https://barnesfoc.us/";

export const BarnesFocus = () => {
  return (
    <HorizontalCard
      key="Barnes Focus"
      title="A New Way to See the Barnes"
      subtitle="Barnes Focus"
      src={BARNES_FOCUS_SRC}
      link={FOCUS_URL}
    >
      <p>
        While you&apos;re in the gallery, don&apos;t forget to use Barnes Focus
        — our free digital guide that makes it fun and easy to explore and share
        art. Barnes Focus works on any smartphone with a web browser. In the
        galleries, simply navigate to <strong>barnesfoc.us</strong> on your
        device. Then use your phone&apos;s camera to focus on a work of art. And
        that&apos;s it! You&apos;ll instantly receive information about the
        piece.
      </p>
    </HorizontalCard>
  );
};
