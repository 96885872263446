import dateformat from "dateformat";

/**
 * Gives a universally formatted string for a range of dates.
 * TODO => This does not match the date format of orderForTicket page.
 * @param {Date} startDate - Lower date boundary.
 * @param {Date} endDate - Upper date boundary.
 * @returns {string} formatted date range.
 */
export const formatDateRange = (startDate: Date, endDate: Date): string => {
  // If start and end date are the same, return single day.
  if (
    startDate.getDate() === endDate.getDate() &&
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getFullYear() === endDate.getFullYear()
  ) {
    return dateformat(startDate, "longDate");
    // Otherwise, return range in the following format.
  } else {
    return `${dateformat(startDate, "longDate")} to ${dateformat(
      endDate,
      "longDate"
    )}`;
  }
};

/**
 * Format a single date, in AC the default format is 'dddd, mmmm dS, yyyy'.
 * @param {Date | string} date - date to be formatted.
 * @param {string} format - override for default format.
 * @returns {string} string format of date for universal formatting in application.
 */
export const formatSingleDate = (
  date: Date | string,
  format?: string
): string => dateformat(date, format || "mmmm dS, yyyy");

/**
 * Format a single date before sending to acme ticketing client.
 * @param {Date | string} date - date to be formatted.
 * @returns {string} string format of date to hit acme ticketing client.
 */
export const acmeFormatDate = (date: Date | string): string =>
  dateformat(date, "isoUtcDateTime");

/**
 * Format two dates for a time range.
 * @param {Date | string} startTime - date to be formatted.
 * @param {Date | string} endTime - date to be formatted.
 * @returns {string} string format of date time range.
 */
export const formatTimeRange = (
  startTime: Date | string,
  endTime: Date | string
) =>
  `${dateformat(startTime, "shortTime")} to ${dateformat(
    endTime,
    "shortTime"
  )}`;

/**
 * Formats a string in with name capitalization.
 * @param {string} name - String to format.
 * @returns {string} string formatted Like A. Name.
 */
export const formatName = (name: string) =>
  name
    .split(" ")
    .filter(Boolean) // Filter blank spaces.
    .map(
      (word) => word.charAt(0).toUpperCase() + word.toLocaleLowerCase().slice(1)
    )
    .join(" ");

/**
 * Format time string.
 * @param time - String to format.
 * @returns formatted time string used in event detail.
 */
export const formatTimeString = (time: string) => {
  const [start] = time
    .substring(0, time.indexOf(","))
    .replace(/ /g, "")
    .split("-");
  const timeString = `${start}`;
  return timeString;
};

/**
 * Escape single and double quotes in a string.
 * @param text - String to format.
 * @returns string with escaped single and double quotes.
 */
export const escapeQuotes = (text: string): string => {
  return text.replace(/\\([\s\S])|([",'])/g, "$1$2");
};

/**
 * Formats pathname for post-login redirect
 * @param location - location object
 * @returns {{ redirectPath: string | false, redirectQueryParams: string }} formatted redirect pathname and formatted query params
 */
export const formatRedirectPath = (
  location
): { redirectPath: string | false; redirectQueryParams: string } => {
  if (!location) {
    return { redirectPath: "", redirectQueryParams: "" };
  }

  const { pathname, search } = location;

  const queryParams = [];
  let redirectPath = "";

  // Format the redirect query param
  redirectPath =
    pathname !== "" &&
    pathname !== "/" &&
    pathname !== "/mobileLogin" &&
    pathname;
  redirectPath.length && queryParams.push(`redirect=${redirectPath}`);

  // Split the previous query params
  const prevLocationParams = search.substring(1).split("&");
  let eventId;
  let orderItemId;

  // Add all previous params except for i
  if (prevLocationParams.length) {
    prevLocationParams.forEach((param) => {
      const paramParts = param.split("=");

      switch (paramParts[0]) {
        case "":
        case "i":
          break;
        case "eventId":
          eventId = paramParts[1];
          break;
        case "orderItemId":
          orderItemId = paramParts[1];
          break;
        default:
          queryParams.push(param);
          break;
      }
    });
  }

  // Format eventId and orderItemId into redirect path
  if (eventId && orderItemId) {
    redirectPath = `/user/my-orders/${eventId}/${orderItemId}`;
    queryParams.push(`redirect=${redirectPath}`);
  }

  return { redirectPath, redirectQueryParams: `${queryParams.join("&")}` };
};

/**
 * Format dollar amount for shopping cart api
 * @param {string} amount - dollar amount to be formatted.
 * @returns {string} formatted dollar amount.
 */
export const formatDollarAmount = (amount: string) => {
  const formattedAmount =
    // remove commas and dollar signs, add decimals with a precision of 2
    (Math.round(parseFloat(amount.replace(/\$|,/g, "")) * 100) / 100).toFixed(
      2
    );
  if (formattedAmount === "0.00") {
    return "0";
  } else {
    return formattedAmount;
  }
};
