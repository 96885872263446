import { NavLinkProps } from "react-router-dom";

export type TopNavLink = {
  href: string;
  name: string;
  permission?: string;
  isActive?: NavLinkProps["isActive"];
};

export const TOP_NAV_LINKS: TopNavLink[] = [
  { href: "/user/home", name: "Home" },
  { href: "/user/news", name: "News" },
  {
    href: "/user/admission",
    name: "Book Tickets",
    isActive: (match, location) =>
      location.pathname.includes("admission") ||
      (match && match.url === "/user/events"),
  },
  {
    href: "/user/events",
    name: "Events",
    isActive: (match, location) =>
      location.pathname.includes("event") ||
      (match && match.url === "/user/events"),
  },
  { href: "/user/classes", name: "Classes" },
  {
    href: "/user/guest-passes",
    name: "Guest Passes",
    permission: "guestPasses",
  },
  { href: "/user/my-orders", name: "My Orders" },
];

export const MIDDLE_NAV_LINKS = [
  {
    href: "/user/membership",
    name: "Membership Card",
  },
  { href: "/user/settings", name: "Settings" },
];
