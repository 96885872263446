import React, { useEffect, useRef, useState } from "react";
import "react-day-picker/lib/style.css";
import {
  EventDetailPayload,
  MembershipDetails,
  MembershipLevelIdentifier,
} from "../../../common/payloads";
import { EventTimeObject, Order } from "acme-ticketing-client";
import { formatSingleDate, formatTimeString } from "../../util/formatting";
import {
  getEventDetails,
  getEventDetailsTime,
} from "../../services/apiRequestor";
import { useAuthRequest, useScrollTop } from "../../hooks/customHooks";
import { useLocation, useParams } from "react-router-dom";
import { DayModifiers } from "react-day-picker";
import { EventDetailCheckIn } from "./eventDetailCheckIn/eventDetailCheckIn";
import { EventDetailDayPicker } from "./eventDetailDayPicker";
import { EventDetailOrderConfirmation } from "./eventDetailOrderConfirmation";
import { EventDetailTimeSelect } from "./eventDetailTimeSelect";
import { HeroBanner } from "../shared/heroBanner";
import config from "../../config";
import { getAdmissionTime } from "../../services/vipService";
import { load } from "recaptcha-v3";
import { AccordionContainer } from "../accordionContainer";
import { generateImgixUrl } from "../../util/generateImgixUrl";

const backgroundSrc = generateImgixUrl(
  "sharedBackgroundImages/BF2044_210209_160235_2",
  "fit=crop&auto=compress&w=1500&h=350"
);

enum AccordionStep {
  Start = 0,
  ArrivalDate = 1,
  ArrivalTime = 2,
  TicketType = 3,
  Payment = 4,
  OrderConfirmation = 5,
}

type EventDescription = {
  src: string;
  html: string;
  name: string;
  thumbnail: string;
};

type EventDetailProps = {
  cardholder?: MembershipDetails["cardholders"][0];
  updateOrders?: () => Promise<void>;
  eventDetailPayload?: EventDetailPayload;
  membershipLevels?: MembershipLevelIdentifier[];
  isVip?: boolean;
};
export const EventDetail: React.FC<EventDetailProps> = ({
  updateOrders,
  cardholder,
  eventDetailPayload,
  membershipLevels,
  isVip,
}: EventDetailProps) => {
  useScrollTop();

  const { id } = useParams<{ id?: string }>();

  const { state } = useLocation<EventDescription>();

  // Wrap all calls to api with authRequest hook, which will update error state on 403.
  const getEventDetailsAuth =
    useAuthRequest<(id: string) => Promise<EventDetailPayload>>(
      getEventDetails
    );
  const getEventDetailsTimeAuth =
    useAuthRequest<(id: string, time: Date) => Promise<EventTimeObject[]>>(
      getEventDetailsTime
    );

  // If image has been loaded
  const [description, setDescription] = useState<EventDescription>({
    src: (state && state.src) || null,
    html: (state && state.html) || null,
    name: (state && state.name) || null,
    thumbnail: null,
  });

  // State of form data
  const [activeDays, setActiveDays] = useState<Date[]>([]);
  const [selectedDay, setSelectedDay] = useState<Date>(null);
  const [times, setTimes] = useState<EventTimeObject[] | void>();
  const [selectedTime, setSelectedTime] = useState<EventTimeObject>(null);
  const [guestPassExchangeCount, setGuestPassExchangeCount] =
    useState<number>(0);
  const [order, setOrder] = useState<Order>(null);
  const [totalTicketCount, setTotalTicketCount] = useState(0);

  // State of accordion
  const [accordionStep, setAccordionStep] = useState(AccordionStep.Start);

  // Fetch event details.
  useEffect(() => {
    // TODO: see what can be removed from here
    // Previously we were using description.src as the HeroBanner background
    // see what is being used out of the state passed from the location, then remove what isn't being used
    if (id || eventDetailPayload) {
      const fetchEventDetails = async () => {
        try {
          const eventDetails =
            eventDetailPayload || (await getEventDetailsAuth(id));
          // Update state.
          setAccordionStep(AccordionStep.ArrivalDate);
          setActiveDays(eventDetails.days.map(({ date }) => new Date(date)));
          setDescription((description) => ({
            // Default to use the SRC we have in state.
            // If this does not exist, check if payload has a screen we can use.
            // Otherwise, set to null and set HeroBanner to no image state.
            src:
              description.src ||
              (eventDetails.images.length && eventDetails.images[0].screen)
                ? eventDetails.images[0].screen
                : null,
            html: description.html || eventDetails.description,
            name: description.name || eventDetails.name,
            thumbnail:
              eventDetails.images.length && eventDetails.images[0].thumbnail
                ? eventDetails.images[0].thumbnail
                : null,
          }));
        } catch (e) {
          console.log(e);
        }
      };

      fetchEventDetails();
    }
  }, []);

  // Set up recaptcha
  const recaptchaExecute = useRef(
    (() => {
      const unresolvedRecaptcha = load(config.acmeRecaptchaKey);

      return async () => {
        try {
          const recaptcha = await unresolvedRecaptcha;
          const token = await recaptcha.execute();

          return token;
        } catch (e) {
          console.error(e);
        }
      };
    })()
  );

  return (
    <div className="wrapper" id="event-detail">
      <HeroBanner header={description.name} src={backgroundSrc} />
      {isVip && (
        <div className="already-a-member center">
          <p>
            Already a member?{" "}
            <a
              className="a-brand-link"
              href={`${config.publicUrl}/?r=/user/admission`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Book here
            </a>
          </p>
        </div>
      )}
      <div className="event-detail">
        <AccordionContainer
          title={"Select your arrival date"}
          isActive={accordionStep <= AccordionStep.ArrivalDate}
          onClick={() => {
            setSelectedTime(null);
            setAccordionStep(AccordionStep.ArrivalDate);
          }}
          isDeactivated={
            accordionStep < AccordionStep.ArrivalDate ||
            accordionStep === AccordionStep.OrderConfirmation
          }
          selectionText={selectedDay && `${formatSingleDate(selectedDay)}`}
          className={
            accordionStep === AccordionStep.Start
              ? "event-detail__item--no-height"
              : null
          }
        >
          <EventDetailDayPicker
            activeDays={activeDays}
            selectedDay={selectedDay}
            setSelectedDay={(day: Date, modifiers: DayModifiers) => {
              if (activeDays && activeDays.length) {
                // Get time from server response and update our day with it.
                // This will keep time consistent across client and server.
                const [serverHours, serverMinutes] = [
                  activeDays[0].getHours(),
                  activeDays[0].getMinutes(),
                ];
                day.setHours(serverHours);
                day.setMinutes(serverMinutes);

                if (!modifiers.disabled) {
                  if (selectedTime) {
                    setSelectedTime(null);
                  }

                  setSelectedDay(day);
                  setAccordionStep(AccordionStep.ArrivalTime);

                  const fetchTimes = async () => {
                    // Clear existing times, if exist.
                    if (times) {
                      setTimes(null);
                    }

                    // If this is a VIP route, we will not need to send authenticated request.
                    const eventTimes = eventDetailPayload
                      ? await getAdmissionTime(eventDetailPayload.id, day)
                      : await getEventDetailsTimeAuth(id, day);

                    setTimes(eventTimes);
                  };

                  fetchTimes();
                }
              }
            }}
          />
        </AccordionContainer>

        <AccordionContainer
          title={"Select time of arrival"}
          isActive={accordionStep === AccordionStep.ArrivalTime}
          isDeactivated={
            accordionStep < AccordionStep.ArrivalTime ||
            accordionStep === AccordionStep.OrderConfirmation
          }
          onClick={() => setAccordionStep(AccordionStep.ArrivalTime)}
          selectionText={selectedTime && formatTimeString(selectedTime.time)}
        >
          {selectedDay && (
            <EventDetailTimeSelect
              times={times}
              selectedTime={selectedTime} // TODO would using times[0] work?
              setSelectedTime={(time: EventTimeObject) => {
                console.log("time", time);
                setSelectedTime(time);
                console.log("selectedTime", selectedTime);
                setAccordionStep(AccordionStep.TicketType);
              }}
              isActive={accordionStep === AccordionStep.ArrivalTime}
            />
          )}
        </AccordionContainer>

        <AccordionContainer
          title={"Select ticket types"}
          isActive={accordionStep === AccordionStep.TicketType}
          isDeactivated={
            accordionStep < AccordionStep.TicketType ||
            accordionStep === AccordionStep.OrderConfirmation
          }
          selectionText={totalTicketCount && `${totalTicketCount} Tickets`}
          onClick={() => {
            setAccordionStep(AccordionStep.TicketType);
          }}
        >
          {selectedDay && selectedTime && (
            <EventDetailCheckIn
              isVIPCheckout={!id}
              membershipLevels={membershipLevels}
              cardholder={cardholder}
              prices={selectedTime.event.priceList.prices}
              eventInfo={{
                eventId: selectedTime.event.id,
                eventName: selectedTime.event.name,
                eventTime: selectedTime.event.startTime,
                itemType: "Event",
                ignoreEntitlements: false,
                eventCategory: selectedTime.event.customFields.find(
                  (field) => field.name === "Category"
                ).value,
              }}
              setOrder={(order: Order, guestPassExchangeCount: number) => {
                // Update local state
                setOrder(order);
                setGuestPassExchangeCount(guestPassExchangeCount);
                setAccordionStep(AccordionStep.OrderConfirmation);

                // Update parent state, if callback exists.
                updateOrders && updateOrders();

                // Send email -- Removed to prevent sending duplicate emails.
                // sendOrderEmail(order.orderNumber);
              }}
              recaptchaExecute={recaptchaExecute.current}
              availableSeats={selectedTime.availableSeats}
              setTotalTicketCount={setTotalTicketCount}
              totalTicketCount={totalTicketCount}
            />
          )}
        </AccordionContainer>

        <AccordionContainer
          title={"Order confirmation"}
          isActive={accordionStep === AccordionStep.OrderConfirmation}
          isDeactivated={accordionStep < AccordionStep.OrderConfirmation}
        >
          <EventDetailOrderConfirmation
            isVIPCheckout={!id}
            order={order}
            thumbnail={description.thumbnail}
            guestPassExchangeCount={guestPassExchangeCount}
          />
        </AccordionContainer>
      </div>
    </div>
  );
};
