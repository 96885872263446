import React, { useContext, useState } from "react";
import classnames from "classnames";

import { MenuContext } from "../contexts/menuContext";
import { SideMenu } from "./menu/sideMenu";
import { SiteHeader } from "./menu/siteHeader";
import { UserContext } from "../contexts/userContext";
import { Logos } from "./shared/logos/logo";

export const TitleBar: React.FC = () => {
  const { userData, membershipStanding } = useContext(UserContext);
  const [isSideMenuActive, setIsSideMenuActive] = useState(false);
  const [siteHeaderStatus, setSiteHeaderStatus] = useState("default");

  return (
    <div className="title-bar">
      <MenuContext.Provider
        value={{
          isSideMenuActive,
          setIsSideMenuActive,
          siteHeaderStatus,
          setSiteHeaderStatus,
        }}
      >
        <header
          data-behavior="header"
          role="banner"
          className={classnames("site-header", {
            "site-header--unlocked": siteHeaderStatus === "unlocked",
            "site-header--locked": siteHeaderStatus === "locked",
            "site-header--vip": !userData.user,
          })}
        >
          <div className="header-container">
            {/** If we have userData, link to home, if we do not (eg: /vip path) link to main site. */}
            <Logos />
            {userData.user && <SiteHeader />}
          </div>
        </header>
        {userData.user && membershipStanding === "active" && <SideMenu />}
      </MenuContext.Provider>
    </div>
  );
};
