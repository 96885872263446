import { Description, Slider, SliderCard } from "../../shared/slider";
import { EventItem, OrderPayload } from "acme-ticketing-client";

import { BackLink } from "../backLink";
import { HeroBanner } from "../../shared/heroBanner";
import React from "react";
import { formatSingleDate } from "../../../util/formatting";
import { useQR } from "../../../hooks/customHooks";
import { generateImgixUrl } from "../../../util/generateImgixUrl";

const backgroundSrc = generateImgixUrl(
  "sharedBackgroundImages/BF388",
  "fit=crop&auto=compress&w=1500&h=350"
);

type SingleEventItemProps = {
  uuid: string;
  eventItem: EventItem;
  ticketTypeName: string;
};

const SingleEventItem: React.FC<SingleEventItemProps> = ({
  uuid,
  eventItem,
  ticketTypeName,
}: SingleEventItemProps) => {
  const ticketQR = useQR(JSON.stringify({ uuid }));

  const descriptions: Description[] = [
    { value: eventItem.eventName, modifier: "title" },
    { value: ticketTypeName, modifier: "sub-header" },
    {
      fieldName: "Ticket Date",
      value: formatSingleDate(eventItem.eventDate),
      modifier: "detail",
    },
    {
      fieldName: "Ticket Time",
      value: formatSingleDate(eventItem.eventDate, "h:MM TT"),
      modifier: "detail",
    },
  ];

  return <SliderCard qr={ticketQR} descriptions={descriptions} />;
};

type EventItemsProps = {
  eventItem: EventItem | any;
  order: OrderPayload;
  backLinkOverride?: string;
};
export const EventItems: React.FC<EventItemsProps> = ({
  eventItem,
  order,
  backLinkOverride,
}: EventItemsProps) => (
  <div className="wrapper" id="event-items">
    <HeroBanner header={`Order # ${order.orderNumber}`} src={backgroundSrc} />

    <BackLink
      text={"Return to all orders"}
      backLinkOverride={backLinkOverride}
    />

    <Slider>
      {eventItem.items.flatMap(({ tickets, ticketTypeName, itemTypeName }) =>
        tickets.map((ticket) => (
          <SingleEventItem
            key={ticket.uuid}
            uuid={ticket.uuid}
            eventItem={eventItem}
            ticketTypeName={ticketTypeName || itemTypeName}
          />
        ))
      )}
    </Slider>
  </div>
);
