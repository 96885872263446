import { useCallback, useContext, useEffect, useState } from "react";

import { AuthErrorContext } from "../contexts/authErrorContext";
import qrCode from "qrcode";

const DEFAULT_WIDTH = 400;

/**
 * Custom hook to turn attribute into QR Data URI.
 * @param {string} qrContent - content to be converted to URI.
 * @returns {string} - Data URI containing a representation of the QR Code image
 */
export const useQR = (qrContent: string) => {
  // Create QR code on mount.
  const [qr, setQR] = useState(null as string);

  useEffect(() => {
    const getQR = async () => {
      const qr = qrContent
        ? await qrCode.toDataURL(qrContent, {
            width: DEFAULT_WIDTH,
            margin: 0,
            color: {
              dark: "#282828",
              light: "#fff",
            },
          } as qrCode.QRCodeToDataURLOptions)
        : null;
      setQR(qr);
    };
    getQR();
  }, [qrContent]);

  return qr;
};

/**
 * Custom hook for locking scrolling mechanism.
 */
export const useScrollLock: () => [
  { isScrollLocked: boolean; scrollLockHeight: number },
  (isLock: boolean) => void
] = () => {
  const [isScrollLocked, setIsScrollLocked] = useState(false);
  const [scrollLockHeight, setScrollLockHeight] = useState(0);

  const lockScroll = useCallback(
    (isLock: boolean) => {
      if (isLock) {
        if (!isScrollLocked) {
          setIsScrollLocked(true);
          setScrollLockHeight(window.scrollY);

          window.document.documentElement.style.maxHeight = "100%";
          window.document.documentElement.style.overflow = "hidden";
        }
      } else {
        if (isScrollLocked) {
          setIsScrollLocked(false);

          // Scroll to stored scrollLockHeight.
          window.scrollTo(0, scrollLockHeight);
          setScrollLockHeight(0);

          window.document.documentElement.style.maxHeight = null;
          window.document.documentElement.style.overflow = null;
        }
      }
    },
    [isScrollLocked, scrollLockHeight]
  );

  return [{ isScrollLocked, scrollLockHeight }, lockScroll];
};

/**
 * Hook to scroll to top of page on cDM.
 */
export const useScrollTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};

/**
 * Wrap HTTP requests that require auth in function that modifies auth context.
 * @param {<T>} fn - function to be wrapped in method that modifies AuthErrorContext.
 * @returns {<T>} function with identical shape to argument.
 */
export function useAuthRequest<T extends (...args) => ReturnType<T>>(fn: T): T {
  const { setIsAuthError } = useContext(AuthErrorContext);

  // Wrap function in try catch.
  const httpWrapper = async (...args) => {
    try {
      const res = await fn(...args);
      return res;
    } catch (e) {
      console.error(e);

      // Only handle 403 errors, this allows us to handle > 500 error codes individually.
      if (setIsAuthError && e.status && e.status === 403) {
        setIsAuthError(true);
      }
    }
  };

  return useCallback(httpWrapper as T, []);
}
