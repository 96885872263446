// Application process.env
const config = {
  env: process.env.NODE_ENV.toLocaleLowerCase(),

  // acme config
  acmeApiKeyCors: process.env.REACT_APP_ACME_API_KEY_CORS,
  acmeTenantId: process.env.REACT_APP_ACME_TENANT_ID,
  acmeApiUrl: process.env.REACT_APP_ACME_API_URL,
  acmeRecaptchaKey: process.env.REACT_APP_ACME_RECAPTCHA_KEY,

  // guest passes
  guestPassUrl: process.env.REACT_APP_GUEST_PASS_URL,

  // firebase and service worker config
  publicUrl: process.env.PUBLIC_URL,

  // SMS marketing campaign opt-in
  smsOptInUrl: process.env.REACT_APP_SMS_OPT_IN_URL,

  // Worldpay/Express/triPOS config
  triPosDevKey: process.env.REACT_APP_TRIPOS_DEV_KEY,
  triPosDevSecret: process.env.REACT_APP_TRIPOS_DEV_SECRET,
  expressAppId: process.env.REACT_APP_EXPRESS_APPLICATION_ID,
};

export default config;
