import React, { useEffect, useState } from "react";
import classnames from "classnames";

type CardImageProps = {
  header?: string;
  src?: string;
  alt?: string;
  className?: string;
  imageOffset?: number;
  isHorizontalCard?: boolean;
  link?: string;
  internalLink?: boolean;
};

export const CardImage: React.FC<CardImageProps> = ({
  header,
  src,
  alt,
  className,
  isHorizontalCard = false,
  link,
  internalLink,
}: CardImageProps) => {
  const [isLoaded, setIsLoaded] = useState(!src);
  const [isError, setIsError] = useState(!src);

  // If SRC changes, reset state.
  useEffect(() => {
    if (src) {
      setIsLoaded(false);
      setIsError(false);
    }
  }, [src]);

  return (
    // <SpinnerSuspense className="spinner" isLoaded={isLoaded}>
    <div
      className={classnames(className, {
        "horizontal-card__image": isHorizontalCard,
        card__image: !isHorizontalCard,
        "card__image--no-image": isError,
      })}
    >
      {src && (
        <>
          {link ? (
            <a
              href={link}
              target={!internalLink && "_blank"}
              rel="noopener noreferrer"
            >
              <img
                className={classnames({
                  "horizontal-card__media": isHorizontalCard,
                  card__media: !isHorizontalCard,
                })}
                src={src}
                alt={alt || header}
                style={{
                  opacity: isLoaded && !isError ? 1 : 0,
                }}
                // Wrap in STO to insure load animation fires.
                onLoad={() => setTimeout(() => setIsLoaded(true), 0)}
                onError={() => {
                  setIsLoaded(true);
                  setIsError(true);
                }}
              />
            </a>
          ) : (
            <img
              className={classnames({
                "horizontal-card__media": isHorizontalCard,
                card__media: !isHorizontalCard,
              })}
              src={src}
              alt={alt || header}
              style={{
                opacity: isLoaded && !isError ? 1 : 0,
              }}
              // Wrap in STO to insure load animation fires.
              onLoad={() => setTimeout(() => setIsLoaded(true), 0)}
              onError={() => {
                setIsLoaded(true);
                setIsError(true);
              }}
            />
          )}
        </>
      )}

      <div
        className={classnames("image-overlay card__media", {
          "image-overlay--loaded": isLoaded,
        })}
      ></div>
    </div>
    // </SpinnerSuspense>
  );
};
