import React from "react";
import { formatDollarAmount } from "../../../../util/formatting";
import { validateDollarAmount } from "../../../../util/validation";

type DonationOptionsProps = {
  donationAmount: string;
  setDonationAmount: (donationAmount: string) => void;
  setInvalidDonation: (invalidDonation: string) => void;
  setButtonDisabled: (buttonDisabled: boolean) => void;
  otherAmount: string;
  setOtherAmount: (otherAmount: string) => void;
};

export const DonationOptions: React.FC<DonationOptionsProps> = ({
  donationAmount,
  setDonationAmount,
  setInvalidDonation,
  setButtonDisabled,
  otherAmount,
  setOtherAmount,
}: DonationOptionsProps) => {
  const handleDonationAmount = (e) => {
    setButtonDisabled(false);
    setInvalidDonation("");
    const target = e.target;
    setOtherAmount("");
    setDonationAmount(formatDollarAmount(target.value));
  };

  const handleOtherAmount = (e) => {
    const target = e.target;
    setOtherAmount(target.value);
    setDonationAmount(formatDollarAmount(target.value));
    if (validateDollarAmount(target.value)) {
      setButtonDisabled(false);
      setInvalidDonation("");
    }
  };

  const handleOtherRadio = () => {
    setButtonDisabled(true);
    setOtherAmount("");
    setDonationAmount(formatDollarAmount("0"));
    document.getElementById("other-amount").focus();
  };

  const onBlur = () => {
    if (validateDollarAmount(donationAmount)) {
      setButtonDisabled(false);
      setInvalidDonation("");
    } else if (Number(donationAmount) === 0) {
      setInvalidDonation("");
    } else {
      setButtonDisabled(true);
      setInvalidDonation(
        "Please enter a valid dollar amount. Example: $2,000."
      );
    }
  };

  return (
    <form className="donation-options">
      <div className="radio-checkbox">
        <input
          type="radio"
          name="donation-option"
          id="donation-option-1"
          value="5.00"
          onChange={handleDonationAmount}
          checked={donationAmount === "5.00"}
        />
        <label className="donation-option-wrapper" htmlFor="donation-option-1">
          $5
        </label>
      </div>

      <div className="radio-checkbox">
        <input
          type="radio"
          name="donation-option"
          id="donation-option-2"
          value="10.00"
          onChange={handleDonationAmount}
          checked={donationAmount === "10.00"}
        />
        <label className="donation-option-wrapper" htmlFor="donation-option-2">
          $10
        </label>
      </div>

      <div className="radio-checkbox">
        <input
          type="radio"
          name="donation-option"
          id="donation-option-3"
          value="25.00"
          onChange={handleDonationAmount}
          checked={donationAmount === "25.00"}
        />
        <label className="donation-option-wrapper" htmlFor="donation-option-3">
          $25
        </label>
      </div>

      <div className="radio-checkbox">
        <input
          type="radio"
          name="donation-option"
          id="donation-option-4"
          value="50.00"
          onChange={handleDonationAmount}
          checked={donationAmount === "50.00"}
        />{" "}
        <label className="donation-option-wrapper" htmlFor="donation-option-4">
          $50
        </label>
      </div>

      <div className="radio-checkbox">
        <input
          type="radio"
          name="donation-option"
          id="donation-option-5"
          value="100.00"
          onChange={handleDonationAmount}
          checked={donationAmount === "100.00"}
        />
        <label className="donation-option-wrapper" htmlFor="donation-option-5">
          $100
        </label>
      </div>

      <div className="radio-checkbox">
        <input
          type="radio"
          name="donation-option"
          id="donation-option-6"
          value="250.00"
          onChange={handleDonationAmount}
          checked={donationAmount === "250.00"}
        />
        <label className="donation-option-wrapper" htmlFor="donation-option-6">
          $250
        </label>
      </div>

      <div className="radio-checkbox">
        <input
          type="radio"
          name="donation-option"
          id="donation-option-7"
          value="other"
          onChange={handleOtherRadio}
        />
        <label className="donation-option-wrapper" htmlFor="donation-option-7">
          Other
        </label>
      </div>
      <input
        type="text"
        id="other-amount"
        placeholder="$500"
        className="form__input"
        onChange={handleOtherAmount}
        onBlur={onBlur}
        value={otherAmount}
      />
    </form>
  );
};
