import React, { useState, useEffect } from "react";
import { Toggle } from "../../shared/toggle";
import {
  PurchaseItem,
  MembershipLevelIdentifier,
} from "../../../../common/payloads";
import { getUpsellContent } from "../../../services/graphCmsService";
import {
  UpsellMembershipTypes,
  generateMembershipPitch,
  isUpsellCheckout,
} from "../helpers/upsellPriceCalculator";

type EventDetailMembershipUpsellProps = {
  purchaseItems: { [key: string]: PurchaseItem };
  totalPrice: number;
  setUpsell:
    | React.Dispatch<React.SetStateAction<MembershipLevelIdentifier>>
    | ((upsell: MembershipLevelIdentifier) => void);
  membershipLevels: MembershipLevelIdentifier[];
  isKiosk: boolean;
};

export const EventDetailMembershipUpsell: React.FC<EventDetailMembershipUpsellProps> =
  ({
    purchaseItems,
    totalPrice,
    setUpsell,
    membershipLevels,
    isKiosk,
  }: EventDetailMembershipUpsellProps) => {
    // Benefits list map.
    const [memberBenefitsMap, setMemberBenefitsMap] = useState<
      { [key in UpsellMembershipTypes]: string[] }
    >({
      Patron: [],
      Contributor: [],
      Supporter: [],
    });
    const [isSelected, setIsSelected] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    // Get the membership upsell content from CMS
    useEffect(() => {
      const fetchMembershipUpsellContent = async () => {
        const contentBlocks = await getUpsellContent();

        const all = contentBlocks.find(
          (content) => content.membershipLevel === "All"
        );

        const dupeMap = { ...memberBenefitsMap };

        Object.keys(dupeMap).forEach((memberType) => {
          const memberContent = contentBlocks.find(
            (content) => content.membershipLevel === memberType
          );
          dupeMap[memberType] = [...memberContent.content, ...all.content];
        });

        setMemberBenefitsMap(dupeMap);
      };

      fetchMembershipUpsellContent();
    }, []);

    // If purchaseItems change, reset checkmark state and parent state.
    useEffect(() => {
      setIsSelected(false);
      setUpsell(null);
      setActiveIndex(0);
    }, [purchaseItems, setUpsell]);

    // See if this is an upsell, and if so map to our object from server.
    const upsell = isUpsellCheckout(purchaseItems as any);
    const activeMembershipLevels =
      upsell &&
      upsell.map((upsellLevel) =>
        membershipLevels.find(({ info: { name } }) => name === upsellLevel)
      );

    // Hide toggle if there is only one activeMembershipLevel to select from.
    let toggleWrapperClassName = "value-proposition__toggle-wrapper";
    if (activeMembershipLevels && activeMembershipLevels.length > 1) {
      toggleWrapperClassName = `${toggleWrapperClassName} value-proposition__toggle-wrapper--active`;
    }

    let contentWrapperClassName = "value-proposition__content-wrapper";
    let overlayClassName = "value-proposition__overlay";
    let buttonClassName =
      "value-proposition__button value-proposition__button--upsell";
    if (isSelected) {
      contentWrapperClassName = `${contentWrapperClassName} value-proposition__content-wrapper--active`;
      overlayClassName = `${overlayClassName} value-proposition__overlay--active`;
      buttonClassName = `${buttonClassName} value-proposition__button--active`;
    }

    return (
      <div
        className="check-in__value-proposition value-proposition-wrapper"
        style={{
          maxHeight: upsell ? 1000 : 0,
          opacity: upsell ? 1 : 0,
        }}
      >
        {/** Confetti 🎊 */}
        <div className="value-proposition__glitter-wrapper">
          {[...Array(30)].map((x, i) => (
            <div
              key={`glitter${i}`}
              className={`value-proposition__glitter--${i}`}
            />
          ))}
        </div>

        <div className="value-proposition value-proposition--clear">
          <div className={contentWrapperClassName}>
            <div className="value-proposition__content">
              <h1 className="value-proposition__header">
                Upgrade to membership today!
              </h1>

              <div className="value-proposition__clearfix">
                <div className={toggleWrapperClassName}>
                  For Two
                  <Toggle
                    onClick={() => {
                      if (
                        activeMembershipLevels &&
                        activeMembershipLevels.length
                      ) {
                        setActiveIndex(
                          (activeIndex) =>
                            (activeIndex + 1) % activeMembershipLevels.length
                        );
                        setUpsell(null);
                        setIsSelected(false);
                      }
                    }}
                    isOn={activeIndex === 0}
                  />
                  For One
                </div>
              </div>

              {upsell && upsell.length && (
                <div className="value-proposition__pitch">
                  <p>
                    {Boolean(activeMembershipLevels[activeIndex]) &&
                      generateMembershipPitch(
                        activeMembershipLevels[activeIndex]
                      )(totalPrice, purchaseItems)}
                  </p>
                  <ul>
                    {Boolean(
                      activeMembershipLevels[activeIndex] &&
                        memberBenefitsMap[
                          activeMembershipLevels[activeIndex].info.name
                        ]
                    ) &&
                      memberBenefitsMap[
                        activeMembershipLevels[activeIndex].info.name
                      ].map((benefit, i) => <li key={i}>{benefit}</li>)}
                  </ul>
                  {!isKiosk && (
                    <p>
                      Your membership supports our conservation efforts and
                      education initiatives.
                    </p>
                  )}
                </div>
              )}
            </div>

            <div className="value-proposition__thanks">
              <h1 className="value-proposition__header value-proposition__header--thanks">
                Thank You!
              </h1>
              <p className="value-proposition__pitch value-proposition__pitch--center">
                We&apos;ve added the membership to your cart. Thank you for
                supporting the Barnes! On purchase, you&apos;ll receive an email
                with your membership information.
              </p>
            </div>
          </div>

          <div className="value-proposition__button-wrapper value-proposition__button-wrapper--center">
            <button
              className={buttonClassName}
              onClick={(e) => {
                if (!isSelected && upsell) {
                  setUpsell(activeMembershipLevels[activeIndex]);
                  setIsSelected(true);
                } else {
                  setUpsell(null);
                  setIsSelected(false);
                }
              }}
            >
              {!isSelected ? "Sign me up!" : "I've changed my mind"}
            </button>
          </div>
          {!isKiosk && (
            <p className="value-proposition__disclaimer">
              <a
                href="https://www.barnesfoundation.org/support/membership"
                target="_blank"
                rel="noopener noreferrer"
              >
                See full details of our membership offerings.
              </a>
            </p>
          )}
        </div>

        <div className={overlayClassName} />
      </div>
    );
  };
