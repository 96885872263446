import { EventTimeObject } from "acme-ticketing-client";
import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import {
  MembershipLevelIdentifier,
  EventDetailPayload,
  MembershipPurchase,
  PurchaseItem,
  TriposCCAuth,
} from "../../common/payloads";
import { calcOrderTotal } from "../components/eventDetail/helpers/upsellPriceCalculator";
import {
  CreditCardInputFields,
  useCreditCardForm,
} from "../hooks/useCreditCardForm";
import { authorizeCard, deleteReservation } from "../services/kioskService";
import { getAdmissionTime } from "../services/vipService";
import { addServerLog } from "../services/loggerService";

export enum AccordionStep {
  Start = 0,
  ArrivalDate = 1,
  ArrivalTime = 2,
  TicketType = 3,
  Payment = 4,
  OrderConfirmation = 5,
}

/**
 * UseKiosk Context
 * For shared kiosk state
 */
type OrderState = {
  selectedTime: EventTimeObject;
  cartItems: (PurchaseItem | MembershipPurchase)[];
  reservationId: number;
  cartId: string;
  upsell: MembershipLevelIdentifier;
  orderTotal: number;
  /** bin, expressTransactionId, and terminalId are required fro triPOS integration */
  bin: string;
  expressTransactionId: string;
  terminalId: string;
  /** maskedCC and ccType are required for the receipt */
  maskedCC: string;
  ccType: string;
};

export const RESET = "RESET";
export const UPDATE_CART = "UPDATE_CART";
export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const UPDATE_RESERVATION = "UPDATE_RESERVATION";
export const UPDATE_TIME = "UPDATE_TIME";
export const UPDATE_UPSELL = "UPDATE_UPSELL";
type Reset = typeof RESET;
type UpdateCart = typeof UPDATE_CART;
type UpdatePayment = typeof UPDATE_PAYMENT;
type UpdateReservation = typeof UPDATE_RESERVATION;
type UpdateTime = typeof UPDATE_TIME;
type UpdateUpsell = typeof UPDATE_UPSELL;

type OrderAction =
  | { type: Reset }
  | {
      type: UpdateCart;
      payload: Pick<OrderState, "cartItems">;
    }
  | {
      type: UpdatePayment;
      payload: Pick<
        OrderState,
        "bin" | "expressTransactionId" | "terminalId" | "maskedCC" | "ccType"
      >;
    }
  | {
      type: UpdateReservation;
      payload: Pick<OrderState, "reservationId" | "cartId">;
    }
  | {
      type: UpdateTime;
      payload: Pick<OrderState, "selectedTime">;
    }
  | {
      type: UpdateUpsell;
      payload: Pick<OrderState, "upsell">;
    };

type KioskContextType = {
  times: EventTimeObject[];
  setTimes: React.Dispatch<React.SetStateAction<EventTimeObject[]>>;
  selectedDay: Date;
  setSelectedDay: React.Dispatch<React.SetStateAction<Date>>;
  showCCInstructions: boolean;
  setShowCCInstructions: React.Dispatch<React.SetStateAction<boolean>>;
  showCancelPinpadInstructions: boolean;
  setShowCancelPinpadInstructions: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  chipCapableError: boolean;
  setChipCapableError: React.Dispatch<React.SetStateAction<boolean>>;
  showTimeoutError: boolean;
  setShowTimeoutError: React.Dispatch<React.SetStateAction<boolean>>;
  showErrorModal: boolean;
  setShowErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
  accordionStep: AccordionStep;
  setAccordionStep: React.Dispatch<React.SetStateAction<AccordionStep>>;
  lane: number;
  orderState: OrderState;
  orderDispatch: React.Dispatch<OrderAction>;
};

const KioskContext = createContext<KioskContextType>({
  times: null,
  setTimes: null,
  selectedDay: null,
  setSelectedDay: null,
  showCCInstructions: null,
  setShowCCInstructions: null,
  showCancelPinpadInstructions: null,
  setShowCancelPinpadInstructions: null,
  chipCapableError: null,
  setChipCapableError: null,
  showTimeoutError: null,
  setShowTimeoutError: null,
  showErrorModal: null,
  setShowErrorModal: null,
  accordionStep: null,
  setAccordionStep: null,
  lane: null,
  orderState: null,
  orderDispatch: null,
});

export const KioskContextProvider: React.FC<{ children: any }> = ({
  children,
}: {
  children: any;
}) => {
  // Event information
  const [times, setTimes] = useState<EventTimeObject[]>();
  const [selectedDay, setSelectedDay] = useState<Date>(null);

  // Order information
  const orderInitState: OrderState = {
    selectedTime: null,
    cartItems: [],
    reservationId: null,
    cartId: null,
    bin: null,
    expressTransactionId: null,
    terminalId: null,
    upsell: null,
    orderTotal: 0,
    maskedCC: null,
    ccType: null,
  };

  const orderStateReducer = (state: OrderState, action: OrderAction) => {
    let updatedState: OrderState;

    switch (action.type) {
      case UPDATE_CART:
        const { cartItems } = action.payload;
        const orderTotal = calcOrderTotal(cartItems, state.selectedTime);
        // Reset upsell to initial state when cart items change
        updatedState = {
          ...state,
          cartItems,
          orderTotal,
        };
        break;
      case UPDATE_PAYMENT:
        const { bin, expressTransactionId, maskedCC, ccType, terminalId } =
          action.payload;
        updatedState = {
          ...state,
          bin,
          expressTransactionId,
          maskedCC,
          ccType,
          terminalId,
        };
        break;
      case UPDATE_RESERVATION:
        const { reservationId, cartId } = action.payload;
        updatedState = { ...state, reservationId, cartId };
        break;
      case UPDATE_TIME:
        const { selectedTime } = action.payload;
        // Reset to the initial state and update with new time
        updatedState = { ...orderInitState, selectedTime };
        break;
      case UPDATE_UPSELL:
        const { upsell } = action.payload;
        const upsellOrderTotal = calcOrderTotal(
          state.cartItems,
          state.selectedTime,
          upsell
        );
        updatedState = { ...state, upsell, orderTotal: upsellOrderTotal };
        break;
      case RESET:
      default:
        // Reset to the initial state
        updatedState = orderInitState;
        break;
    }

    return updatedState;
  };

  const [orderState, orderDispatch] = useReducer(
    orderStateReducer,
    orderInitState
  );

  // UI State
  // refactor into a reducer
  const [showCCInstructions, setShowCCInstructions] = useState(false);
  const [showCancelPinpadInstructions, setShowCancelPinpadInstructions] =
    useState(false);
  const [showTimeoutError, setShowTimeoutError] = useState(false);
  const [chipCapableError, setChipCapableError] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [accordionStep, setAccordionStep] = useState(AccordionStep.Start);

  // triPOS pin pad lane number
  const query = new URLSearchParams(useLocation().search);
  const lane = query.get("lane") ? parseInt(query.get("lane")) : 1;

  const providerValue: KioskContextType = {
    times,
    setTimes,
    selectedDay,
    setSelectedDay,
    showCCInstructions,
    setShowCCInstructions,
    showCancelPinpadInstructions,
    setShowCancelPinpadInstructions,
    chipCapableError,
    setChipCapableError,
    showTimeoutError,
    setShowTimeoutError,
    showErrorModal,
    setShowErrorModal,
    accordionStep,
    setAccordionStep,
    lane,
    orderState,
    orderDispatch,
  };

  return (
    <KioskContext.Provider value={providerValue}>
      {children}
    </KioskContext.Provider>
  );
};

/**
 * UseKiosk Hook
 *    For business logic associated with the kiosk
 */
type UseKioskContextType = KioskContextType & {
  handleTimeOut: () => void;
  setStepStart: () => void;
  setStepDate: () => void;
  setStepTime: () => void;
  setStepTickets: () => void;
  setStepPayment: () => void;
  setStepConfirmation: () => void;
  setPreviousStep: () => void;
  setNextStep: () => void;
  creditCardInputs: CreditCardInputFields;
  authPurchase: () => Promise<void>;
  resetKiosk: (eventDetailPayload: EventDetailPayload) => void;
  fetchKioskTimes: (eventDetailPayload: EventDetailPayload) => void;
};

export const useKiosk = (): UseKioskContextType => {
  const query = new URLSearchParams(useLocation().search);
  const kioskContext = useContext(KioskContext);
  const {
    lane,
    setShowCCInstructions,
    setShowTimeoutError,
    accordionStep,
    setAccordionStep,
    setShowErrorModal,
    times,
    setTimes,
    setSelectedDay,
    setShowCancelPinpadInstructions,
    setChipCapableError,
    orderState,
    orderDispatch,
  } = kioskContext;

  /** Handles when kiosk times out by closing all modals and turing off the card reader */
  const handleTimeOut = () => {
    // Close all modals
    setShowCCInstructions(false);
    setShowTimeoutError(false);
  };

  // Accordion step setters
  const setStepStart = () => setAccordionStep(AccordionStep.Start);
  const setStepDate = () => setAccordionStep(AccordionStep.ArrivalDate);
  const setStepTime = () => setAccordionStep(AccordionStep.ArrivalTime);
  const setStepTickets = () => setAccordionStep(AccordionStep.TicketType);
  const setStepPayment = () => setAccordionStep(AccordionStep.Payment);
  const setStepConfirmation = () =>
    setAccordionStep(AccordionStep.OrderConfirmation);
  const setPreviousStep = () => setAccordionStep(accordionStep - 1);
  const setNextStep = () => setAccordionStep(accordionStep + 1);

  // Payment and contact form fields
  const creditCardInputs = useCreditCardForm();

  /** Fetches admission event and sets selected time to nex available time slot */
  const fetchKioskTimes = async (eventDetailPayload: EventDetailPayload) => {
    // get date from query params
    const date = query.get("date");
    const step = parseInt(query.get("step"));
    let day: Date;

    // Set day as the date from query params or default to today
    if (date) {
      day = new Date(`${date}T00:00:00-04:00`);
    } else {
      day = new Date();
    }

    setSelectedDay(day);

    const fetchTimes = async () => {
      // Clear existing times, if exist.
      if (times) {
        setTimes(null);
      }

      const eventTimes = await getAdmissionTime(eventDetailPayload.id, day);

      setTimes(eventTimes);
      // Select the first time slot returned by the API since it only returns future time slots
      const selectedTime = eventTimes.find((time) => time.availableSeats > 0);
      orderDispatch({ type: UPDATE_TIME, payload: { selectedTime } });
    };

    await fetchTimes();

    // Set the step from the query params or default to ticket select
    if (step) {
      setAccordionStep(step);
    } else {
      setStepTickets();
    }
  };

  /** Authorize purchase via triPOS API */
  const authPurchase = async () => {
    setShowCCInstructions(true);

    try {
      // Authorize CC via pin pad
      const resp = await authorizeCard(lane, orderState.orderTotal.toString());
      const data = resp.data as TriposCCAuth;
      const logs = data._logs;

      if (resp.status !== 200) {
        setShowErrorModal(true);
        setChipCapableError(false);
        const { error } = resp.data as { error: any };
        console.error("Error authenticating CC with triPOS:", error, logs);
        addServerLog({
          type: "ERROR",
          message: {
            message: "ERROR AUTHENTICATING CC",
            response: resp,
            reqConfig: resp.config,
          },
        });
      } else if (data._hasErrors) {
        if (data.statusCode === "Cancelled") {
          setShowCancelPinpadInstructions(true);
          setChipCapableError(false);
        } else if (data.statusCode === "SwipedCardIsChipCapable") {
          setChipCapableError(true);
          authPurchase();
        } else {
          setChipCapableError(false);
          setShowErrorModal(true);
          const errors = data._errors;
          console.error(
            "Error authenticating cc with triPOS",
            errors,
            logs,
            data
          );
          addServerLog({
            type: "ERROR",
            message: {
              message: "ERROR AUTHENTICATING CC",
              response: resp,
              reqConfig: resp.config,
            },
          });
        }
      } else if (!data.isApproved) {
        setChipCapableError(false);
        setShowErrorModal(true);
        console.error("triPOS authentication not approved", logs, data);
        addServerLog({
          type: "ERROR",
          message: {
            message: "AUTHENTICATION NOT APPROVED",
            response: resp,
            reqConfig: resp.config,
          },
        });
      } else {
        setChipCapableError(false);
        const {
          binValue,
          accountNumber,
          cardHolderName,
          expirationMonth,
          expirationYear,
          transactionId,
          terminalId,
          cardLogo,
        } = resp.data as TriposCCAuth;

        // Set data from CC auth into local state
        const [lastName, firstName] = cardHolderName
          ? cardHolderName.split("/")
          : ["", ""];
        creditCardInputs.fields.address.firstName.setValue(firstName);
        creditCardInputs.fields.address.lastName.setValue(lastName);
        creditCardInputs.fields.cc.number.setValue(accountNumber);
        creditCardInputs.fields.cc.expDate.setValue(
          `${expirationMonth}${expirationYear}`
        );

        orderDispatch({
          type: UPDATE_PAYMENT,
          payload: {
            bin: binValue,
            expressTransactionId: transactionId,
            terminalId,
            maskedCC: accountNumber,
            ccType: cardLogo,
          },
        });
      }
    } catch (e) {
      setChipCapableError(false);
      setShowErrorModal(true);
      console.log("error authorizing the credit card", e);
    } finally {
      // Close the CC instructions overlay
      setShowCCInstructions(false);
    }
  };

  /** Handles resetting the kiosk to its initial state */
  const resetKiosk = async (eventDetailPayload: EventDetailPayload) => {
    // Go to first step
    setStepTickets();
    // delete reservation
    const d = await deleteReservation(
      orderState.reservationId,
      orderState.cartId
    );
    // clear contact and payment information
    creditCardInputs.clearPayment();
    // clear order information
    orderDispatch({ type: RESET });
    // Close all modals
    setShowCCInstructions(false);
    setShowErrorModal(false);
    setShowTimeoutError(false);
    setShowCancelPinpadInstructions(false);
    // Select next available time slot
    await fetchKioskTimes(eventDetailPayload);
  };

  return {
    ...kioskContext,
    handleTimeOut,
    setStepStart,
    setStepDate,
    setStepTime,
    setStepTickets,
    setStepPayment,
    setStepConfirmation,
    setPreviousStep,
    setNextStep,
    creditCardInputs,
    authPurchase,
    resetKiosk,
    fetchKioskTimes,
  };
};
