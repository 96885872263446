import classnames from "classnames";
import React from "react";
import {
  MembershipLevelIdentifier,
  PurchaseItem,
} from "../../../../common/payloads";
import { TicketCard } from "../../shared/ticketCard";
import {
  upsellPriceCalculator,
  UpsellTicketItems,
} from "../helpers/upsellPriceCalculator";
import { AccordionStep, useKiosk } from "../../../contexts/kioskContext";

type UpsellTicketItemsValue = UpsellTicketItems[keyof UpsellTicketItems];

type EventDetailProps = {
  purchaseItems: { [key: string]: PurchaseItem };
  upsellInfo: MembershipLevelIdentifier["info"] | void;
  promoItem: PurchaseItem | null;
  isKioskCheckout: boolean;
};
export const EventDetailConversion: React.FC<EventDetailProps> = ({
  purchaseItems,
  upsellInfo,
  promoItem,
  isKioskCheckout,
}: EventDetailProps) => {
  const { price, ...upsellTicketItems } = upsellInfo
    ? upsellPriceCalculator(purchaseItems, upsellInfo.name)
    : { price: undefined };
  const { accordionStep } = useKiosk();

  let eventDetailClassName = "event-detail-conversion";
  if (upsellInfo) {
    eventDetailClassName = `${eventDetailClassName} ${eventDetailClassName}--active`;
  }

  // Add the promotion item to the conversion cards
  if (promoItem) {
    const { quantity, unitPrice, inventoryName } = promoItem;

    // Add it to the pre-conversion card
    purchaseItems = {
      ...purchaseItems,
      [inventoryName]: {
        quantity,
        unitPrice,
        ignoreEntitlements: false,
      },
    };

    // Add it to the upsell card
    upsellTicketItems[promoItem.inventoryName] = {
      quantity,
      unitPrice,
    };
  }

  return (
    <div
      className={classnames(eventDetailClassName, {
        kiosk: isKioskCheckout,
      })}
    >
      {upsellInfo && (
        <span
          className={classnames("event-detail-conversion__explanation", {
            kiosk: isKioskCheckout,
            "kiosk--active": accordionStep === AccordionStep.TicketType,
          })}
        >
          Your cart will be converted according to {upsellInfo.name}-level
          membership prices.
        </span>
      )}

      {/** Pre-conversion */}
      <EventDetailConversionCard ticketItems={purchaseItems} />

      <div className="event-detail-conversion__arrow-section">
        {[...Array(4)].map((x, i) => (
          <div key={i}></div>
        ))}
      </div>

      {/** Post-conversion */}
      {upsellInfo && upsellTicketItems && (
        <EventDetailConversionCard
          ticketItems={{
            [upsellInfo.name]: {
              quantity: 1,
              unitPrice: upsellInfo.price,
            },
            ...upsellTicketItems,
          }}
        />
      )}
    </div>
  );
};

type EventDetailConversionCardProps = {
  ticketItems: { [key: string]: UpsellTicketItemsValue | PurchaseItem };
};
const EventDetailConversionCard: React.FC<EventDetailConversionCardProps> = ({
  ticketItems,
}: EventDetailConversionCardProps) => (
  <TicketCard
    isConversion={true}
    ticketItems={Object.entries(ticketItems)
      .filter(([, { quantity }]) => quantity)
      .map(([displayName, { unitPrice, quantity }]) => ({
        displayName: `x $${unitPrice || "0.00"} — ${displayName}`,
        quantity,
      }))}
  />
);
