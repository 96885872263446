import { createContext } from "react";

type PushNotificationContextType = {
  willShowValueProposition: boolean;
  promptForPushNotifications?: () => Promise<void>;
  hidePushNotifications?: () => void;
};
export const PushNotificationContext =
  createContext<PushNotificationContextType>({
    willShowValueProposition: false,
    promptForPushNotifications: null,
    hidePushNotifications: null,
  });
