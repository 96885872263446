import { PurchaseItem } from "../../../../common/payloads";
import { upsellPriceCalculator } from "./upsellPriceCalculator";

export type EventCategoryType = "Admissions" | "Art Courses" | "First Fridays";

/** Helper function that creates purchase item object for Community Pass Members
 * Ensuring that the order has 4 discounted (free) tickets, and any additional
 * tickets are at standard price, while ensuring that the tickets that have the
 * standard price applied is the ticket with the lowest price.
 *
 * Each ticket type has two objects associated with it, the base ticket obj (discount) and
 * the Standard ticket obj (standard price). The base ticket obj quantity equals the TOTAL
 * number of tickets for this ticket type and the Standard ticket obj quantity equals
 * how many of the tickets will be at the standard price point.
 * @param {{ [key: string]: PurchaseItem }} purchaseItems - List of ticket items available for purchase. Standard price tickets have "_Standard" appended to the name
 * @param {number} ticketLimit - Optional limit for number of discounted tickets
 * @param {EventCategoryType} eventCategory - Type of event that will determine which tickets should be filtered
 * @param {number} quantity - Number of tickets requested for this ticket type
 * @param {number} totalQuantity - Total number of tickets in the order before new tickets are added
 * @param {string} name - Ticket type name that is being updated
 * @returns {{ [key: string]: PurchaseItem }}
 */
export const createItemsObject = (
  purchaseItems: {
    [key: string]: PurchaseItem;
  },
  ticketLimit: number,
  eventCategory: EventCategoryType
): { [key: string]: PurchaseItem } => {
  // get current quantity of this ticket type
  // const currentQuantity = purchaseItems[name].quantity;
  // const diff = quantity - currentQuantity;

  // Calculate the total number of tickets in the order
  const orderTotal = getTicketTotal(purchaseItems);
  // Determine new number of total standard tickets for this order
  const standardTotal = orderTotal - ticketLimit;
  // Remove all standard ticket quantities
  const purchaseItemsNoStandard = removeStandardTickets(purchaseItems);

  // Assign standard tickets based on ticket price
  return assignStandardTickets(
    purchaseItemsNoStandard,
    standardTotal,
    eventCategory
  );
};

/** Helper fn to remove quantities from standard tickets
 * @param {{ [key: string]: PurchaseItem }} purchaseItems - List of ticket items available for purchase. Standard price tickets have "_Standard" appended to the name
 * @returns {{ [key: string]: PurchaseItem }} - List of ticket items with standard quantities set to 0
 */
export const removeStandardTickets = (purchaseItems: {
  [key: string]: PurchaseItem;
}): { [key: string]: PurchaseItem } => {
  const updatedPurchaseItems: { [key: string]: PurchaseItem } = {};

  for (const [type, item] of Object.entries(purchaseItems)) {
    if (type.includes("Standard")) {
      updatedPurchaseItems[type] = { ...item, quantity: 0 };
    } else {
      updatedPurchaseItems[type] = item;
    }
  }

  return updatedPurchaseItems;
};

/** Helper fn that assigns the standard tickets based on ticket price
 * @param {{ [key: string]: PurchaseItem }} purchaseItems - List of ticket items available for purchase. Standard price tickets have "_Standard" appended to the name
 * @param {number} standardTotal - Total quantity of standard tickets in the order
 * @param {EventCategoryType} eventCategory - Type of event that will determine which tickets should be filtered
 * @returns {{ [key: string]: PurchaseItem }} - List of purchase items with standard quantities assigned
 */
export const assignStandardTickets = (
  purchaseItems: { [key: string]: PurchaseItem },
  standardTotal: number,
  eventCategory: EventCategoryType
): { [key: string]: PurchaseItem } => {
  const checkOrderMap = {
    Admissions: ["Child", "Youth", "Senior", "Adult"],
    "First Fridays": ["Program Ticket"],
  }; // Sorted by price ascending

  const updatedItems = { ...purchaseItems };
  let ticketsToAssign = standardTotal;

  if (eventCategory !== "Art Courses") {
    let checkOrder = checkOrderMap["Admissions"];

    if (eventCategory === "First Fridays") {
      checkOrder = checkOrderMap[eventCategory];
    }

    // Check each ticket type for quantity and add tickets to Standard ticket obj
    checkOrder.forEach((ticketType) => {
      const standardName = formatStandardName(ticketType);
      if (updatedItems[ticketType]) {
        if (updatedItems[ticketType].quantity > 0) {
          // Add tickets one at a time while standard quantity is less than Child total
          while (ticketsToAssign > 0) {
            // Check to see we can assign all remaining tickets
            if (ticketsToAssign <= updatedItems[ticketType].quantity) {
              updatedItems[standardName] = {
                ...updatedItems[standardName],
                quantity: ticketsToAssign,
              };
              ticketsToAssign = 0;
              break;
            } else {
              // Otherwise assign the total quantity of that ticket type to standard
              const quantity = updatedItems[ticketType].quantity;
              updatedItems[standardName] = {
                ...updatedItems[standardName],
                quantity,
              };
              // Recalculate tickets to assign
              ticketsToAssign = ticketsToAssign - quantity;
              break;
            }
          }
        }
      }
    });
  } else {
    // For courses, only allow one discounted ticket per ticket type
    Object.keys(purchaseItems).forEach((ticketType) => {
      const standardName = formatStandardName(ticketType);

      if (updatedItems[ticketType]) {
        if (updatedItems[ticketType].quantity > 0) {
          while (ticketsToAssign > 0) {
            // Check to see we can assign all remaining tickets
            if (ticketsToAssign < updatedItems[ticketType].quantity) {
              updatedItems[standardName] = {
                ...updatedItems[standardName],
                quantity: ticketsToAssign,
              };
              ticketsToAssign = 0;
              break;
            } else {
              // Otherwise assign total quantity - 1 of that ticket type to standard
              const quantity = updatedItems[ticketType].quantity - 1;
              updatedItems[standardName] = {
                ...updatedItems[standardName],
                quantity,
              };
              // Recalculate tickets to assign
              ticketsToAssign = ticketsToAssign - quantity;
              break;
            }
          }
        }
      }
    });
  }

  return updatedItems;
};

/** Filters and formats purchase item object for payload to ACME API
 * @param {{ [key: string]: PurchaseItem }} purchaseItems - List of ticket items
 * @param {boolean} ticketLimit - Whether or not ticket limit applies to this order
 * @returns {{ items: PurchaseItem[] }} - Formatted and filtered list of ticket items
 */
export const filterPurchaseItems = (
  purchaseItems: {
    [key: string]: PurchaseItem;
  },
  ticketLimit?: boolean
): { items: PurchaseItem[] } => {
  let items = [];

  if (ticketLimit) {
    for (const [name, item] of Object.entries(purchaseItems)) {
      const standardName = formatStandardName(name);

      if (item.quantity && !name.includes("_Standard")) {
        // Add basic item obj, subtracting the number of standard price tickets from the total
        const standardQuantity = purchaseItems[standardName].quantity;
        const quantity = item.quantity - standardQuantity;

        if (quantity) {
          items.push({
            ...item,
            quantity,
          });
        }

        // Add standard item obj
        if (standardQuantity) {
          const standard = purchaseItems[standardName];
          const standardPrice = {
            ...standard,
            retailUnitPrice: standard.unitPrice,
            amount: (parseFloat(standard.unitPrice) * standard.quantity)
              .toFixed(2)
              .toString(),
            isRetail: true,
          };

          items.push(standardPrice);
        }
      }
    }
  } else {
    items = Object.values(purchaseItems).filter(
      ({ quantity }) => quantity
    ) as PurchaseItem[];
  }
  return { items };
};

/**
 * @param {{ [key: string]: PurchaseItem }} purchaseItems - List of ticket items
 * @param {string} name - Name of ticket type
 * @returns {number} - Total quantity of discounted tickets
 */
export const getDiscountQuantity = (
  purchaseItems: { [key: string]: PurchaseItem },
  name: string
): number => {
  const totalQuantity = purchaseItems[name] ? purchaseItems[name].quantity : 0;
  const standardQuantity = purchaseItems[formatStandardName(name)]
    ? purchaseItems[formatStandardName(name)].quantity
    : 0;

  return totalQuantity - standardQuantity;
};

/**
 * @param {{ [key: string]: PurchaseItem }} purchaseItems - List of ticket items
 * @param {string} name - Name of ticket type
 * @returns {number} - Total quantity of discounted tickets
 */
export const getStandardQuantity = (
  purchaseItems: { [key: string]: PurchaseItem },
  name: string
): number => {
  return purchaseItems[formatStandardName(name)]
    ? purchaseItems[formatStandardName(name)].quantity
    : 0;
};

/**
 * @param {string} name - String to be interpolated
 * @returns {string}
 */
const formatStandardName = (name: string): string => `${name}_Standard`;

/**
 *
 */
export const getTicketTotal = (purchaseItems: {
  [key: string]: PurchaseItem;
}): number => {
  let total = 0;
  for (const [type, item] of Object.entries(purchaseItems)) {
    if (!type.includes("Standard")) {
      total = item.quantity + total;
    }
  }
  return total;
};

/** Filters and formats purchase item object for member upsell via kiosk checkout payload to ACME API
 * @param {{ [key: string]: PurchaseItem }} purchaseItems - current shopping cart.
 * @param {string} membershipLevel - level of upsell.
 * @returns {{ items: PurchaseItem[] }} - Formatted and filtered list of ticket items
 */
export const filterKioskUpsellPurchaseItems = (
  purchaseItems: { [key: string]: PurchaseItem },
  membershipLevel: string
): { items: PurchaseItem[] } => {
  const upsellItems = upsellPriceCalculator(purchaseItems, membershipLevel);

  // overwrite the unitPrice in purchaseItems with the unitPrice from upsellItems
  const kioskPurchaseItems: { [key: string]: PurchaseItem } = {};

  for (const [ticketType, details] of Object.entries(purchaseItems)) {
    kioskPurchaseItems[ticketType] = {
      ...details,
      unitPrice: upsellItems[ticketType]
        ? upsellItems[ticketType].unitPrice
        : details.unitPrice,
    };
  }

  // and then do the formatting thing
  return filterPurchaseItems(kioskPurchaseItems, false);
};
