import React from "react";

type SpinnerProps = { className?: string };
export const Spinner: React.FC<SpinnerProps> = ({
  className,
}: SpinnerProps) => (
  <div className={`spinner ${className || ""}`}>
    <div className="bounce1"></div>
    <div className="bounce2"></div>
    <div className="bounce3"></div>
  </div>
);

type SpinnerSuspenseProps = {
  isLoaded: boolean;
  className?: string;
  children: any;
};
export const SpinnerSuspense: React.FC<SpinnerSuspenseProps> = ({
  children,
  isLoaded,
  className,
}: SpinnerSuspenseProps) => {
  return <>{isLoaded ? children : <Spinner className={className} />}</>;
};
