import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import classnames from "classnames";
import { useHistory } from "react-router-dom";

import { AuthContext } from "../../contexts/authContext";
import { AuthErrorContext } from "../../contexts/authErrorContext";
import AuthenticateService from "../../services/authenticationService";
import { ReactComponent as BarnesLogo } from "../shared/icons/barnesLogo.svg";
import { Spinner } from "../shared/spinner";
import { useQueryParams } from "../../hooks/useQueryParams";
import { generateImgixUrl } from "../../util/generateImgixUrl";

const backgroundSrc = generateImgixUrl(
  "sharedBackgroundImages/BF388",
  "auto=compress&w=1500"
);

type InputError = "NoInput" | "BadFormat";

export const SplashScreen: React.FC = () => {
  const { isAuthError, setIsAuthError } = useContext(AuthErrorContext);
  const { setIsAuthenticated, setPermissions } = useContext(AuthContext);
  const history = useHistory();
  const { redirect, offerCode } = useQueryParams();

  const [showLogin, setShowLogin] = useState(false);
  const [input, setInput] = useState("");
  const [memberId, setMemberId] = useState("");
  const [inputError, setInputError] = useState<InputError | void>(undefined);
  const [submitted, setSubmitted] = useState(false);
  const [requestIsActive, setIsRequestActive] = useState(false);
  const [, setAuth] = useState(null);
  const [memberIdLogin, setMemberIdLogin] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const as = useRef(new AuthenticateService());
  const splashRef = useRef<HTMLDivElement>();

  /**
   * Copy for login link form
   */
  const link_submitButtonText = "Send login link";
  const link_loginDescription = "Alternatively,";
  const link_loginToggleText = "click here to log in with your Member ID.";
  const link_idPlaceholder = "Enter your email or phone number";

  /**
   * Copy for member id login form
   */
  const memId_submitButtonText = "Log in";
  const memId_loginDescription = "Don't have your Membership ID handy?";
  const memId_loginToggleText = "Click here to request a log in link.";
  const memId_idPlaceholder = "Enter Member Last Name";

  const [submitButtonText, setSubmitButtonText] = useState(
    link_submitButtonText
  );
  const [loginDescription, setLoginDescription] = useState(
    link_loginDescription
  );
  const [loginToggleText, setLoginToggleText] = useState(link_loginToggleText);
  const [idPlaceholder, setIdPlaceholder] = useState(link_idPlaceholder);

  /**
   * Update input value and reset input error status on input interaction.
   */
  const inputChange = (event) => {
    setInputError(undefined);
    setInput(event.target.value);
  };

  const memberIdChange = (event) => {
    setInputError(undefined);
    setMemberId(event.target.value);
    setFormValid(validate());
  };

  const toggleMemberIdLogin = (event) => {
    event.preventDefault();
    setMemberIdLogin(!memberIdLogin);
    setInput("");
    setMemberId("");
    setSubmitted(false);
    setIsAuthError(false);
  };

  const validate = useCallback(() => {
    if (memberIdLogin) {
      return !!input.length && !!memberId.length;
    } else {
      return !!input.length;
    }
  }, [memberIdLogin, input, memberId]);

  /**
   * Submit form to server.
   */
  const submitLogin = useCallback(
    async (event?) => {
      if (event) event.preventDefault();
      setSubmitted(true);
      setIsAuthError(false);

      if (!input) {
        setInputError("NoInput");
      }

      // Utilize the authenticate payload if the expired field is provided
      if (formValid && !requestIsActive) {
        setIsRequestActive(true);

        // If validating with Member ID, log user in now
        if (memberIdLogin) {
          try {
            const { userLoggedIn, membershipStanding, permissions } =
              await as.current.authenticateWithMemberId(input, memberId);

            setIsAuthenticated(userLoggedIn);
            setPermissions(permissions);
            setIsAuthError(!userLoggedIn);

            if (userLoggedIn) {
              let path = "";

              if (redirect === "/renew") {
                path = "/user/settings/renew";
                if (offerCode) {
                  path = path + `?o=${offerCode}`;
                }
              } else if (redirect) {
                path = redirect;
              } else {
                path = "/user/home";
              }

              history.push(path);
            } else {
              history.push("/");
            }
          } catch (e) {
            setIsAuthError(true);
            history.push("/");
          }
          // Otherwise, send magic link for login
        } else {
          try {
            let params: string;

            if (redirect) {
              params = `r=${redirect}`;
              if (offerCode) {
                params = params + `&o=${offerCode}`;
              }
            }

            const authenticatePayload =
              await as.current.authenticateWithIdentifier(input, true, params);

            setPermissions(authenticatePayload.permissions);

            // Utilize the authenticate payload if the expired field is provided
            if (
              authenticatePayload &&
              authenticatePayload.hasOwnProperty("expired")
            ) {
              setAuth(authenticatePayload);
            }
          } catch (e) {
            setIsAuthError(true);
          }
        }
      }
    },
    [
      input,
      setIsAuthError,
      requestIsActive,
      formValid,
      memberId,
      memberIdLogin,
      setIsAuthenticated,
      history,
      redirect,
      offerCode,
      setPermissions,
    ]
  );

  /**
   * Updates text based on type of login
   */
  useEffect(() => {
    if (memberIdLogin) {
      setSubmitButtonText(memId_submitButtonText);
      setLoginDescription(memId_loginDescription);
      setLoginToggleText(memId_loginToggleText);
      setIdPlaceholder(memId_idPlaceholder);
    } else {
      setSubmitButtonText(link_submitButtonText);
      setLoginDescription(link_loginDescription);
      setLoginToggleText(link_loginToggleText);
      setIdPlaceholder(link_idPlaceholder);
    }

    setFormValid(validate());
  }, [
    memberIdLogin,
    setSubmitButtonText,
    setLoginDescription,
    setLoginToggleText,
    setFormValid,
    validate,
    memId_submitButtonText,
    memId_loginDescription,
    memId_loginToggleText,
    memId_idPlaceholder,
    link_submitButtonText,
    link_loginDescription,
    link_loginToggleText,
    link_idPlaceholder,
  ]);

  /**
   * Reset ability to make http requests after half a second.
   * This prevents accidentally triggering two authentication requests.
   */
  useEffect(() => {
    let requestSTO: ReturnType<typeof setTimeout> | void;

    if (requestIsActive) {
      requestSTO = setTimeout(() => {
        setIsRequestActive(false);
      }, 3000);
    }

    return () => {
      if (requestSTO) {
        clearTimeout(requestSTO);
      }
    };
  }, [requestIsActive]);

  // Set up window listener for enter key.
  useEffect(() => {
    /** Listens for the enter key being pressed on main keyboard/numpad */
    const enterKeyListener = (event) => {
      if (event.code === "NumpadEnter" || event.code === "Enter") {
        submitLogin();
      }
    };

    // Registers the enter key listener on mount
    window.addEventListener("keydown", enterKeyListener);

    return () => {
      // Unregisters the enter key listener on unmount
      window.removeEventListener("keydown", enterKeyListener);
    };
  }, [input, submitLogin]);

  // Fire transition animation after 1 second for login inputs.
  useEffect(() => {
    const sto = setTimeout(() => {
      setShowLogin(true);
    }, 1000);

    return () => {
      if (sto) {
        clearTimeout(sto);
      }
    };
  }, []);

  return (
    <div className="app__content app__content--center-splash">
      <div className="splash__login-flex splash__login-flex--end">
        <img className="splash__background" src={backgroundSrc} alt="" />
        <div className="splash__overlay"></div>
        <div className="splash" ref={splashRef}>
          <BarnesLogo className="splash__logo" />
          <h1
            className="splash__header"
            style={{ display: showLogin ? "block" : "none" }}
          >
            Digital Membership
          </h1>
        </div>
      </div>
      <div className="splash__login-flex ">
        {/** Show the login prompt when true */}

        <div className="splash__login-cont login__form">
          {memberIdLogin && (
            <div className="login-form__field">
              <label
                className={classnames("login-form__label", {
                  "transform-label": isFocus,
                })}
                style={{ display: showLogin ? "block" : "none" }}
                htmlFor="member-id"
              >
                Enter your Membership ID
              </label>
              <input
                id="member-id"
                type="text"
                className="login-form__input"
                value={memberId}
                onChange={memberIdChange}
                style={{ display: showLogin ? "block" : "none" }}
                onFocus={(e) => setIsFocus(true)}
                onBlur={(e) => {
                  memberId === "" ? setIsFocus(false) : setIsFocus(true);
                }}
              />
            </div>
          )}
          <div className="login-form__field">
            <label
              className={classnames("login-form__label", {
                "transform-label": isFocus,
              })}
              style={{ display: showLogin ? "block" : "none" }}
              htmlFor="input"
            >
              {idPlaceholder}
            </label>
            <input
              id="input"
              type="text"
              className="login-form__input"
              value={input}
              onChange={inputChange}
              style={{ display: showLogin ? "block" : "none" }}
              onFocus={(e) => setIsFocus(true)}
              onBlur={(e) => {
                input === "" ? setIsFocus(false) : setIsFocus(true);
              }}
            />
          </div>
          {inputError && (
            <p className="login__text">Please enter an email or phone number</p>
          )}
          <button
            type="submit"
            className={classnames("btn-barnes", {
              "btn-barnes--disabled": !formValid,
            })}
            onClick={!requestIsActive ? submitLogin : null}
            style={{ display: showLogin ? "block" : "none" }}
            disabled={!formValid}
          >
            {requestIsActive ? (
              <Spinner className="splash__spinner" />
            ) : (
              submitButtonText
            )}
          </button>

          <p
            className="login__text"
            style={{ display: showLogin ? "block" : "none" }}
          >
            {loginDescription}{" "}
            <span className="login__link" onClick={toggleMemberIdLogin}>
              {loginToggleText}
            </span>
          </p>

          {/** Display text upon submission */}
          {submitted && !inputError && !memberIdLogin && (
            <p className="login__message">
              Check your email or phone for a new message with information to
              log into your account.
            </p>
          )}

          {isAuthError && !submitted && (
            <p
              className="login__message"
              style={{ display: showLogin ? "block" : "none" }}
            >
              Your session has expired, please log in again.
            </p>
          )}

          {isAuthError && memberIdLogin && (
            <p
              className="login__message"
              style={{ display: showLogin ? "block" : "none" }}
            >
              There was an error logging in, please try again.
            </p>
          )}

          {
            <a
              className="login__link"
              href="/vip"
              style={{ display: showLogin ? "block" : "none" }}
            >
              Not a member? Looking to purchase tickets?
            </a>
          }
        </div>
      </div>
    </div>
  );
};
