import { ReactComponent as Arrow } from "../icons/arrow.svg";
import React from "react";

type RenewalItemProps = {
  title: string;
  isActive?: boolean;
  isDeactivated?: boolean;
  onClick?: () => void;
  selectionText?: string;
  className?: string;
  id?: string;
  headerStyle?: React.CSSProperties;
  children: any;
};

export const RenewalItem: React.FC<RenewalItemProps> = ({
  title,
  isActive = true,
  isDeactivated,
  onClick,
  selectionText,
  className,
  id,
  children,
  headerStyle,
}: RenewalItemProps) => {
  let arrowClassName = "renewal__header-arrow";
  let childrenClassName = "renewal__item";
  let headerClassName = "renewal__header";

  if (isActive) {
    arrowClassName = `${arrowClassName} renewal__header-arrow--active`;
    childrenClassName = `${childrenClassName} renewal__item--active`;
  }

  if (isDeactivated) {
    headerClassName = `${headerClassName} renewal__header--deactivated`;
    childrenClassName = `${childrenClassName} renewal__item--deactivated`;
  }

  if (className) {
    childrenClassName = `${childrenClassName} ${className}`;
  }

  return (
    <div className="event-detail" id={id}>
      <h1
        className={headerClassName}
        role="button"
        onClick={!isDeactivated && onClick ? onClick : null}
        style={headerStyle || {}}
      >
        {/* {selectionText || title} */}
        {title}
        <span className="renewal__selection-text">{selectionText}</span>
        <Arrow className={arrowClassName} />
      </h1>

      <div className={childrenClassName}>{children}</div>
    </div>
  );
};
