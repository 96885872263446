import React, { Fragment } from "react";

type CheckboxProps = {
  id: string;
  value: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  label?: string;
};

// Component for a basic checkbox
export const Checkbox = ({
  id,
  value,
  onChange,
  checked,
  label,
}: CheckboxProps) => {
  return (
    <Fragment>
      <div className="checkbox-container">
        <div className="input-form__input-wrapper checkbox-input">
          <input
            aria-label={value}
            id={id}
            type="checkbox"
            checked={checked}
            onChange={(e) => {
              onChange(e);
            }}
            value={value}
          />
          {label && (
            <label className="input-form__label" htmlFor={id}>
              {label}
            </label>
          )}
        </div>
      </div>
    </Fragment>
  );
};
