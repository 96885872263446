import React, { Fragment, useRef } from "react";
import { OrderPayload } from "acme-ticketing-client";
import { useQR } from "../../hooks/customHooks";
import ReactToPrint from "react-to-print";
import { KioskTicketReceipt } from "./kioskTicketReceipt";
import { KioskTicketDetail } from "./kioskTicketDetails";
import { useKiosk } from "../../contexts/kioskContext";
import { EventDetailPayload } from "../../../common/payloads";

type EventDetailKioskConfirmationProps = {
  order: OrderPayload & { smsLink: string };
  onClickBack: () => void;
  eventDetailPayload: EventDetailPayload;
};
export const EventDetailKioskConfirmation: React.FC<EventDetailKioskConfirmationProps> =
  ({
    order,
    onClickBack,
    eventDetailPayload,
  }: EventDetailKioskConfirmationProps) => {
    const { resetKiosk } = useKiosk();
    const userQR = useQR(order.smsLink);
    const componentRef = useRef();
    let ticketQuantity = 0;
    order.eventItems
      .find((orderItem) => orderItem.eventName === "Admission")
      .items.forEach((item) => (ticketQuantity += item.quantity));

    return (
      <Fragment>
        <div className="kiosk-order-confirmation">
          <div className="kiosk-order-confirmation__container">
            <div className="kiosk-order-confirmation__header-wrapper">
              <h2 className="kiosk-order-confirmation__header">
                Thank you and enjoy your visit!
              </h2>
            </div>
            {order && (
              <div className="kiosk-order-confirmation__content">
                <div className="kiosk-order-confirmation__copy">
                  {Object.values(order.eventItems).map((orderItem, i) => {
                    const eventDate =
                      orderItem &&
                      orderItem.eventDate &&
                      orderItem.eventName === "Admission"
                        ? new Date(orderItem.eventDate)
                        : null;
                    return (
                      <KioskTicketDetail
                        key={i}
                        eventName={orderItem.eventName}
                        eventDate={eventDate}
                        ticketItems={orderItem.items.map(
                          ({ ticketTypeName, quantity }) => ({
                            ticketTypeName,
                            quantity,
                          })
                        )}
                        orderNumber={i < 1 && order.orderNumber}
                        includeTotal={i === order.eventItems.length - 1}
                      />
                    );
                  })}
                </div>

                <div className="kiosk-order-confirmation__qr">
                  <img src={userQR} alt="qrcode" />
                </div>
                <br />
                <div className="kiosk-order-confirmation__footer">
                  <p>
                    To access your ticket{ticketQuantity > 1 ? "s" : ""} on your
                    smartphone, simply scan the QR code above. <br />
                    You will receive an sms message at{" "}
                    {order.billingPhoneNumber} with your ticket
                    {ticketQuantity > 1 ? "s" : ""} shortly.
                    <br />
                    <br />
                    If you would prefer to print your ticket
                    {ticketQuantity > 1 ? "s" : ""}, please click the button
                    below.
                  </p>
                </div>
              </div>
            )}

            {/* Receipt for printing */}
            <div style={{ display: "none" }}>
              <div ref={componentRef}>
                <KioskTicketReceipt order={order} />
              </div>
            </div>
          </div>
        </div>
        <div className="kiosk-buttons">
          <div className="nav-buttons">
            <button
              className="btn-barnes-secondary"
              onClick={() => resetKiosk(eventDetailPayload)}
              type="button"
            >
              Done
            </button>
            <ReactToPrint
              trigger={() => (
                <button className="btn-barnes" onClick={onClickBack}>
                  Print Receipt and Tickets
                </button>
              )}
              content={() => componentRef.current}
              pageStyle={"@page { width: 288px; }"}
            />
          </div>
          <button
            className="btn-plain"
            id="btn-reset"
            onClick={() => resetKiosk(eventDetailPayload)}
            type="reset"
          >
            Start New Order
          </button>
        </div>
      </Fragment>
    );
  };
