import React, { createContext } from "react";

type AuthErrorContextType = {
  isAuthError: boolean;
  setIsAuthError?: React.Dispatch<React.SetStateAction<boolean>>;
};
export const AuthErrorContext = createContext<AuthErrorContextType>({
  isAuthError: false,
  setIsAuthError: null,
});
