import React, { useState, useEffect, useCallback, useContext } from "react";
import { Route } from "react-router-dom";

import { UserPage } from "./userPage/userPage";
import { EventDetail } from "./eventDetail/eventDetail";
import { Footer } from "./footer/footer";
import { TitleBar } from "./titleBar";
import {
  getGuestPasses,
  getUserInfo,
  getEvents,
  getOrders,
  getClasses,
} from "../services/membershipServices";
import { useAuthRequest } from "../hooks/customHooks";
import {
  UserOrdersPayload,
  EventSummaryPayload,
  MembershipDetails,
} from "../../common/payloads";
import { UserContext, UserData } from "../contexts/userContext";
import { AuthContext } from "../contexts/authContext";
import { ActiveMemberRoute } from "./activeMemberRoute";
import { ModalContext } from "../contexts/modalContext";

export const Content = () => {
  const [userData, setUserData] = useState<UserData>({
    user: null,
    guestPasses: null,
    orders: null,
    events: null,
    classes: null,
  });
  const [membershipStanding, setMembershipStanding] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const { setIsAuthenticated, setPermissions, setPermsGroup } =
    useContext(AuthContext);

  // Wrap all calls to api with authRequest hook, which will update error state on 403.
  const getOrdersAuth =
    useAuthRequest<() => Promise<UserOrdersPayload>>(getOrders);
  const getEventsAuth = useAuthRequest<
    () => Promise<{
      admission: EventSummaryPayload[];
      other: EventSummaryPayload[];
    }>
  >(getEvents);
  const getClassesAuth =
    useAuthRequest<() => Promise<EventSummaryPayload[]>>(getClasses);
  const getUserInfoAuth =
    useAuthRequest<() => Promise<MembershipDetails>>(getUserInfo);

  // Fetch user data, deps are all wrapped in useCallback / are Dispatch<SetStateAction>.
  const fetchUserData = useCallback(async () => {
    try {
      // Make call to server, membershipId is stored in request data.
      getEventsAuth().then((events) =>
        setUserData((data) => ({ ...data, events }))
      );
      getClassesAuth().then((classes) =>
        setUserData((data) => ({ ...data, classes }))
      );
      getUserInfoAuth().then(async (user) => {
        setUserData((data) => ({ ...data, user }));

        if (user) {
          setMembershipStanding(user.membershipStanding);
          setIsAuthenticated(!!user.authenticated);
          setPermissions(user.permissions);
          setPermsGroup(user.permsGroup);
        }

        if (user && user.cardholders.length && user.cardholders[0]) {
          const { membershipId } = user;

          try {
            const guestPasses = await getGuestPasses({ membershipId });
            setUserData((data) => ({ ...data, guestPasses }));
          } catch (e) {
            console.log(e);
          } finally {
            // Handle orders after, this is due to an issue w/ this call blocking the event loop.
            getOrdersAuth().then((orders) =>
              setUserData((data) => ({ ...data, orders }))
            );
          }
        }
      });
    } catch (e) {
      // On http error, reset state to default.
      console.log(e);
    }
  }, [
    getEventsAuth,
    getClassesAuth,
    getUserInfoAuth,
    getOrdersAuth,
    setMembershipStanding,
    setIsAuthenticated,
    setPermissions,
    setPermsGroup,
  ]);

  // Fetch data once on page load
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return (
    <>
      <div className="app__content">
        <UserContext.Provider
          value={{
            userData,
            setUserData,
            membershipStanding,
            setMembershipStanding,
          }}
        >
          <ModalContext.Provider value={{ showModal, setShowModal }}>
            <TitleBar />
            <Route path="/user">
              <UserPage fetchUserData={fetchUserData} />
            </Route>
            <ActiveMemberRoute path="/event/:id">
              <EventDetail
                cardholder={
                  Boolean(userData.user) &&
                  userData.user.cardholders &&
                  userData.user.cardholders.find(
                    ({ cardType }) => cardType === "primary"
                  )
                }
                updateOrders={async () => {
                  try {
                    const orders = await getOrdersAuth();

                    setUserData((data) => ({ ...data, orders }));
                  } catch (e) {
                    console.log(e);
                  }
                }}
              />
            </ActiveMemberRoute>
          </ModalContext.Provider>
        </UserContext.Provider>
      </div>
      <Footer />
    </>
  );
};
