/** Mapping for ACME API between eligible levels and shopping cart itemType */
export const MEMBER_RENEW_MAPPING = {
  renew: "MembershipRenewal",
  renewUpgrade: "MembershipRenewalUpgrade",
  renewDowngrade: "MembershipRenewalDowngrade",
  upgrade: "MembershipUpgrade",
  downgrade: "MembershipDowngrade",
  postTermRenew: "MembershipPostTermRenewal",
  postTermRenewUpgrade: "MembershipPostTermRenewalUpgrade",
  postTermRenewDowngrade: "MembershipPostTermRenewalDowngrade",
  rejoin: "MembershipRejoin",
  rejoinUpgrade: "MembershipRejoinUpgrade",
  rejoinDowngrade: "MembershipRejoinDowngrade",
};

export const MEMBER_RENEW_ACTIONS = {
  current: ["renew", "postTermRenew", "rejoin"],
  downgrade: [
    "renewDowngrade",
    "downgrade",
    "postTermRenewDowngrade",
    "rejoinDowngrade",
  ],
  upgrade: ["renewUpgrade", "upgrade", "postTermRenewUpgrade", "rejoinUpgrade"],
};
