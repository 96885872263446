import { request, gql } from "graphql-request";
import { PermsGroupType } from "../../common/types";

export const performGraphCmsRequest = async (
  query: string,
  variables?: { [key: string]: any }
): Promise<{ [key: string]: any }> => {
  try {
    const data = await request(
      process.env.REACT_APP_GRAPHCMS_ENDPOINT,
      query,
      variables
    );
    return data;
  } catch (error) {
    console.log(
      `An error occurred fetching data from GraphCMS: ${query}, variables: ${variables}`,
      error
    );

    throw error;
  }
};

export type ContactInfoType = {
  email: string;
  phone: string;
};

export const getContactInfo = async (
  permsGroup: PermsGroupType
): Promise<ContactInfoType> => {
  const query = gql`
    query getContactInfo($permsGroup: MembershipPermissionLevels) {
      contactInfos(where: { membershipType: $permsGroup }) {
        phone
        email
      }
    }
  `;

  const variables = {
    permsGroup,
  };

  const { contactInfos } = await performGraphCmsRequest(query, variables);
  return contactInfos[0];
};

export type UpsellContent = {
  membershipLevel: "All" | "Patron" | "Contributor" | "Supporter";
  content?: string[];
};

export const getUpsellContent = async (): Promise<UpsellContent[]> => {
  const query = gql`
    query getUpsellContent {
      vipUpsellContents {
        content
        membershipLevel
      }
    }
  `;

  const { vipUpsellContents } = await performGraphCmsRequest(query);
  return vipUpsellContents;
};
