import React from "react";
import { ReactComponent as Visa } from "payment-icons/min/flat/visa.svg";
import { ReactComponent as MasterCard } from "payment-icons/min/flat/mastercard.svg";
import { ReactComponent as AmericanExpress } from "payment-icons/min/flat/amex.svg";
import { ReactComponent as Discover } from "payment-icons/min/flat/discover.svg";
import { ReactComponent as JCB } from "payment-icons/min/flat/jcb.svg";

export const getCreditCardIcon: (type: string) => JSX.Element = (type) => {
  switch (type) {
    case "visa":
      return <Visa />;
    case "mastercard":
      return <MasterCard />;
    case "american-express":
      return <AmericanExpress />;
    case "discover":
      return <Discover />;
    case "jcb":
      return <JCB />;
    default:
      return null;
  }
};
