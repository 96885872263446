import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import {
  MembershipLevelObject,
  MembershipOfferingObject,
} from "acme-ticketing-client/dist/src/interfaces/acmeMembershipLevelsPayloads";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import { gql } from "graphql-request";

import { CarouselItem } from "./carouselItem";
import { ReactComponent as Arrow } from "../../settings/icons/arrow.svg";
import { LevelCarouselItem } from "../../../../common/payloads";
import { performGraphCmsRequest } from "../../../services/graphCmsService";

interface CarouselProps {
  onClick: (data: any) => void;
  levels: LevelCarouselItem[];
  initialItem: number;
  appliedOffer?: string;
}

export const Carousel: React.FC<CarouselProps> = ({
  onClick,
  levels,
  initialItem,
  appliedOffer,
}: CarouselProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const sto = useRef<ReturnType<typeof setTimeout>>(null); // ref for scroll sto.
  const [visibleChildIndex, setVisibleChildIndex] = useState(initialItem);
  const [selectedIndex, setSelectedIndex] = useState(initialItem);
  const [memberLevelBenefits, setMemberLevelBenefits] = useState({});

  // Sets the initial item as the selected item so it is centered on page load
  useEffect(() => {
    setSelectedIndex(initialItem);
    handleScrollToItem(initialItem);
  }, [initialItem]);

  // Clean up sto on unmount.
  useEffect(() => {
    return () => {
      if (sto.current) {
        clearTimeout(sto.current);
      }
    };
  }, []);

  // Fetch the copy for level benefits
  useEffect(() => {
    const fetchBenefits = async () => {
      const query = gql`
        query getMemberRenewOffers {
          memberRenewOffers {
            memberLevel
            content {
              html
            }
          }
        }
      `;

      const { memberRenewOffers } = await performGraphCmsRequest(query);
      const benefits = {};

      // Reformat into the expected object layout
      memberRenewOffers.forEach((offer) => {
        const [name, id] = offer.memberLevel.split("__");
        benefits[id] = offer.content.html;
      });

      setMemberLevelBenefits(benefits);
    };

    fetchBenefits();
  }, [setMemberLevelBenefits]);

  // onClick handler for selecting a level
  const handleSelectLevel = (
    data: {
      level: MembershipLevelObject;
      type: string;
      offer: MembershipOfferingObject;
      appliedOffer: string;
    },
    index: number
  ) => {
    // Pass data to onClick handler passed from parent component
    onClick(data);
    // Set local state and scroll to the selected item
    setSelectedIndex(index);
    handleScrollToItem(index);
  };

  // Handles scrolling the item when scroll bubble is clicked
  const handleScrollToItem = (i) => {
    if (ref.current) {
      const scrollTo = Array.from(ref.current.children)[i];

      if (scrollTo) {
        scrollTo.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
        setVisibleChildIndex(i);
      }
    }
  };

  // Scroll event listener
  const checkVisibleChild = () => {
    if (ref.current) {
      // If we have a current timeout, clear it.
      if (sto.current) {
        clearTimeout(sto.current);
      }

      sto.current = setTimeout(() => {
        const visibleIndex = Array.from(ref.current.children).findIndex(
          (child) => {
            const { left, width } = child.getBoundingClientRect();

            return left >= -1 && left < width;
          }
        );

        // If a visibleIndex was located.
        if (visibleIndex > -1) {
          setVisibleChildIndex(visibleIndex);
        }
      }, 100);
    }
  };

  const handleClickNext = () => {
    handleScrollToItem(visibleChildIndex + 1);
  };

  const handleClickPrevious = () => {
    handleScrollToItem(visibleChildIndex - 1);
  };

  return (
    <div className="carousel" onScroll={checkVisibleChild}>
      <div className="carousel__wrapper">
        <button
          className="carousel-arrow-wrapper"
          onClick={handleClickPrevious}
        >
          <Arrow className="carousel-arrow--left" />
        </button>
        <div className="carousel__content" ref={ref}>
          {levels &&
            levels.map((levelItem, i) => (
              <CarouselItem
                key={i}
                appliedOffer={appliedOffer}
                onClick={handleSelectLevel}
                levelItem={levelItem}
                selected={i === selectedIndex}
                index={i}
                handleScroll={handleScrollToItem}
              >
                {memberLevelBenefits[levelItem.level.id] ? (
                  <>{parse(memberLevelBenefits[levelItem.level.id])}</>
                ) : (
                  <>
                    {sanitizeHtml(levelItem.level.description, {
                      allowedTags: [],
                    })}
                    <ul className="carousel-item__list">
                      {levelItem.level.benefits.map((benefit, i) => (
                        <li className="carousel-item__list-item" key={i}>
                          {benefit.description}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </CarouselItem>
            ))}
        </div>
        <button className="carousel-arrow-wrapper" onClick={handleClickNext}>
          <Arrow className="carousel-arrow--right" />
        </button>
      </div>
      {levels.length > 1 && (
        <div className="slider__bubbles-wrapper">
          <div className="slider__bubbles">
            {[...Array(levels.length)].map((x, i) => {
              return (
                <button
                  key={i}
                  className={classnames("slider__bubble", {
                    "slider__bubble--active": i === visibleChildIndex,
                  })}
                  onClick={() => handleScrollToItem(i)}
                  aria-label={`Go to item ${i + 1}`}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
