import React from "react";

type ToggleProps = {
  onClick: () => any;
  isOn: boolean;
  className?: string;
};
export const Toggle: React.FC<ToggleProps> = ({
  onClick,
  isOn,
  className,
}: ToggleProps) => {
  let toggleClassName = "toggle";
  if (isOn) {
    toggleClassName = `${toggleClassName} toggle--active`;
  }

  if (className) {
    toggleClassName = `${toggleClassName} ${className}`;
  }

  return (
    <input type="checkbox" onClick={onClick} className={toggleClassName} />
  );
};
