import { Link, LinkProps } from "react-router-dom";
import React, { useRef } from "react";

import { CardHeader } from "./cardHeader";
import { CardImage } from "./cardImage";

type CardProps = {
  title: string;
  src?: string;
  subtitle?: string;
  description?: string;
  isHTML?: boolean;
  to?: LinkProps["to"];
  href?: string;
  className?: string;
  imageOffset?: number;
  children?: any;
};
export const Card: React.FC<CardProps> = ({
  title,
  src = null,
  subtitle,
  description,
  isHTML = true,
  to,
  href,
  className,
  children,
  imageOffset = 0,
}: CardProps) => {
  // Body of card.

  let cardClassName = "card";
  if (className) {
    cardClassName = `${cardClassName} ${className}`;
  }
  const imageWrapperRef = useRef<HTMLDivElement>();

  return (
    // If there is Link.to prop, then we can render a router link.
    // Otherwise, render a regular div.
    // This is for :last-of-type selector, so that each banner card is a sibling in its parent div.
    to ? (
      <div className={cardClassName}>
        <Link to={to}>
          <div className="card__header">
            <CardImage
              isHorizontalCard={false}
              header={title}
              src={src}
              imageOffset={imageOffset}
            />
            <CardHeader
              imageWrapperHeight={
                imageWrapperRef.current
                  ? imageWrapperRef.current.offsetHeight
                  : null
              }
              header={title}
              subheader={subtitle}
            />
          </div>

          <div className="card__body">
            <p>
              {/** Drop in text or HTML */}
              {Boolean(description || React.Children.count(children) === 0) &&
              isHTML ? (
                <div
                  className="card__summary"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              ) : (
                <div className="card__summary">{description || children}</div>
              )}
            </p>
          </div>
        </Link>
      </div>
    ) : href ? (
      <div className={cardClassName}>
        <a href={href} rel="noopener noreferrer" target="_blank">
          <div className="card__header">
            <CardImage
              isHorizontalCard={false}
              header={title}
              src={src}
              imageOffset={imageOffset}
            />
            <CardHeader
              imageWrapperHeight={
                imageWrapperRef.current
                  ? imageWrapperRef.current.offsetHeight
                  : null
              }
              header={title}
              subheader={subtitle}
            />
          </div>
          <div className="card__body">
            {/** Drop in text or HTML */}
            {Boolean(description || React.Children.count(children) === 0) &&
            isHTML ? (
              <div
                className="card__summary"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            ) : (
              <div className="card__summary">{description || children}</div>
            )}
          </div>
        </a>
      </div>
    ) : (
      <div className={cardClassName}>
        <div className="card__header">
          <CardImage
            isHorizontalCard={false}
            header={title}
            src={src}
            imageOffset={imageOffset}
          />
          <CardHeader
            imageWrapperHeight={
              imageWrapperRef.current
                ? imageWrapperRef.current.offsetHeight
                : null
            }
            header={title}
            subheader={subtitle}
          />
        </div>
        <div className="card__body">
          {/** Drop in text or HTML */}
          {Boolean(description || React.Children.count(children) === 0) &&
          isHTML ? (
            <div
              className="card__summary"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          ) : (
            <div className="card__summary">{description || children}</div>
          )}
        </div>
      </div>
    )
  );
};
