import {
  MembershipLevelObject,
  MembershipOfferingObject,
} from "acme-ticketing-client/dist/src/interfaces/acmeMembershipLevelsPayloads";
import {
  EligibleMembershipLevel,
  LevelCarouselItem,
  MembershipDetails,
} from "../../common/payloads";
import { MembershipRenewType } from "../../common/types";
import {
  MEMBER_RENEW_ACTIONS,
  MEMBER_RENEW_MAPPING,
} from "../constants/memberLevels";
import { CardHolderFields } from "../hooks/useCardHolderForm";

type RenewType = "downgrade" | "upgrade" | "current";

type SecondCardHolderData = CardHolderFields["values"] & {
  cardType: "secondary";
  constituentImportId: null | string;
};

/**
 * Formats data from the ACME API for rendering in the level selector carousel
 * @param {EligibleMembershipLevel[]} eligibleLevels - levels object from the ACME API
 * @param {string?} offerCode - offer code to be applied
 * @returns {false | [LevelCarouselItem[], number]} Will return false if there are no eligible levels,
 * otherwise it will return and array of objects for rendering level selector carousel
 * and the index of the renew item in the array
 */
export const getFormattedLevels = (
  eligibleLevels: EligibleMembershipLevel[],
  offerCode?: string
): [LevelCarouselItem[] | null, number | null] => {
  // Get downgrade, upgrade, and current level from eligibleLevels
  const downgrade = getRenewLevel(eligibleLevels, "downgrade");
  const upgrade = getRenewLevel(eligibleLevels, "upgrade");
  const current = getRenewLevel(eligibleLevels, "current");

  // Check for levels, and return false if there are no levels
  if (!downgrade && !current && !upgrade) {
    return [null, null];
  }

  // Sort downgrade and upgrade levels by displayOrder (current only has one target level, so it doesn't need to be sorted)
  const sortedDowngrade = sortTargetLevels(downgrade.targetLevels);
  const sortedUpgrade = sortTargetLevels(upgrade.targetLevels);

  // Create objects for rendering
  const downgradeLevels = getLevelObject(
    MEMBER_RENEW_MAPPING[downgrade.type],
    sortedDowngrade,
    "Downgrade",
    offerCode
  );
  const upgradeLevels = getLevelObject(
    MEMBER_RENEW_MAPPING[upgrade.type],
    sortedUpgrade,
    "Upgrade",
    offerCode
  );
  const currentLevels = getLevelObject(
    MEMBER_RENEW_MAPPING[current.type],
    current.targetLevels,
    "Renew",
    offerCode
  );

  return [
    [...downgradeLevels, ...currentLevels, ...upgradeLevels],
    downgradeLevels.length,
  ];
};

/**
 * Get renewal targetLevels associated with a renewal type
 * @param {EligibleMembershipLevel[]} eligibleLevels - array of membership level options
 * @param {RenewType} renewType - renew type that we are getting
 * @returns {EligibleMembershipLevel} eligible membership level option matching the renew type
 */
export const getRenewLevel = (
  eligibleLevels: EligibleMembershipLevel[],
  renewType: RenewType
): EligibleMembershipLevel => {
  return eligibleLevels.find((level) =>
    MEMBER_RENEW_ACTIONS[renewType].includes(level.type)
  );
};

/**
 * Sort target levels by display order
 * @param {MembershipLevelObject[]} targetLevels
 * @returns {MembershipLevelObject[]} list of target levels sorted by display order
 */
export const sortTargetLevels = (
  targetLevels: MembershipLevelObject[]
): MembershipLevelObject[] => {
  return targetLevels.sort((a, b) => a.displayOrder - b.displayOrder);
};

/**
 * @param {MembershipRenewType} type - type of renew options
 * @param {MembershipLevelObject[]} levelOptions - array of membership renewal level options
 * @param {string} buttonText - text for UI button
 * @param {string} offerCode - offer code to be applied to the purchase
 * @returns {LevelCarouselItem[]} object with data required for rendering the level carousel card
 */
export const getLevelObject = (
  type: MembershipRenewType,
  levelOptions: MembershipLevelObject[],
  buttonText: string,
  offerCode?: string
): LevelCarouselItem[] => {
  return levelOptions.map((level) => {
    const [offer, applyOffer] = getOffer(level.offerings, offerCode);
    const formattedLevel = {
      buttonText,
      level,
      type,
      offer,
      applyOffer,
    };
    return formattedLevel;
  });
};

/**
 * Finds membership offering that matches the given offer
 * @param {MembershipOfferingObject[]} offerings - array of offerings to filter for the given offer
 * @param {string} offerCode - offer code to apply
 * @returns {[MembershipOfferingObject, boolean]} offering that matches the given offer and boolean to
 * indicate whether or not an offering was found. If no offer is found this will return the standard offering
 */
export const getOffer = (
  offerings: MembershipOfferingObject[],
  offerCode?: string
): [MembershipOfferingObject, boolean] => {
  const offerLevel =
    offerCode &&
    offerings.find((offer) =>
      offer.name.toLowerCase().includes(offerCode.toLowerCase())
    );

  // If level is found that matches the offer, return it and true to indicate offer code applied.
  if (offerLevel) {
    return [offerLevel, true];
  } else {
    // Otherwise return the standard level and set error message
    return [offerings[0], false];
  }
};

/**
 * @param {CardHolderFields["values"]} secondaryCardHolderValues - data provided by the user in the card holder form
 * @param {string} primaryFirst - first name of the primary card holder
 * @param {string} primaryLast - last name of the primary card holder
 * @param {MembershipDetails["cardholders"][0]} originalSecondaryCardHolder - original cardholder data that is pulled from the ACME API on login
 * @returns {SecondCardHolderData} card holder details properly formatted to be submitted to the API
 */
export const getSecondCardHolderData = (
  secondaryCardHolderValues: CardHolderFields["values"],
  primaryFirst: string,
  primaryLast: string,
  originalSecondaryCardHolder: MembershipDetails["cardholders"][0]
): SecondCardHolderData => {
  const secondaryMemberCard: SecondCardHolderData = {
    cardType: "secondary",
    ...secondaryCardHolderValues,
    constituentImportId: null,
  };

  // If no information has been given to second card holder, set default
  if (
    secondaryCardHolderValues.firstName === "" &&
    secondaryCardHolderValues.lastName === ""
  ) {
    secondaryMemberCard.firstName = `Guest of ${primaryFirst}`;
    secondaryMemberCard.lastName = primaryLast;
    // Else if secondary card holder info has been given and has not changed, include the constituent import ID from the original
  } else if (
    originalSecondaryCardHolder &&
    originalSecondaryCardHolder.firstName ===
      secondaryCardHolderValues.firstName &&
    originalSecondaryCardHolder.lastName === secondaryCardHolderValues.lastName
  ) {
    secondaryMemberCard.constituentImportId =
      originalSecondaryCardHolder.constituentImportId;
  }

  return secondaryMemberCard;
};
