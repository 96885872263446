/**
 * @param {string} creditCardNumber - valid credit card number
 * @returns {string} - masked credit card number formatted "•••• •••• •••• 1234"
 */
export const maskCardNumber = (creditCardNumber: string) => {
  const mask = creditCardNumber
    .substring(0, creditCardNumber.length - 4)
    .replace(/[0-9]{4}/g, "•••• ");

  const lastFourDigits = creditCardNumber.substring(
    creditCardNumber.length - 4,
    creditCardNumber.length
  );

  return `${mask}${lastFourDigits}`;
};
