import { useState } from "react";

import { MembershipDetails } from "../../common/payloads";
import { InputFieldType } from "../../common/types";
import {
  validateText,
  validateEmail,
  validateZip,
  validateCardHolder,
  validatePII,
} from "../util/validation";

export interface CardHolderFields {
  fields: {
    contact: {
      firstName: InputFieldType;
      lastName: InputFieldType;
      email: InputFieldType;
      phone: InputFieldType;
    };
    address: {
      address1: InputFieldType;
      address2: InputFieldType;
      city: InputFieldType;
      state: InputFieldType;
      zipCode: InputFieldType;
    };
  };
  values: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    streetAddress1: string;
    streetAddress2: string;
    city: string;
    state: string;
    zipCode: string;
  };
  validateCardHolder: (primary: boolean) => boolean;
  validatePII: (requireAddress: boolean) => boolean;
  setInitialValues: (cardHolder: MembershipDetails["cardholders"][0]) => void;
}

export const useCardHolderForm = (): CardHolderFields => {
  // First Name
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [fName, setFirstName] = useState("");
  const onFirstNameBlur = (event) => {
    const isValid = validateText(fName);
    setIsFirstNameValid(isValid);
  };
  const onFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const firstNameError = "Please enter the card holder's first name.";
  const firstName = {
    attributes: {
      onBlur: onFirstNameBlur,
      onChange: onFirstNameChange,
      type: "text",
      id: "first-name",
    },
    isValid: isFirstNameValid,
    setIsValid: setIsFirstNameValid,
    value: fName,
    setValue: setFirstName,
    validate: () => validateText(fName),
    label: "First Name",
    errorMessage: firstNameError,
  };

  // Last Name
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [lName, setLastName] = useState("");
  const onLastNameBlur = (event) => {
    const isValid = validateText(lName);
    setIsLastNameValid(isValid);
  };
  const onLastNameChange = (event) => {
    setLastName(event.target.value);
  };
  const lastNameError = "Please enter the card holder's last name.";
  const lastName = {
    attributes: {
      onBlur: onLastNameBlur,
      onChange: onLastNameChange,
      type: "text",
      id: "last-name",
    },
    isValid: isLastNameValid,
    setIsValid: setIsLastNameValid,
    value: lName,
    setValue: setLastName,
    validate: () => validateText(lName),
    label: "Last Name",
    errorMessage: lastNameError,
  };

  // Email
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailAddr, setEmail] = useState("");
  const onEmailBlur = (event) => {
    const isValid = validateEmail(emailAddr);
    setIsEmailValid(isValid);
  };
  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const emailError = "Please enter a valid email address.";
  const email = {
    attributes: {
      onBlur: onEmailBlur,
      onChange: onEmailChange,
      type: "text",
      id: "email",
    },
    isValid: isEmailValid,
    setIsValid: setIsEmailValid,
    value: emailAddr,
    setValue: setEmail,
    validate: () => validateEmail(emailAddr),
    label: "Email",
    errorMessage: emailError,
  };

  // Phone
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [phoneNum, setPhone] = useState("");
  const onPhoneBlur = (event) => {
    const isValid = validateText(phoneNum);
    setIsPhoneValid(isValid);
  };
  const onPhoneChange = (event) => {
    setPhone(event.target.value);
  };
  const phoneError = "Please enter a valid phone number.";
  const phone = {
    attributes: {
      onBlur: onPhoneBlur,
      onChange: onPhoneChange,
      type: "text",
      id: "phone",
    },
    isValid: isPhoneValid,
    setIsValid: setIsPhoneValid,
    value: phoneNum,
    setValue: setPhone,
    validate: () => validateText(phoneNum),
    label: "Mobile Phone Number",
    errorMessage: phoneError,
  };

  // Address 1
  const [isAddress1Valid, setIsAddress1Valid] = useState(true);
  const [addr1, setAddress1] = useState("");
  const onAddress1Blur = (event) => {
    const isValid = validateText(addr1);
    setIsAddress1Valid(isValid);
  };
  const onAddress1Change = (event) => {
    setAddress1(event.target.value);
  };
  const addressError = "Please enter your address.";
  const address1 = {
    attributes: {
      onBlur: onAddress1Blur,
      onChange: onAddress1Change,
      type: "text",
      id: "address1",
    },
    isValid: isAddress1Valid,
    setIsValid: setIsAddress1Valid,
    value: addr1,
    setValue: setAddress1,
    validate: () => validateText(addr1),
    label: "Address 1",
    errorMessage: addressError,
  };

  // Address 2
  const [isAddress2Valid, setIsAddress2Valid] = useState(true);
  const [addr2, setAddress2] = useState("");
  const onAddress2Blur = (event) => {
    const isValid = addr2.length > 0 ? validateText(addr2) : true;
    setIsAddress2Valid(isValid);
  };
  const onAddress2Change = (event) => {
    setAddress2(event.target.value);
  };
  const address2 = {
    attributes: {
      onBlur: onAddress2Blur,
      onChange: onAddress2Change,
      type: "text",
      id: "address2",
    },
    isValid: isAddress2Valid,
    setIsValid: setIsAddress2Valid,
    value: addr2,
    setValue: setAddress2,
    validate: () => validateText(addr2),
    label: "Address 2",
    errorMessage: addressError,
  };

  // City
  const [isCityValid, setIsCityValid] = useState(true);
  const [cityAddr, setCity] = useState("");
  const onCityBlur = (event) => {
    const isValid = validateText(cityAddr);
    setIsCityValid(isValid);
  };
  const onCityChange = (event) => {
    setCity(event.target.value);
  };
  const cityError = "Please enter your city.";
  const city = {
    attributes: {
      onBlur: onCityBlur,
      onChange: onCityChange,
      type: "text",
      id: "city",
    },
    isValid: isCityValid,
    setIsValid: setIsCityValid,
    value: cityAddr,
    setValue: setCity,
    validate: () => validateText(cityAddr),
    label: "City",
    errorMessage: cityError,
  };

  // State
  const [isStateValid, setIsStateValid] = useState(true);
  const [stateAddr, setState] = useState("");
  const onStateBlur = (event) => {
    const isValid = validateText(stateAddr);
    setIsStateValid(isValid);
  };
  const onStateChange = (event) => {
    setState(event.target.value);
  };
  const stateError = "Please enter your state.";
  const state = {
    attributes: {
      onBlur: onStateBlur,
      onChange: onStateChange,
      type: "text",
      id: "state",
    },
    isValid: isStateValid,
    setIsValid: setIsStateValid,
    value: stateAddr,
    setValue: setState,
    validate: () => validateText(stateAddr),
    label: "State",
    errorMessage: stateError,
  };

  // Zip code
  const [isZipValid, setIsZipValid] = useState(true);
  const [zipAddr, setZip] = useState("");
  const onZipBlur = (event) => {
    const isValid = validateZip(zipAddr);
    setIsZipValid(isValid);
  };
  const onZipChange = (event) => {
    setZip(event.target.value);
  };
  const zipError = "Please enter a valid zip code.";
  const zip = {
    attributes: {
      onBlur: onZipBlur,
      onChange: onZipChange,
      type: "text",
      id: "zip",
    },
    isValid: isZipValid,
    setIsValid: setIsZipValid,
    value: zipAddr,
    setValue: setZip,
    validate: () => validateZip(zipAddr),
    label: "Zip Code",
    errorMessage: zipError,
  };

  // Validate Card Holder
  const validate = (primary: boolean) => {
    return validateCardHolder({
      primary,
      firstName,
      lastName,
      email,
      phone,
      address1,
      address2,
      city,
      state,
      zip,
    });
  };

  const validateGuestPII = (addressRequired: boolean) => {
    return validatePII({
      addressRequired,
      firstName,
      lastName,
      email,
      phone,
      address1,
      address2,
      city,
      state,
      zip,
    });
  };

  // Set Initial Values
  const setInitialValues = (
    cardHolder: MembershipDetails["cardholders"][0]
  ) => {
    const name = cardHolder.name ? cardHolder.name.split(" ") : [];

    if (cardHolder.firstName) {
      setFirstName(cardHolder.firstName);
    } else if (name[0] === "Guest") {
      // If it is a "Guest of..." card, default to empty string
      setFirstName("");
    } else {
      // Set use the name field to set the first name, or default to empty string
      setFirstName(name[0] || "");
    }

    if (cardHolder.lastName) {
      setLastName(cardHolder.lastName);
    } else if (name[0] === "Guest") {
      // If it is a "Guest of..." card, default to empty string
      setLastName("");
    } else {
      // Set use the name field to set the last name, or default to empty string
      const lastName = name[name.length - 1];
      setLastName(lastName || "");
    }

    setEmail(cardHolder.email || "");
    setPhone(cardHolder.phoneNumber || "");
    setAddress1(cardHolder.streetAddress1 || "");
    setCity(cardHolder.city || "");
    setState(cardHolder.state || "");
    setZip(cardHolder.zipCode || "");
  };

  return {
    fields: {
      contact: {
        firstName,
        lastName,
        email,
        phone,
      },
      address: {
        address1,
        address2,
        city,
        state,
        zipCode: zip,
      },
    },
    values: {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      phone: phone.value,
      streetAddress1: address1.value,
      streetAddress2: address2.value,
      city: city.value,
      state: state.value,
      zipCode: zip.value,
    },
    validateCardHolder: validate,
    validatePII: validateGuestPII,
    setInitialValues,
  };
};
