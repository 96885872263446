import React from "react";
import { HeroBanner } from "./shared/heroBanner";
import { generateImgixUrl } from "../util/generateImgixUrl";

type NotFoundProps = {};

const backgroundSrc = generateImgixUrl(
  "sharedBackgroundImages/BF962-August-Spotlight-Tour-crop",
  "fit=crop&auto=compress&w=1500&h=350"
);

export const NotFound: React.FC<NotFoundProps> = ({}: NotFoundProps) => {
  return (
    <div className="wrapper" id="not-found">
      <HeroBanner src={backgroundSrc} header="Oops!"></HeroBanner>
      <div className="container">
        <h1>Don’t be afraid — you are definitely not lost.</h1>
        <p>
          The page you are looking for is not available. It may have expired or
          moved. <br />
          How about visiting our <a href="/user/home">homepage</a> or{" "}
          <a href="https://barnesfoundation.org/">main site</a>?
        </p>
      </div>
    </div>
  );
};
