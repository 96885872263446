import { useState } from "react";

import {
  validateCVC,
  validateCreditCard,
  validateExpirationDate,
  validateNumber,
} from "../util/validation";
import { maskCardNumber } from "../util/masking";
import { InputFieldType } from "../../common/types";
import { PaymentInformation } from "../../common/payloads";
import { useCardHolderForm } from "./useCardHolderForm";

export type CreditCardInputFields = {
  fields: {
    cc: {
      number: InputFieldType;
      expDate: InputFieldType;
      cvc: InputFieldType;
    };
    address: {
      firstName: InputFieldType;
      lastName: InputFieldType;
      email: InputFieldType;
      phoneNumber: InputFieldType;
      address1: InputFieldType;
      address2: InputFieldType;
      city: InputFieldType;
      state: InputFieldType;
      zipCode: InputFieldType;
    };
  };
  values: PaymentInformation;
  validateCreditCard: () => boolean;
  validateCardHolder: (primary: boolean) => boolean;
  validatePII: (addressRequired: boolean) => boolean;
  clearPayment: () => void;
};

// Custom hook for generating input fields with validation for a credit card payment form
export const useCreditCardForm = (): CreditCardInputFields => {
  // Credit Card Number
  const [isNumberValid, setIsNumberValid] = useState(true);
  const [number, setNumber] = useState("");
  const [maskedNumber, setMaskedNumber] = useState("");
  const onCreditCardBlur = () => {
    const isValid = validateNumber(number);
    setIsNumberValid(isValid);
    setMaskedNumber(maskCardNumber(number));
  };
  const onCreditCardChange = (event) => {
    const value = event.target.value.replace(/\s/g, "");
    setNumber(value);
    setMaskedNumber(value.replace(/([0-9]{4})/g, "$& ").trim());
  };
  const onCreditCardFocus = () => {
    setMaskedNumber(number.replace(/([0-9]{4})/g, "$& ").trim());
  };
  const ccError = "Please enter a valid credit card number.";

  // Expiration Date
  const [isExpDateValid, setIsExpDateValid] = useState(true);
  const [expDate, setExpDate] = useState("");
  const [maskedExpDate, setMaskedExpDate] = useState("");
  const onExpDateBlur = () => {
    const isValid = validateExpirationDate(expDate);
    setIsExpDateValid(isValid);
    const value = expDate.replace("/", "");
    setMaskedExpDate(value.replace(/^([0-9]{2})/g, "$&/"));
  };
  const onExpDateChange = (event) => {
    const value = event.target.value;
    setExpDate(value);
    setMaskedExpDate(value);
  };
  const expDateError =
    "Please enter a valid expiration date with the format MM/YY.";

  // CVC
  const [isCVCValid, setIsCVCValid] = useState(true);
  const [cvc, setCVC] = useState("");
  const onCVCBlur = () => {
    const isValid = validateCVC({ number, cvc });
    setIsCVCValid(isValid);
  };
  const cvcError = "Please enter a valid cvc.";

  // Payment Contact Information
  const contactInfo = useCardHolderForm();

  // Validate Credit Card
  const validate = () => {
    return validateCreditCard(number, expDate, cvc);
  };

  // Clears all payment and address information
  const clearPayment = () => {
    const {
      contact: { firstName, lastName, email, phone },
      address: { address1, address2, city, state, zipCode },
    } = contactInfo.fields;

    setNumber("");
    setExpDate("");
    setCVC("");
    firstName.setValue("");
    lastName.setValue("");
    email.setValue("");
    phone.setValue("");
    address1.setValue("");
    address2.setValue("");
    city.setValue("");
    state.setValue("");
    zipCode.setValue("");
  };

  return {
    fields: {
      cc: {
        number: {
          attributes: {
            id: "cc-number",
            onBlur: onCreditCardBlur,
            onChange: onCreditCardChange,
            onFocus: onCreditCardFocus,
          },
          value: number,
          maskedValue: maskedNumber,
          isValid: isNumberValid,
          setValue: setNumber,
          validate: validateNumber,
          label: "Credit Card Number",
          errorMessage: ccError,
          maxLength: 19,
        },
        expDate: {
          attributes: {
            id: "expiration-date",
            onBlur: onExpDateBlur,
            onChange: onExpDateChange,
          },
          isValid: isExpDateValid,
          value: expDate,
          maskedValue: maskedExpDate,
          setValue: setExpDate,
          validate: validateExpirationDate,
          label: "Expiration Date",
          errorMessage: expDateError,
          placeholder: "MM/YY",
          maxLength: 5,
        },
        cvc: {
          attributes: {
            id: "cvc",
            onBlur: onCVCBlur,
            onChange: (event) => setCVC(event.target.value),
          },
          isValid: isCVCValid,
          value: cvc,
          setValue: setCVC,
          validate: validateCVC,
          label: "CVC",
          errorMessage: cvcError,
          maxLength: 4,
        },
      },
      address: {
        firstName: contactInfo.fields.contact.firstName,
        lastName: contactInfo.fields.contact.lastName,
        email: contactInfo.fields.contact.email,
        phoneNumber: {
          ...contactInfo.fields.contact.phone,
          label: "Phone Number",
        },
        ...contactInfo.fields.address,
      },
    },
    values: {
      number,
      expDate,
      cvc,
      firstName: contactInfo.values.firstName,
      lastName: contactInfo.values.lastName,
      email: contactInfo.values.email,
      phoneNumber: contactInfo.values.phone,
      streetAddress1: contactInfo.values.streetAddress1,
      streetAddress2: contactInfo.values.streetAddress2,
      city: contactInfo.values.city,
      state: contactInfo.values.state,
      zip: contactInfo.values.zipCode,
    },
    validateCreditCard: validate,
    validateCardHolder: contactInfo.validateCardHolder,
    validatePII: contactInfo.validatePII,
    clearPayment,
  };
};
