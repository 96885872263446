import React, {
  useState,
  useCallback,
  useRef,
  CSSProperties,
  useEffect,
} from "react";
import creditCardType from "credit-card-type";
import { card as formatCreditCard } from "creditcards";
import {
  validateCreditCard,
  maskCreditCard,
} from "../helpers/validateCreditCard";
import { MembershipDetails } from "../../../../common/payloads";

/**
 * Shape of inputs.
 */
export type AddressKeys = "address1" | "city" | "zipCode" | "state";
type ContactKeys = "firstName" | "lastName" | "phoneNumber" | "email";

/**
 * General input attributes.
 */
type InputAttribute = {
  sort: number;
  label: string;
  value: string;
  style?: CSSProperties;
  additionalProps: {
    [key: string]: any;
  };
};
/**
 * Payment method type for updating and validating cc.
 */
type ValidationAttribute = {
  isValid: boolean | void;
  errorMessage?: string;
  isDisabled?: boolean;
};

/**
 * Additional type for CC, to contain information about CC type for SVG icon and masked value
 * to block out CC number on autocomplete.
 */
type CreditCardValidation = {
  creditCardType: string;
  maskedValue: string | void;
};

/**
 * PaymentMethod object that will be sent with cart on purchase.
 */
export type PaymentMethod = {
  // Credit card
  payment: {
    creditCard: InputAttribute & ValidationAttribute & CreditCardValidation;
    expirationDate: InputAttribute & ValidationAttribute;
    firstName: InputAttribute & ValidationAttribute;
    lastName: InputAttribute & ValidationAttribute;
    cvc: InputAttribute & ValidationAttribute;
  };

  // Billing address
  address: {
    [key in AddressKeys]: InputAttribute &
      Partial<ValidationAttribute> &
      Partial<CreditCardValidation>;
  };

  // Contact information
  contact: {
    [key in ContactKeys]: InputAttribute &
      Partial<ValidationAttribute> &
      Partial<CreditCardValidation>;
  };

  membership: {
    [key in AddressKeys | ContactKeys]: InputAttribute &
      Partial<ValidationAttribute> &
      Partial<CreditCardValidation>;
  };
};

export type PaymentMethodHandlers = {
  updatePaymentMethod: (
    paymentSectionName: string,
    key: string,
    value: string
  ) => void;
  autofillCardholderInformation: () => void;
  autoFillMembershipInformation: () => void;
  validatePaymentMethod: () => void;
  resetPaymentMethodValidation: (key: string) => void;
  setHasBillingMatchMembership: React.Dispatch<React.SetStateAction<Boolean>>;
};

/**
 * Custom hook to handle external logic of payment method.
 *
 * The payment method controls all user inputted data related to form fields
 * that we will send to ACME.
 */
export const usePaymentMethod = (
  cardholder?: MembershipDetails["cardholders"][0]
): {
  paymentMethod: PaymentMethod;
  hasBillingMatchMembership: boolean;
} & PaymentMethodHandlers => {
  // Keep track of autoComplete state.
  // On autocomplete state updating, change card mask value.
  const isAutoCompleteRef = useRef(false);

  // For if member keeps billing data same as membership data.
  const [hasBillingMatchMembership, setHasBillingMatchMembership] =
    useState(true);

  // For autopopulating billing name from contact
  const [, setHasInteractedWithBillingName] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>({
    payment: {
      firstName: {
        sort: 1,
        value: "",
        isValid: true,
        label: "First Name on Card",

        // This attribute should be 50% width.
        style: {
          flex: "1 0 calc(50% - 10px)",
          marginRight: "10px",
        },

        // For spreading on component
        additionalProps: {
          // Autocomplete props
          name: "cc-given-name",
          autoComplete: "cc-given-name",

          // On click, no longer do autocomplete
          onClick: () => setHasInteractedWithBillingName(true),
        },
      },

      lastName: {
        sort: 2,
        value: "",
        isValid: true,
        label: "Last Name on Card",

        // This attribute should be 50% width.
        style: {
          flex: "1 0 calc(50% - 10px)",
        },

        // For spreading on component
        additionalProps: {
          // Autocomplete props
          name: "cc-family-name",
          autoComplete: "cc-family-name",

          // On click, no longer do autocomplete
          onClick: () => setHasInteractedWithBillingName(true),
        },
      },

      creditCard: {
        sort: 3,
        value: "",

        isValid: true,
        errorMessage: "Invalid card number.",

        label: "Card Number",

        creditCardType: null,
        maskedValue: null,

        additionalProps: {
          // Add spaces to credit card, overriding default method.
          onChange: ({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) => {
            setPaymentMethod((paymentMethod) => {
              const isAutoComplete =
                !paymentMethod.payment.creditCard.value && value.length > 13;

              // Find CC type.
              const defaultCard = value ? creditCardType(value)[0] : null;
              const type = defaultCard ? defaultCard.type : null;

              // Update refs
              isAutoCompleteRef.current = isAutoComplete;

              return {
                ...paymentMethod,
                payment: {
                  ...paymentMethod.payment,
                  creditCard: {
                    ...paymentMethod.payment.creditCard,
                    value: value
                      ? formatCreditCard.format(value.replace(/\s/g, ""))
                      : "",
                    maskedValue: isAutoComplete ? maskCreditCard(value) : null,
                    creditCardType: type,
                  },
                },
              };
            });
          },
          onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
            // Check keys to make sure it is not a navigation press.
            if (
              isAutoCompleteRef.current &&
              e.key !== "Tab" &&
              e.key !== "Shift" &&
              !e.key.includes("Arrow")
            ) {
              isAutoCompleteRef.current = false;

              setPaymentMethod((paymentMethod) => ({
                ...paymentMethod,
                payment: {
                  ...paymentMethod.payment,
                  creditCard: {
                    ...paymentMethod.payment.creditCard,
                    value: "",
                    maskedValue: null,
                  },
                },
              }));
            }
          },

          // DOM properties
          type: "tel",
          inputMode: "numeric",
          pattern: "[0-9]{13,19}",
          maxLength: 19,

          // Autocomplete props
          name: "cardnumber",
          autoComplete: "cc-number",
        },
      },

      expirationDate: {
        sort: 4,
        value: "",
        isValid: true,
        errorMessage: "Invalid expiration date.",

        label: "Expiry",

        // This attribute should be 50% width.
        style: {
          flex: "1 0 calc(50% - 10px)",
          marginRight: "10px",
        },

        additionalProps: {
          // On expiry change, add / on delete
          onChange: ({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) => {
            const removedSlashesExpiry = value.replace(/\//g, "");

            setPaymentMethod((paymentMethod) => {
              // Determine if our removed slashes is > 2 and if we are increasing in size.
              const formattedExpiry =
                (removedSlashesExpiry.length >= 2 &&
                  paymentMethod.payment.expirationDate.value.length <
                    value.length) ||
                removedSlashesExpiry.length === 3
                  ? `${removedSlashesExpiry.substring(
                      0,
                      2
                    )}/${removedSlashesExpiry.substring(2)}`
                  : removedSlashesExpiry;

              return {
                ...paymentMethod,
                payment: {
                  ...paymentMethod.payment,
                  expirationDate: {
                    ...paymentMethod.payment.expirationDate,
                    value: formattedExpiry,
                  },
                },
              };
            });
          },

          // DOM properties
          inputMode: "numeric",
          placeholder: "MM/YY",
          maxLength: 5,

          // autocomplete props
          name: "cc-exp",
          autoComplete: "cc-exp",
        },
      },

      cvc: {
        sort: 5,
        value: "",
        isValid: true,
        errorMessage: "Invalid security code.",
        label: "Security Code",

        // This attribute should be 50% width.
        style: {
          flex: "1 0 calc(50% - 10px)",
        },

        additionalProps: {
          // DOM properties
          maxLength: 4,
          inputMode: "numeric",
          pattern: "[0-9]{3,5}",

          // autocomplete props
          name: "cvc",
          autoComplete: "cc-csc",
        },
      },
    },

    address: {
      address1: {
        sort: 1,
        label: "Address",
        value: "",

        additionalProps: {
          autoComplete: "shipping street-address",
        },
      },

      city: {
        sort: 2,
        label: "City",
        value: "",

        additionalProps: {
          autoComplete: "shipping locality",
        },
      },

      zipCode: {
        sort: 3,
        label: "Zip Code",
        value: "",

        // This attribute should be 50% width.
        style: {
          flex: "1 0 calc(50% - 10px)",
          marginRight: "10px",
        },

        additionalProps: {
          type: "tel",
          inputMode: "numeric",
          maxLength: 5,

          autoComplete: "shipping postal-code",
          name: "shipping postal-code",

          onChange: ({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) => {
            const sanitizedZipCode = value.replace(/\D/g, "");

            setPaymentMethod((paymentMethod) => ({
              ...paymentMethod,
              address: {
                ...paymentMethod.address,
                zipCode: {
                  ...paymentMethod.address.zipCode,
                  value: sanitizedZipCode,
                },
              },
            }));
          },
        },
      },

      state: {
        sort: 4,
        label: "State",
        value: "",

        // This attribute should be 50% width.
        style: {
          flex: "1 0 calc(50% - 10px)",
        },

        additionalProps: {
          maxLength: 2,

          autoComplete: "shipping region",
          name: "shipping region",
        },
      },
    },

    contact: {
      firstName: {
        sort: 1,
        label: "First Name",
        value: cardholder ? cardholder.name : "",

        // This attribute should be 50% width.
        style: {
          flex: "1 0 calc(50% - 10px)",
          marginRight: "10px",
        },

        additionalProps: {
          autoComplete: "given-name",
          name: "given-name",

          onChange: ({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) => {
            setHasInteractedWithBillingName((hasInteractedWithBillingName) => {
              setPaymentMethod((paymentMethod) => ({
                ...paymentMethod,

                // Overwrite payment info here
                payment: {
                  ...paymentMethod.payment,
                  firstName: {
                    ...paymentMethod.payment.firstName,
                    value: hasInteractedWithBillingName
                      ? paymentMethod.payment.firstName.value
                      : value,
                  },
                },

                contact: {
                  ...paymentMethod.contact,
                  firstName: {
                    ...paymentMethod.contact.firstName,
                    value,
                  },
                },
              }));

              return hasInteractedWithBillingName;
            });
          },
        },
      },

      lastName: {
        sort: 2,
        label: "Last Name",
        value: cardholder ? cardholder.name : "",

        // This attribute should be 50% width.
        style: {
          flex: "1 0 calc(50% - 10px)",
        },

        additionalProps: {
          autoComplete: "family-name",
          name: "family-name",

          onChange: ({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) => {
            setHasInteractedWithBillingName((hasInteractedWithBillingName) => {
              setPaymentMethod((paymentMethod) => ({
                ...paymentMethod,

                // Overwrite payment info here
                payment: {
                  ...paymentMethod.payment,
                  lastName: {
                    ...paymentMethod.payment.lastName,
                    value: hasInteractedWithBillingName
                      ? paymentMethod.payment.lastName.value
                      : value,
                  },
                },

                contact: {
                  ...paymentMethod.contact,
                  lastName: {
                    ...paymentMethod.contact.lastName,
                    value,
                  },
                },
              }));

              return hasInteractedWithBillingName;
            });
          },
        },
      },

      email: {
        sort: 3,
        label: "E-mail",
        value: cardholder ? cardholder.email : "",

        isValid: true,
        errorMessage: "Invalid email address.",

        additionalProps: {
          autoComplete: "email",
          name: "email",
        },
      },

      phoneNumber: {
        sort: 4,
        label: "Mobile Phone #",
        value: cardholder ? cardholder.phoneNumber : "",

        additionalProps: {
          type: "tel",
          autoComplete: "tel",
          inputMode: "numeric",
          name: "tel",

          onChange: ({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) => {
            const sanitizedPhoneNumber = value.replace(/\D/g, "");

            setPaymentMethod((paymentMethod) => ({
              ...paymentMethod,
              contact: {
                ...paymentMethod.contact,
                phoneNumber: {
                  ...paymentMethod.contact.phoneNumber,
                  value: sanitizedPhoneNumber,
                },
              },
            }));
          },
        },
      },
    },

    membership: {
      firstName: {
        sort: 1,
        label: "Member First Name",
        value: "",

        // This attribute should be 50% width.
        style: {
          flex: "1 0 calc(50% - 10px)",
          marginRight: "10px",
        },

        additionalProps: {
          autoComplete: "given-name",
          name: "member given-name",
        },
      },

      lastName: {
        sort: 1,
        label: "Member Last Name",
        value: "",

        // This attribute should be 50% width.
        style: {
          flex: "1 0 calc(50% - 10px)",
        },

        additionalProps: {
          autoComplete: "family-name",
          name: "member family-name",
        },
      },

      email: {
        sort: 2,
        label: "E-mail",
        value: "",

        isValid: true,
        errorMessage: "Invalid email address.",

        additionalProps: {
          autoComplete: "email",
          name: "member email",
        },
      },

      phoneNumber: {
        sort: 3,
        label: "Mobile Phone #",
        value: "",

        additionalProps: {
          autoComplete: "tel",
          name: "member tel",

          onChange: ({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) => {
            const sanitizedPhoneNumber = value.replace(/\D/g, "");

            setPaymentMethod((paymentMethod) => ({
              ...paymentMethod,
              membership: {
                ...paymentMethod.membership,
                phoneNumber: {
                  ...paymentMethod.membership.phoneNumber,
                  value: sanitizedPhoneNumber,
                },
              },
            }));
          },
        },
      },

      address1: {
        sort: 4,
        label: "Address",
        value: "",

        additionalProps: {
          autoComplete: "shipping street-address",
          name: "member address",
        },
      },

      city: {
        sort: 5,
        label: "City",
        value: "",

        additionalProps: {
          autoComplete: "shipping locality",
          name: "member locality",
        },
      },

      zipCode: {
        sort: 6,
        label: "Zip Code",
        value: "",

        // This attribute should be 50% width.
        style: {
          flex: "1 0 calc(50% - 10px)",
          marginRight: "10px",
        },

        additionalProps: {
          type: "tel",

          autoComplete: "shipping postal-code",
          name: "member zip",

          onChange: ({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) => {
            const sanitizedZipCode = value.replace(/\D/g, "");

            setPaymentMethod((paymentMethod) => ({
              ...paymentMethod,
              membership: {
                ...paymentMethod.membership,
                zipCode: {
                  ...paymentMethod.membership.zipCode,
                  value: sanitizedZipCode,
                },
              },
            }));
          },
        },
      },

      state: {
        sort: 7,
        label: "State",
        value: "",

        // This attribute should be 50% width.
        style: {
          flex: "1 0 calc(50% - 10px)",
        },

        additionalProps: {
          maxLength: 2,

          autoComplete: "shipping region",
          name: "member shipping region",
        },
      },
    },
  });

  // Autofill cardholder information
  const autofillCardholderInformation = useCallback(() => {
    setPaymentMethod((paymentMethod) => ({
      ...paymentMethod,
      address: {
        address1: {
          ...paymentMethod.address.address1,
          value: cardholder ? cardholder.streetAddress1 : "",
        },
        city: {
          ...paymentMethod.address.city,
          value: cardholder ? cardholder.city : "",
        },
        zipCode: {
          ...paymentMethod.address.zipCode,
          value: cardholder ? cardholder.zipCode : "",
        },
        state: {
          ...paymentMethod.address.state,
          value: cardholder ? cardholder.state : "",
        },
      },
    }));
  }, [cardholder]);

  // Autofill membership information to match payment information.
  const autoFillMembershipInformation = useCallback(() => {
    setPaymentMethod((paymentMethod) => ({
      ...paymentMethod,
      membership: {
        // Spread whatever we don't touch.
        ...paymentMethod.membership,

        // Copy information over from contact info.
        firstName: {
          ...paymentMethod.membership.firstName,
          value: paymentMethod.contact.firstName.value,
        },

        lastName: {
          ...paymentMethod.membership.lastName,
          value: paymentMethod.contact.lastName.value,
        },

        email: {
          ...paymentMethod.membership.email,
          value: paymentMethod.contact.email.value,
        },

        phoneNumber: {
          ...paymentMethod.membership.phoneNumber,
          value: paymentMethod.contact.phoneNumber.value,
        },

        // Copy information over from address information
        address1: {
          ...paymentMethod.membership.address1,
          value: paymentMethod.address.address1.value,
        },

        city: {
          ...paymentMethod.membership.city,
          value: paymentMethod.address.city.value,
        },

        zipCode: {
          ...paymentMethod.membership.zipCode,
          value: paymentMethod.address.zipCode.value,
        },

        state: {
          ...paymentMethod.membership.state,
          value: paymentMethod.address.state.value,
        },
      },
    }));
  }, []);

  // Update any specific value.
  // # TODO => paymentSection name should be keyof PaymentMethod.
  const updatePaymentMethod = useCallback(
    (paymentSectionName: string, key: string, value: string) => {
      setPaymentMethod((paymentMethod) => ({
        ...paymentMethod,
        [paymentSectionName]: {
          ...paymentMethod[paymentSectionName],
          [key]: {
            ...paymentMethod[paymentSectionName][key],
            value,
          },
        },
      }));

      // If this is set to match billing membership, autofill.
      if (hasBillingMatchMembership) {
        autoFillMembershipInformation();
      }
    },
    [hasBillingMatchMembership, autoFillMembershipInformation]
  );

  // On blur, we will want to validate this payment.
  const validatePaymentMethod = useCallback(() => {
    const validationErrors = validateCreditCard(paymentMethod);

    // Spread over any errors.
    const validatedPaymentMethod = {
      ...paymentMethod,
      ...Object.entries(validationErrors).reduce(
        (acc, [paymentMethodField, paymentMethodFieldValue]) => ({
          ...acc,
          [paymentMethodField]: {
            ...paymentMethod[paymentMethodField],
            ...Object.entries(paymentMethodFieldValue).reduce(
              (
                acc,
                [
                  paymentMethodFieldAttribute,
                  paymentMethodFieldAttributeIsValid,
                ]
              ) => ({
                ...acc,
                [paymentMethodFieldAttribute]: {
                  ...paymentMethod[paymentMethodField][
                    paymentMethodFieldAttribute
                  ],
                  isValid: paymentMethodFieldAttributeIsValid,
                },
              }),
              {}
            ),
          },
        }),
        {}
      ),
    };

    setPaymentMethod(validatedPaymentMethod);
  }, [paymentMethod]);

  // On focus, reset validation state to valid.
  const resetPaymentMethodValidation = useCallback(
    (key: string) => {
      if (paymentMethod.payment[key]) {
        setPaymentMethod((paymentMethod) => ({
          ...paymentMethod,
          payment: {
            ...paymentMethod.payment,
            [key]: {
              ...paymentMethod.payment[key],
              isValid: true,
            },
          },
        }));
      }
    },
    [paymentMethod]
  );

  // On billing membership match status change to true, autofill.
  useEffect(() => {
    if (hasBillingMatchMembership) {
      autoFillMembershipInformation();
    }
  }, [hasBillingMatchMembership, autoFillMembershipInformation]);

  return {
    // Actual object that we are recording.
    paymentMethod,

    // If membership should match billing
    hasBillingMatchMembership,

    // Methods to update the state of this object.
    updatePaymentMethod,
    autofillCardholderInformation,
    autoFillMembershipInformation,
    validatePaymentMethod,
    resetPaymentMethodValidation,
    setHasBillingMatchMembership,
  };
};
