import { useLocation } from "react-router-dom";

type QueryType = {
  id: string;
  eventId: string;
  orderItemId: string;
  redirect: string;
  u: string;
  x: string;
  offerCode: string;
  orderId: string;
  step: number;
};

/** A custom hook that builds on useLocation to parse query params. */
export const useQueryParams = (): QueryType => {
  const query = new URLSearchParams(useLocation().search);

  // All possible query params that could be appended to the URL
  const params = {
    id: query.get("i"), // ID for mobile login via magic link
    eventId: query.get("eventId"), // Event for redirect to order from SMS ticketing
    orderItemId: query.get("orderItemId"), // Order item for redirect to order from SMS ticketing
    redirect: query.get("r"), // path for redirect after log in
    u: query.get("u"), // Encrypted member id for login via marketing magic link
    x: query.get("x"), // Encrypted link expiration date for login via marketing magic link
    offerCode: query.get("o"), // Offer code for renewal discount
    orderId: query.get("orderId"), // Order id to view kiosk confirmation
    step: parseInt(query.get("step")), // Step of the kiosk to view
  };

  return params;
};
