import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "../../hooks/useQueryParams";
import {
  getMobileTicketing,
  getMarketingLogin,
} from "../../services/authenticationService";

export const MobileLogin: React.FC = () => {
  const history = useHistory();
  const { id, eventId, orderItemId, redirect, u, x, offerCode } =
    useQueryParams();

  useEffect(() => {
    const authenticate = async () => {
      try {
        // If id param is present, that means it is a mobile login generated by SMS site
        if (id) {
          await getMobileTicketing(id);

          // If this is a link generated for SMS via sms.barnesfoundation
          // redirect towards associated order, otherwise push to homepage.
          let path;

          if (eventId && orderItemId) {
            path = `/user/my-orders/${eventId}/${orderItemId}`;
          } else if (redirect) {
            if (redirect === "/renew") {
              path = "/user/settings/renew";

              if (offerCode) {
                path = path + `?o=${offerCode}`;
              }
            } else {
              path = redirect;
            }
          } else {
            path = "/user/home";
          }

          history.push(path);

          // If u and x params are present, that means this is a marketing link generated by the acoustic sync
        } else if (u && x) {
          await getMarketingLogin(u, x, redirect);

          if (redirect) {
            history.push(redirect);
          } else {
            history.push("/user/home");
          }
        }
      } catch (e) {
        history.push("/");
      }
    };

    authenticate();
  }, [history, id, eventId, orderItemId, u, x, redirect, offerCode]);

  return <></>;
};
