import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./scss/styles.scss";
import App from "./App";
//import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

/*
const onSuccess = (registration: ServiceWorkerRegistration) => {
	serviceWorker.initalizePushNotification();
}

const onUpdate = (registration: ServiceWorkerRegistration) => {
	console.log(`Service worker was updated`, registration);
}
serviceWorker.register({
	onSuccess,
	onUpdate
});

if (navigator && navigator.serviceWorker && navigator.serviceWorker.register) {
	navigator.serviceWorker
		.register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`, { scope: '/' })
		.then(function (registration) {
			console.log("Registration successful, scope is:", registration.scope);
		})
		.catch(function (err) {
			console.log("Service worker registration failed, error:", err);
		});
}
*/
