import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { HelmetContext } from "../contexts/helmetContext";
import {
  META_DESCRIPTION,
  CANONICAL_ROOT,
  META_IMAGE,
  META_PLACENAME,
} from "../constants";

const canonicalUrl = CANONICAL_ROOT + window.location.pathname;

type SiteHelmetProps = {};
export const SiteHelmet: React.FC<SiteHelmetProps> = () => {
  // # TODO => Use react router information to determine what meta tags to render.
  // # TODO => This context can probably be removed entirely.
  const {
    helmetData: { title },
  } = useContext(HelmetContext);

  return (
    <Helmet>
      {/** Title */}
      <meta name="title" content={title} />
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={title} />

      {/** Description */}
      <meta name="description" content={META_DESCRIPTION} />
      <meta property="og:description" content={META_DESCRIPTION} />

      {/** Location */}
      <meta name="geo.placename" content={META_PLACENAME} />

      {/** Other */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image" content={META_IMAGE} />

      {/* <!-- Acoustic Campaign Web Tracking Code --> */}
      <meta
        name="com.silverpop.brandeddomains"
        content="www.pages03.net,barnesfoundation.org,members.barnesfoundation.org,store.barnesfoundation.org"
      />
      <script
        src="https://www.sc.pages03.net/lp/static/js/iMAWebCookie.js?703f9e3c-14396b6e80f-943e27de0c8b91cc3fcf1475c3e5d726&h=www.pages03.net"
        type="text/javascript"
      ></script>
    </Helmet>
  );
};
