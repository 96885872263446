import React, { useEffect } from "react";
import classnames from "classnames";
import { LevelCarouselItem } from "../../../../common/payloads";

interface CarouselItemProps {
  children: any;
  onClick: (data: any, index: number) => void;
  appliedOffer?: string;
  levelItem: LevelCarouselItem;
  selected: boolean;
  index: number;
  handleScroll: (i: number) => void;
}

export const CarouselItem: React.FC<CarouselItemProps> = ({
  children,
  onClick,
  appliedOffer,
  levelItem,
  selected,
  index,
  handleScroll,
}: CarouselItemProps) => {
  const { level, type, applyOffer, offer, buttonText } = levelItem;
  const title = level.name;
  const price = offer.priceLists[0].prices[0].price;
  const flag = buttonText === "Renew";

  // Handles scrolling to the selected item on component render
  useEffect(() => {
    if (selected) {
      handleScroll(index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClick = () => {
    onClick({ level, type, offer, appliedOffer }, index);
  };

  return (
    <div className="carousel-item-container">
      <div
        className={classnames("carousel-item", {
          "carousel-item__selected-level": selected,
        })}
      >
        {flag && <div className="level-flag">Current Level</div>}

        <div
          className={classnames({
            "carousel-item__content-flag": flag,
            "carousel-item__content": !flag,
          })}
        >
          <div className="carousel-item__header">
            <h2>{title}</h2>
            {applyOffer ? (
              <div className="offer-code">
                <del>
                  $
                  {level.offerings[0].priceLists[0].prices[0].price.substring(
                    0,
                    price.length - 3
                  )}
                </del>{" "}
                <br />
                <p>
                  <small>With offer code {appliedOffer}:</small> $
                  {price.substring(0, price.length - 3)}
                </p>
              </div>
            ) : (
              <p>${price.substring(0, price.length - 3)}</p>
            )}
          </div>

          <div className="carousel-item__body">{children}</div>
        </div>
        <div className="carousel-item__footer">
          <button className="btn-barnes" onClick={handleOnClick}>
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};
