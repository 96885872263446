import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

import { UserContext } from "../contexts/userContext";
import { useQueryParams } from "../hooks/useQueryParams";

type MemberStandingProps = {
  path: string | string[];
  children: any;
};

/** Wrapper component to redirect non active members to the renew page */
export const ActiveMemberRoute = ({ path, children }: MemberStandingProps) => {
  const { membershipStanding } = useContext(UserContext);
  let redirectPath = "/user/settings/renew";

  // Include offer code in the redirect link if one was present in the original path
  const { offerCode } = useQueryParams();
  if (offerCode) redirectPath = redirectPath + "?o=" + offerCode;

  return (
    <Route path={path}>
      {membershipStanding ? (
        <>
          {membershipStanding === "active" ? (
            children
          ) : (
            <Redirect to={redirectPath} />
          )}
        </>
      ) : (
        children
      )}
    </Route>
  );
};
