import React from "react";
import DayPicker, { DayModifiers } from "react-day-picker";

const getFirstDate = (days: Date[]) => {
  const sortedDays = days.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
  return sortedDays.length ? sortedDays[0] : null;
};

type EventDetailDayPickerProps = {
  activeDays: Date[];
  selectedDay: Date;
  setSelectedDay: (date: Date, modifiers: DayModifiers) => void;
};
export const EventDetailDayPicker: React.FC<EventDetailDayPickerProps> = ({
  activeDays,
  selectedDay,
  setSelectedDay,
}: EventDetailDayPickerProps) => (
  <DayPicker
    onDayClick={setSelectedDay}
    selectedDays={[selectedDay]}
    disabledDays={(day) =>
      !activeDays.some(
        (activeDay) =>
          activeDay.getDate() === day.getDate() &&
          activeDay.getMonth() === day.getMonth() &&
          activeDay.getFullYear() === day.getFullYear()
      )
    }
    month={getFirstDate(activeDays)}
  />
);
