import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthErrorContext } from "../contexts/authErrorContext";

/**
 * Component that handles 403 errors inside of application by redirecting to splash.
 */
export const AuthWrapper: React.FC = ({ children }: any) => {
  const history = useHistory();
  const [isAuthError, setIsAuthError] = useState(false);

  useEffect(() => {
    if (isAuthError) {
      history.push("/");
    }
  }, [history, isAuthError]);

  return (
    <AuthErrorContext.Provider
      value={{
        isAuthError,
        setIsAuthError,
      }}
    >
      {children}
    </AuthErrorContext.Provider>
  );
};
