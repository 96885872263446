import React, { useRef } from "react";

import { CardHeader } from "./cardHeader";
import { CardImage } from "./cardImage";

type HorizontalCardProps = {
  title: string;
  src?: string;
  subtitle?: string;
  children?: any;
  link?: string;
  internalLink?: boolean;
};
export const HorizontalCard: React.FC<HorizontalCardProps> = ({
  title,
  src,
  subtitle,
  children,
  link,
  internalLink,
}: HorizontalCardProps) => {
  const imageWrapperRef = useRef<HTMLDivElement>();

  return (
    <div className="horizontal-card">
      <div className="horizontal-card__header">
        <CardImage
          isHorizontalCard={true}
          header={title}
          src={src}
          imageOffset={0}
          link={link}
          internalLink={internalLink}
        />
      </div>
      <div className="horizontal-card__body">
        {/** Drop in text or HTML */}
        <CardHeader
          imageWrapperHeight={
            imageWrapperRef.current
              ? imageWrapperRef.current.offsetHeight
              : null
          }
          header={title}
          subheader={subtitle}
          link={link}
          internalLink={internalLink}
        />
        {children && <div className="horizontal-card__summary">{children}</div>}
      </div>
    </div>
  );
};
