import { executePayloadRequest } from "./httpService";

/**
 * Get artwork of the day.
 * @returns {ArtObject} - Art object from server.
 * @throws - @see httpService
 */
export const getArtworkOfTheDay: () => Promise<ArtObject> = async () =>
  executePayloadRequest<ArtObject>({
    url: "/api/collection/artwork-of-the-day",
    method: "GET",
  });
