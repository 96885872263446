import React from "react";

import { AccordionStep } from "../../../constants/enum";
import { formatSingleDate } from "../../../util/formatting";
import { RenewalItem } from "./renewalItem";

type ConfirmationType = {
  updatedMember?: {
    membershipStartDate: string;
    membershipExpirationDate: string;
    externalMembershipId: string;
  };
  accordionStep: number;
  setAccordionStep: (accordionStep: number) => void;
  issuedGuestPasses: boolean;
  orderNumber: string;
};

export const Confirmation: React.FC<ConfirmationType> = ({
  updatedMember,
  accordionStep,
  setAccordionStep,
  issuedGuestPasses,
  orderNumber,
}: ConfirmationType) => {
  return (
    <RenewalItem
      title={"Renewal Confirmation"}
      isActive={accordionStep <= AccordionStep.RenewalConfirmation}
      onClick={() => {
        setAccordionStep(AccordionStep.RenewalConfirmation);
      }}
      isDeactivated={accordionStep < AccordionStep.RenewalConfirmation}
      className={
        accordionStep === AccordionStep.Start
          ? "renewal__item--no-height"
          : null
      }
    >
      <h1>Thank you for your support!</h1>
      <p>
        <strong>Order Number: {orderNumber}</strong>
      </p>
      <p>
        You will receive a confirmation of your renewal via email. Your new
        benefits start on{" "}
        {updatedMember && formatSingleDate(updatedMember.membershipStartDate)},
        and expire end of day on{" "}
        {updatedMember &&
          formatSingleDate(updatedMember.membershipExpirationDate)}
        .{" "}
        {!issuedGuestPasses &&
          "Your new guest passes will be issued in 1-2 business days."}{" "}
      </p>
      <p>Member ID: {updatedMember && updatedMember.externalMembershipId}</p>
      <a className="btn-barnes" href="/user/home">
        Return to Member Portal
      </a>
    </RenewalItem>
  );
};
