import { OrderPayload } from "acme-ticketing-client";
import React, { Fragment } from "react";
import { formatSingleDate } from "../../util/formatting";
import { KioskReceiptDetail, KioskTicketDetail } from "./kioskTicketDetails";
import { useQR } from "../../hooks/customHooks";

type KioskTicketReceiptProps = {
  order: OrderPayload;
};

const TicketQR: React.FC<{ uuid: string }> = ({ uuid }: { uuid: string }) => {
  const ticketQR = useQR(JSON.stringify({ uuid }));
  return <img className="print__qr" src={ticketQR} alt="qrcode" />;
};

const PrintContent: React.FC<{ children: JSX.Element; footer?: JSX.Element }> =
  ({ children, footer }: { children: JSX.Element; footer: JSX.Element }) => (
    <div className="print">
      <div className="print__header">
        <h1 id="tenant-name">The Barnes Foundation</h1>
        <h2 id="tenant-address">
          2025 Benjamin Franklin Parkway <br />
          Philadelphia, PA 19130 <br />
          Phone: 215.278.7200
        </h2>
      </div>
      <div className="print__content">{children}</div>
      <div className="print__footer">
        {footer && footer}
        {formatSingleDate(new Date(), "mm/dd/yyyy h:MM TT")} Terminal#: Kiosk
      </div>
    </div>
  );

export const KioskTicketReceipt: React.FC<KioskTicketReceiptProps> = ({
  order,
}: KioskTicketReceiptProps) => {
  const footer = (
    <Fragment>
      Order #: {order.orderNumber}
      <br />
    </Fragment>
  );

  return (
    <Fragment>
      {order && (
        <Fragment>
          {/* Order receipt */}
          <PrintContent footer={footer}>
            <KioskReceiptDetail order={order} />
          </PrintContent>

          {/* Print each admission ticket individually */}
          {order.eventItems
            .find((eventItem) => eventItem.eventName === "Admission")
            .items.map((item) =>
              item.tickets.map((ticket, index) => {
                const ticketCardClassName = "kiosk-ticket-details";
                const eventItem = order.eventItems[0];
                const eventDate =
                  eventItem && eventItem.eventDate
                    ? new Date(eventItem.eventDate)
                    : null;

                return (
                  <Fragment key={index}>
                    <div className="pagebreak"></div>
                    <PrintContent>
                      <div className={`${ticketCardClassName}__content`}>
                        <h2 className={`${ticketCardClassName}__header`}>
                          {eventItem.eventName}
                        </h2>

                        <h2 className={`${ticketCardClassName}__date`}>
                          {formatSingleDate(eventDate)} at{" "}
                          {formatSingleDate(eventDate, "h:MM TT")}
                        </h2>

                        {/* Ticket Type */}
                        <h2 className={`${ticketCardClassName}__detail`}>
                          {item.quantity} {item.ticketTypeName}
                        </h2>

                        <h3 className={`${ticketCardClassName}`}>
                          Order {order.orderNumber}
                        </h3>

                        {/* QR code */}
                        <TicketQR uuid={ticket.uuid} />

                        <div>
                          Please only scan one ticket per party while moving
                          throughout the galleries.
                        </div>
                      </div>
                    </PrintContent>
                  </Fragment>
                );
              })
            )}
        </Fragment>
      )}
    </Fragment>
  );
};
