import React, { Fragment, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import TagManager from "react-gtm-module";

import { AuthWrapper } from "./components/authWrapper";
import { MobileLogin } from "./components/login/mobileLogin";
import { SplashScreen } from "./components/splash/splash";
import { Vip } from "./components/vip";
import { Kiosk } from "./components/kiosk/kiosk";
import { NotFound } from "./components/notFound";
import { Content } from "./components/content";
import { Error } from "./components/shared/error";
import { SiteHelmet } from "./components/siteHelmet";
import { StyleGuide } from "./components/styleGuide";
import { HelmetContext, HelmetData } from "./contexts/helmetContext";
import { AuthContext } from "./contexts/authContext";
import { ScrollLockContext } from "./contexts/scrollLockContext";
import { META_TITLE } from "./constants";
import { useScrollLock } from "./hooks/customHooks";
import { KioskContextProvider } from "./contexts/kioskContext";

const tagManagerArgs = {
  gtmId: "GTM-NSXG2B8",
};
TagManager.initialize(tagManagerArgs);

const App: React.FC = () => {
  const [, lockScroll] = useScrollLock();
  const [helmetData, setHelmetData] = useState<HelmetData>({
    title: META_TITLE,
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [permissions, setPermissions] = useState({
    ticketQuantityLimit: true,
    renewal: false,
    guestPasses: false,
    loginLapsed: false,
    loginDropped: false,
  });
  const [permsGroup, setPermsGroup] = useState();

  // # TODO => Correctly apply translation to nested content.
  // let appStyle: React.CSSProperties = {};

  /*
    if (isScrollLocked) {
        // appStyle = {
        //     ...appStyle,
        //     
        // };
  }
  */

  return (
    <ScrollLockContext.Provider value={{ lockScroll }}>
      <HelmetContext.Provider value={{ helmetData, setHelmetData }}>
        <AuthContext.Provider
          value={{
            isAuthenticated,
            setIsAuthenticated,
            permissions,
            setPermissions,
            permsGroup,
            setPermsGroup,
          }}
        >
          <BrowserRouter>
            <AuthWrapper>
              <KioskContextProvider>
                <SiteHelmet />
                <div className="app">
                  <Switch>
                    <Route exact path="/">
                      <SplashScreen />
                    </Route>
                    <Route path={["/mobileLogin", "/ml"]}>
                      <MobileLogin />
                    </Route>
                    <Route path="/vip">
                      <Vip />
                    </Route>
                    <Route path="/kiosk">
                      <Kiosk />
                    </Route>
                    <Route path={["/user", "/event"]}>
                      <Content />
                    </Route>
                    <Route path="*">
                      {isAuthenticated ? (
                        <div className="app__content">
                          <NotFound />
                        </div>
                      ) : (
                        <Redirect to="/vip" />
                      )}
                    </Route>
                    {process.env.NODE_ENV !== "production" && (
                      <Fragment>
                        <Route path="/style-guide">
                          <StyleGuide />
                        </Route>
                        <Route path="/error">
                          <div className="app__content">
                            <Error />
                          </div>
                        </Route>
                      </Fragment>
                    )}
                    <Route>
                      {isAuthenticated ? (
                        <Redirect to="/user/home" />
                      ) : (
                        <Redirect to="/" />
                      )}
                    </Route>
                  </Switch>
                </div>
              </KioskContextProvider>
            </AuthWrapper>
          </BrowserRouter>
        </AuthContext.Provider>
      </HelmetContext.Provider>
    </ScrollLockContext.Provider>
  );
};

export default App;
