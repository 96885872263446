import React, { Fragment, useEffect, useState } from "react";
import { IdleTimerProvider } from "react-idle-timer";
import { EventDetailPayload } from "../../../common/payloads";
import { useKiosk, AccordionStep } from "../../contexts/kioskContext";

type KioskModalsProps = {
  eventDetailPayload: EventDetailPayload;
  showAvailabilityError: boolean;
  setShowAvailabilityError;
};

type KioskModalProps = {
  header: string;
  body?: string;
  dismissable?: boolean;
  onContinue?: () => void;
  continueText?: string;
  onBack?: () => void;
  backText?: string;
  children?: React.ReactNode;
  countDown?: string;
};

export const KioskModal: React.FC<KioskModalProps> = ({
  header,
  body,
  dismissable,
  onContinue,
  continueText = "Continue",
  onBack,
  backText = "Back",
  children,
  countDown,
}: KioskModalProps) => {
  return (
    <div className="kiosk-modal">
      <div className="kiosk-modal__content">
        {countDown && <h1>{countDown}</h1>}
        <h1>{header}</h1>
        {body && <h3>{body}</h3>}
        {children && children}
        {dismissable && (
          <Fragment>
            <div className="kiosk-modal__buttons">
              {onContinue && (
                <button className="btn-barnes-secondary" onClick={onContinue}>
                  {continueText}
                </button>
              )}
            </div>
            {onBack && (
              <button
                className="a-basic-link kiosk-modal__back"
                onClick={onBack}
              >
                {backText}
              </button>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export const KioskModals: React.FC<KioskModalsProps> = ({
  eventDetailPayload,
  showAvailabilityError,
  setShowAvailabilityError,
}: KioskModalsProps) => {
  const [countdownString, setCountdownString] = useState("");
  const {
    showCCInstructions,
    showTimeoutError,
    setShowTimeoutError,
    accordionStep,
    handleTimeOut,
    setStepTickets,
    authPurchase,
    showErrorModal,
    resetKiosk,
    showCancelPinpadInstructions,
    setShowCancelPinpadInstructions,
    orderState,
  } = useKiosk();

  const TIMEOUT_SECONDS = 30;

  useEffect(() => {
    let seconds = TIMEOUT_SECONDS;
    const countdownInterval = setInterval(() => {
      if (seconds === 0) {
        clearInterval(countdownInterval);
        setCountdownString("");
      } else {
        setCountdownString(seconds.toString());
        seconds--;
      }
    }, 1000);
    return () => {
      clearInterval(countdownInterval);
    };
  }, [showTimeoutError]);

  // Handles when user selects just close the modal
  const handleContinueOrder = () => {
    setShowTimeoutError(false);
  };

  // Handles canceling an order
  const handleCancelOrder = () => {
    setShowAvailabilityError(false);
    resetKiosk(eventDetailPayload);
  };

  // Handles when user selects to go back in the CC Instructions modal
  const handleBackToTickets = () => {
    setShowAvailabilityError(false);
    setShowCancelPinpadInstructions(false);
    handleTimeOut();
    setStepTickets();
  };

  // Handles when the user cancels the pinpad and then chooses to continue their order
  const onContinuePinpadCancel = () => {
    authPurchase();
    setShowCancelPinpadInstructions(false);
  };

  return (
    <>
      {/* Displays when general error occurs */}
      {showErrorModal && (
        <IdleTimerProvider
          timeout={1000 * TIMEOUT_SECONDS}
          onIdle={handleCancelOrder}
        >
          <KioskModal
            header="Oops!"
            body="Something went wrong. Please check in with the box office for assistance. Thank you!"
            dismissable={true}
            onContinue={handleCancelOrder}
            continueText="OK"
          />
        </IdleTimerProvider>
      )}
      {/* Displays if user attempts to exceed maximum available tickets */}
      {showAvailabilityError && (
        <IdleTimerProvider
          timeout={1000 * TIMEOUT_SECONDS}
          onIdle={handleCancelOrder}
        >
          <KioskModal
            header="Oops!"
            dismissable={true}
            onContinue={handleBackToTickets}
            continueText="Back to ticket selection"
          >
            <p>
              There are only {orderState.selectedTime.availableSeats} available
              tickets for this time slot. Please reduce your ticket quantity,
              select another time slot, or see a staff member for assistance.
            </p>
          </KioskModal>
        </IdleTimerProvider>
      )}
      {/* Displays when the pinpad is active */}
      {showCCInstructions && (
        <KioskModal header="Almost there!" dismissable={false}>
          <p>
            Please follow the instructions on the pin pad below to continue the
            booking process.
          </p>
          <p>
            To cancel your order, press the red cancel button on the pin pad.{" "}
          </p>
        </KioskModal>
      )}
      {/* Displays when cancel is clicked on the pin pad*/}
      {showCancelPinpadInstructions && (
        <KioskModal
          header="Pin pad cancelled!"
          body="Please click Back to return to ticket selection or click Continue to complete your transaction using the pin pad."
          dismissable={true}
          onContinue={onContinuePinpadCancel}
          continueText="Continue to payment"
          onBack={handleBackToTickets}
          backText="Back to ticket selection"
        />
      )}
      {/* KIOSK TIMEOUT MODALS */}
      {showTimeoutError && !showCCInstructions && (
        <IdleTimerProvider
          timeout={1000 * TIMEOUT_SECONDS}
          onIdle={handleCancelOrder}
        >
          <KioskModal
            countDown={`The kiosk will reset in ${countdownString} seconds`}
            header="Need some help?"
            dismissable={true}
            onContinue={handleContinueOrder}
            continueText={
              accordionStep === AccordionStep.OrderConfirmation
                ? "View Confirmation"
                : accordionStep === AccordionStep.ArrivalTime
                ? "Continue time selection"
                : accordionStep === AccordionStep.TicketType
                ? "Continue ticket selection"
                : accordionStep === AccordionStep.Payment
                ? "Continue placing order"
                : "Continue"
            }
            {...(accordionStep === AccordionStep.OrderConfirmation && {
              body: `Please click View Confirmation to review your order details. The kiosk will reset in ${countdownString} seconds`,
              onBack: handleCancelOrder,
              backText: "Start new order",
            })}
          />
        </IdleTimerProvider>
      )}
    </>
  );
};
