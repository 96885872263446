import { Card } from "./card";
import { EventSummaryPayload } from "../../../common/payloads";
import { HeroBanner } from "../shared/heroBanner";
import React from "react";
import { ReactComponent as SadFace } from "./icons/sad-face.svg";
import { Link, useLocation } from "react-router-dom";

type EventsProps = {
  events: EventSummaryPayload[];
  rootPath: string;
  src: string;
};
export const EventList: React.FC<EventsProps> = ({
  events,
  rootPath,
  src,
}: EventsProps) => {
  const location = useLocation();

  return (
    <div className="wrapper" id={rootPath}>
      <HeroBanner src={src} header={rootPath}></HeroBanner>
      {location.pathname.includes("event") && (
        <div className="center">
          <p>
            View the full calendar of events{" "}
            <a
              className="a-brand-link"
              href="https://www.barnesfoundation.org/whats-on"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </p>
        </div>
      )}
      <div className="container">
        <div className="card-list">
          {events.length ? (
            events.map(({ id, name, images, shortDescription }, i) => (
              <Card
                className="card"
                key={`${i}${rootPath}`}
                title={name}
                src={
                  Boolean(images.length && images[0].screen)
                    ? images[0].screen
                    : null
                }
                description={shortDescription}
                to={{
                  pathname: `/event/${id}`,
                  state: {
                    name,
                    html: shortDescription,
                    src:
                      images.length && images[0].screen
                        ? images[0].screen
                        : null,
                  },
                }}
              />
            ))
          ) : (
            <div className="general__error">
              <SadFace className="general__sad-face" />
              <span className="general__error-text">
                There are currently no available events.
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
