import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import parse from "html-react-parser";
import { gql } from "graphql-request";

import { AccordionStep } from "../../../constants/enum";
import { Carousel } from "../../shared/carousel/carousel";
import { RenewalItem } from "./renewalItem";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { UserContext } from "../../../contexts/userContext";
import { EligibleMembershipLevel } from "../../../../common/payloads";
import { ModalProps } from "../../shared/modal";
import { ModalContext } from "../../../contexts/modalContext";
import { getFormattedLevels } from "../../../util/renewalHelpers";
import { AuthContext } from "../../../contexts/authContext";
import {
  getContactInfo,
  ContactInfoType,
  performGraphCmsRequest,
} from "../../../services/graphCmsService";

type LevelSelectorType = {
  eligibleLevels: EligibleMembershipLevel[];
  onClick: (data: any) => void;
  setErrorMessage: (errorMessage: string) => void;
  setErrorModal: (data: ModalProps) => void;
  accordionStep: number;
  setAccordionStep: (accordionStep: number) => void;
};

export const LevelSelector: React.FC<LevelSelectorType> = ({
  eligibleLevels,
  onClick,
  setErrorMessage,
  setErrorModal,
  accordionStep,
  setAccordionStep,
}: LevelSelectorType) => {
  const { offerCode } = useQueryParams();
  const [showCoreBenefits, setShowCoreBenefits] = useState(false);
  const [levels, setLevels] = useState([]);
  const { membershipStanding } = useContext(UserContext);
  const isActive = membershipStanding === "active";
  const [initialIndex, setInitialIndex] = useState(0);
  const history = useHistory();
  const { setShowModal } = useContext(ModalContext);
  const [couponCode, setCouponCode] = useState("");
  const [couponValid, setCouponValid] = useState(false);
  const [appliedOffer, setAppliedOffer] = useState<string | undefined>();
  const [contactInfo, setContactInfo] = useState<ContactInfoType>();
  const { permsGroup } = useContext(AuthContext);
  const [coreBenefits, setCoreBenefits] = useState({ content: { html: "" } });

  useEffect(() => {
    const fetchBenefits = async () => {
      const query = gql`
        query getMemberRenewOffers {
          memberRenewOffers {
            memberLevel
            content {
              html
            }
          }
        }
      `;

      const { memberRenewOffers } = await performGraphCmsRequest(query);
      const allBenefits = memberRenewOffers.filter(
        (o) => o.memberLevel === "All"
      );
      setCoreBenefits(allBenefits[0]);
    };

    fetchBenefits();
  }, [setCoreBenefits]);

  /**
   * @param {string} offer Offer/Coupon code to be applied
   * @returns {boolean} Whether or not the offer/coupon code was applied
   */
  const applyOffer = useCallback(
    (offer: string): boolean => {
      let offerValid;

      // Create formatted objects for rendering
      const [formattedLevels, renewIndex] = getFormattedLevels(
        eligibleLevels,
        offer
      );

      // Set error message if no levels were returned
      if (!formattedLevels) {
        setErrorMessage(
          `<p>Your membership is not eligible for renewal at this time.</p>`
        );
        setErrorModal({
          buttonText: isActive ? "Return to Member Portal" : undefined,
          onClick: () => history.push("/user/home"),
        });
        setShowModal(true);
      } else {
        setLevels(formattedLevels);
        setInitialIndex(renewIndex);

        // Check to see if the offer was applied to any levels
        if (offer) {
          offerValid = formattedLevels.find(
            (level) => level.applyOffer === true
          );

          // Set error message if offer code was not valid
          if (!offerValid) {
            setErrorMessage(
              `<p>An error ocurred while applying the offer code to your membership renewal!</p><p>Please contact <a href="mailto:${
                contactInfo ? contactInfo.email : "members@barnesfoundation.org"
              }">${
                contactInfo ? contactInfo.email : "members@barnesfoundation.org"
              }</a> or <a href="tel:${
                contactInfo ? contactInfo.phone : "215-287-7100"
              }">${
                contactInfo ? contactInfo.phone : "215-287-7100"
              }</a> for assistance.</p>`
            );
            setErrorModal({
              dismissible: true,
              dismissText: "Continue renewal without offer",
              buttonText: isActive ? "Return to Member Portal" : undefined,
              onClick: () => history.push("/user/home"),
            });
            setShowModal(true);
          }
        }
      }

      return !!offerValid;
    },
    [
      eligibleLevels,
      setErrorMessage,
      setErrorModal,
      setLevels,
      setInitialIndex,
      history,
      isActive,
      setShowModal,
      contactInfo,
    ]
  );

  // Handler for applying offer code from input
  const handleApplyCoupon = (event) => {
    event.preventDefault();
    const couponValid = applyOffer(couponCode);
    couponValid && setAppliedOffer(couponCode);
    couponValid ? setCouponValid(true) : setCouponValid(false);
  };

  // Handler for coupon code input
  const handleCouponChange = (event) => {
    setCouponCode(event.target.value);
  };

  // Format levels for rendering and set error messages
  useEffect(() => {
    if (eligibleLevels) {
      const offerValid = applyOffer(offerCode);
      offerValid && setAppliedOffer(offerCode);
    }
  }, [offerCode, eligibleLevels, applyOffer]);

  // Fetch Barnes contact info
  useEffect(() => {
    const fetchContactInfo = async () => {
      const contact = await getContactInfo(permsGroup);
      setContactInfo(contact);
    };

    permsGroup && fetchContactInfo();
  }, [permsGroup, setContactInfo]);

  const toggleCoreBenefits = () => {
    setShowCoreBenefits(!showCoreBenefits);
  };

  const handleCouponCode = (event) => {
    document.getElementById("coupon-code").focus();
  };

  return (
    <RenewalItem
      title={"Select Membership Level"}
      isActive={accordionStep <= AccordionStep.MembershipLevel}
      onClick={() => {
        setAccordionStep(AccordionStep.MembershipLevel);
      }}
      isDeactivated={
        accordionStep < AccordionStep.MembershipLevel ||
        accordionStep === AccordionStep.RenewalConfirmation
      }
      className={
        accordionStep === AccordionStep.Start
          ? "renewal__item--no-height"
          : null
      }
      id="membership-level"
    >
      {(!offerCode || !appliedOffer) && (
        <a className="a-basic-link" onClick={handleCouponCode}>
          <br />
          Have a coupon code? Enter it below.
          <br />
        </a>
      )}
      <Carousel
        levels={levels}
        onClick={onClick}
        initialItem={initialIndex}
        appliedOffer={appliedOffer}
      />
      {(!offerCode || !appliedOffer) && (
        <div className="coupon-code">
          <div className="coupon-code__container">
            <div className="coupon-code__input">
              <label htmlFor="coupon-code" className="coupon-code__label">
                Coupon Code
              </label>
              <input
                id="coupon-code"
                type="text"
                placeholder="Enter coupon code"
                className="form__input"
                onChange={handleCouponChange}
              />
            </div>
            <button
              className="coupon-code__button"
              type="submit"
              value="Apply"
              onClick={handleApplyCoupon}
            >
              Apply
            </button>
          </div>
          {couponValid && <p>Discount applied!</p>}{" "}
        </div>
      )}
      <div className="core-benefits__container">
        <button onClick={toggleCoreBenefits} className="btn-barnes-secondary">
          View All Core Membership Benefits
        </button>
        {showCoreBenefits && (
          <div className="core-benefits">
            {parse(coreBenefits.content.html)}
          </div>
        )}
      </div>
    </RenewalItem>
  );
};
