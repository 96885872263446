import { PaymentMethod } from "../eventDetailCheckIn/usePaymentMethod";

type StripeError = {
  message: string;
  errorCode: number;
  type: string;
};

export type PaymentError = [keyof PaymentMethod | "general", string] | void;

/**
 * Determine what error to render.
 * @see https://developers.acmeticketing.com/support/solutions/articles/33000248662-api-response-codes-and-error-messages
 * for stripe error codes.
 * @param {StripeError} error error from server.
 * @returns {[keyof PaymentMethod, string] | void} tuple of location of error with message or void if there is no
 * discernable error.
 */
export const handlePaymentErrors = ({
  message,
  errorCode,
}: StripeError): PaymentError => {
  // if this is an address error.
  if (errorCode === 6113) {
    return ["address", message];
  }

  if (errorCode > 6100 && errorCode < 6115) {
    return ["payment", message];
  }

  if (message) {
    return ["general", message];
  }
};
