import { InputFieldType } from "../../../../common/types";
import React from "react";
import classnames from "classnames";

interface InputProps {
  field: InputFieldType;
  className?: string;
  onFocus?: () => void;
  onClick?: () => void;
}

export const Input: React.FC<InputProps> = ({
  field,
  className,
  onFocus,
  onClick,
}: InputProps) => {
  return (
    <div className={classnames("form__input-wrapper", className)}>
      {field.label && (
        <label htmlFor={field.attributes.id}>{field.label}</label>
      )}
      <input
        value={field.maskedValue ? field.maskedValue : field.value}
        className={classnames("form__input", {
          "form__input--error": !field.isValid,
        })}
        {...field.attributes}
        name={field.attributes.id}
        autoComplete="off"
        placeholder={field.placeholder}
        maxLength={field.maxLength}
        onFocus={onFocus}
        onClick={onClick}
      />
      <div className={"form__input--message"}>
        {!field.isValid && field.errorMessage}
      </div>
    </div>
  );
};
