import React, { useState } from "react";
import { ReactComponent as BarnesLogo } from "./icons/barnesLogo.svg";
import { formatSingleDate } from "../../util/formatting";

export type TicketItem = {
  displayName: string;
  quantity: number;
};

type TicketProps = {
  thumbnail?: string | void;
  eventName?: string;
  eventDate?: Date | string;
  includeTime?: boolean;
  checkInDate?: string;
  ticketItems: TicketItem[];
  isList?: boolean;
  isConversion?: boolean;
};
export const TicketCard: React.FC<TicketProps> = ({
  thumbnail,
  eventName,
  eventDate,
  includeTime = false,
  checkInDate,
  ticketItems,
  isList = false,
  isConversion = false,
}: TicketProps) => {
  const [isImageError, setIsImageError] = useState(false);

  let ticketCardClassName = "ticket-card";
  if (isList) {
    ticketCardClassName = `${ticketCardClassName} ticket-card--listed`;
  }

  if ((!thumbnail || isImageError) && !isConversion) {
    ticketCardClassName = `${ticketCardClassName} ticket-card--error`;
  }

  return (
    <div className={ticketCardClassName}>
      <div className="ticket-card__thumbnail-wrapper">
        {thumbnail && !isConversion && (
          <img
            className="ticket-card__thumbnail"
            src={thumbnail}
            alt={eventName}
            onError={() => setIsImageError(true)}
          />
        )}
        {(!thumbnail || isImageError) && !isConversion && <BarnesLogo />}
      </div>
      <div className="ticket-card__event-copy">
        {eventName && (
          <h3 className="ticket-card__event-header">{eventName}</h3>
        )}
        {eventDate && (
          <h4 className="ticket-card__event-date">
            {formatSingleDate(eventDate)}
          </h4>
        )}
        {eventDate && includeTime && (
          <h4 className="ticket-card__event-date">
            {formatSingleDate(eventDate, "h:MM TT")}
          </h4>
        )}
        {ticketItems.map((orderItem) => (
          <div className="ticket-card__item" key={orderItem.displayName}>
            <span className="ticket-card__event-detail">
              {orderItem.quantity} {orderItem.displayName}
            </span>
          </div>
        ))}
        {checkInDate && (
          <span className="ticket-card__event-detail ticket-card__event-detail--expiration-date">
            Redeemed on: {formatSingleDate(checkInDate)}
          </span>
        )}
      </div>
    </div>
  );
};
