import { Input } from "./input";
import { InputFieldType } from "../../../../common/types";
import React from "react";
import classnames from "classnames";

interface FormProps {
  header?: string;
  className?: string;
  icon?: JSX.Element;
  children: any;
}

export const Form: React.FC<FormProps> = ({
  header,
  className,
  icon,
  children,
}: FormProps) => {
  return (
    <div className="input-form">
      <div className="input-form__header-wrapper">
        <h2 className="input-form__header">
          {header && header}
          {icon && icon}
        </h2>
      </div>
      <div className="input-form__form">
        <form className={classnames("form", className)}>{children}</form>
      </div>
    </div>
  );
};

interface FormSectionProps {
  fields: InputFieldType[];
  className?: string;
  autoPopulate?: (any) => void;
  autoPopulateText?: string;
  autoPopulateVal?: boolean;
}

export const FormSection: React.FC<FormSectionProps> = ({
  fields,
  className,
  autoPopulate,
  autoPopulateText,
  autoPopulateVal,
}: FormSectionProps) => {
  return (
    <div className={classnames("form__section", className)}>
      {autoPopulate && (
        <div className="form__input-wrapper autopopulate-checkbox">
          <input
            type="checkbox"
            id="autopopulate"
            value={autoPopulateVal && autoPopulateVal.toString()}
            onClick={autoPopulate}
          />
          <label htmlFor="autopopulate">
            {autoPopulateText && autoPopulateText}
          </label>
        </div>
      )}
      {fields.map((field, i) => (
        <Input key={i} field={field} />
      ))}
    </div>
  );
};
