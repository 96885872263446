import React from "react";
import { SpinnerSuspense } from "../shared/spinner";
import classnames from "classnames";

type KioskButtonsProps = {
  handleContinue: React.MouseEventHandler<HTMLButtonElement>;
  onClickBack: React.MouseEventHandler<HTMLButtonElement>;
  disabled: boolean;
  isLoaded: boolean;
  continueText: string;
  backText: string;
  handleReset: React.MouseEventHandler<HTMLButtonElement>;
};

export const KioskButtons: React.FC<KioskButtonsProps> = ({
  handleContinue,
  onClickBack,
  disabled = false,
  isLoaded = true,
  continueText,
  backText,
  handleReset,
}: KioskButtonsProps) => {
  return (
    <div className="kiosk-buttons">
      <div className="nav-buttons">
        <button
          className="btn-barnes-secondary"
          onClick={onClickBack}
          type="button"
        >
          {backText}
        </button>
        <button
          className={classnames("btn-barnes", {
            "btn-barnes--disabled": disabled || !isLoaded,
          })}
          onClick={handleContinue}
          disabled={disabled || !isLoaded}
          type="submit"
          id="btn-continue"
        >
          <SpinnerSuspense isLoaded={isLoaded} className="check-in__spinner">
            {continueText}
          </SpinnerSuspense>
        </button>
      </div>
      <button
        className="btn-plain"
        id="btn-reset"
        onClick={handleReset}
        type="reset"
      >
        Start New Order
      </button>
    </div>
  );
};
