import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Order } from "acme-ticketing-client";
import { TicketCard } from "../shared/ticketCard";
import AuthenticateService from "../../services/authenticationService";
import { SpinnerSuspense } from "../shared/spinner";

type OrderItem = {
  eventName: string;
  displayName: string;
  quantity: number;
  eventStartTime: number;
};
type EventDetailOrderConfirmationProps = {
  order?: Order;
  thumbnail: string | void;
  guestPassExchangeCount: number;
  isVIPCheckout: boolean;
};
export const EventDetailOrderConfirmation: React.FC<EventDetailOrderConfirmationProps> =
  ({
    order,
    thumbnail,
    guestPassExchangeCount,
    isVIPCheckout,
  }: EventDetailOrderConfirmationProps) => {
    const [link, setLink] = useState("");
    const [isError, setIsError] = useState(false);

    const as = useRef(new AuthenticateService());

    useEffect(() => {
      if (isVIPCheckout && order && order.billingEmail && order.membershipId) {
        const fetchLink = async () => {
          // Update state on error from server.
          try {
            const { link } = await as.current.authenticateWithIdentifier(
              order.billingEmail,
              false
            );

            if (link) {
              setLink(link);
            }
          } catch (e) {
            setIsError(true);
          }
        };

        fetchLink();
      }
    }, [isVIPCheckout, order]);

    // This object has a keys that represent assigned to an array of order items.
    // In theory there should only be one key as this is used for one event, however this component
    // can handle multiple events.
    const orderItemsObject: { [key: string]: OrderItem[] } =
      order &&
      order.orderItems.reduce(
        (acc, { eventName, displayName, quantity, eventStartTime }) => {
          const item: OrderItem = {
            eventName,
            displayName,
            quantity,
            eventStartTime,
          };

          return {
            ...acc,
            [eventName]: acc[eventName] ? [...acc[eventName], item] : [item],
          };
        },
        {}
      );

    return (
      <div className="order-confirmation">
        <div className="order-confirmation__container order-confirmation__container--border">
          {/** Header */}
          <h2 className="order-confirmation__header">
            {order && `Order #${order.orderNumber}`}
          </h2>
          <p className="order-confirmation__copy">
            Thank you for your online purchase!
          </p>

          {/** Event section card */}
          {orderItemsObject &&
            Object.values(orderItemsObject).map((orderItems, i) => {
              const eventDate =
                orderItems.length && orderItems[0].eventStartTime
                  ? new Date(orderItems[0].eventStartTime)
                  : null;

              return (
                <TicketCard
                  key={i}
                  thumbnail={thumbnail}
                  eventName={orderItems.length ? orderItems[0].eventName : null}
                  eventDate={eventDate}
                  ticketItems={orderItems.map(({ displayName, quantity }) => ({
                    displayName,
                    quantity,
                  }))}
                />
              );
            })}

          <div className="order-confirmation__total">
            {order && `Total: $${order.paidAmount}`}
          </div>
          {Boolean(guestPassExchangeCount) && (
            <div className="order-confirmation__guest-pass-wrapper">
              <span className="order-confirmation__guest-pass">
                {guestPassExchangeCount} guest passes were exchanged for
                tickets.
              </span>
            </div>
          )}
        </div>

        <div className="order-confirmation__container">
          <div className="order-confirmation__copy-wrapper">
            <p className="order-confirmation__copy order-confirmation__copy--sub">
              You will receive an email confirmation shortly.
            </p>
            {order &&
              order.membershipId &&
              (isVIPCheckout && !isError ? (
                <a href={link}>
                  <button className="btn-barnes">
                    <SpinnerSuspense
                      isLoaded={Boolean(!isVIPCheckout || link)}
                      className="check-in__spinner"
                    >
                      Take Me to My Orders
                    </SpinnerSuspense>
                  </button>
                </a>
              ) : (
                <Link to={"/user/my-orders"}>
                  <button className="btn-barnes">Take Me to My Orders</button>
                </Link>
              ))}
          </div>
        </div>
      </div>
    );
  };
