import { LoggerBody } from "../../common/payloads";
import { executePayloadRequest } from "./httpService";

/** Wrapper around the executePayloadRequest fn for the logger to ensure the body
 * has the correct formatting
 * @param {LoggerBody} body
 */
export const addServerLog = async (body: LoggerBody) => {
  executePayloadRequest({
    url: "/logger",
    method: "POST",
    body: JSON.stringify(body),
  });
};
