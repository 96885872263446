import React, { Fragment } from "react";
import classnames from "classnames";
import { ReactComponent as Arrow } from "./eventDetail/icons/arrow.svg";

type AccordionContainerProps = {
  title: string;
  isActive?: boolean;
  isDeactivated?: boolean;
  onClick?: () => void;
  selectionText?: string;
  className?: string;
  childClassName?: string;
  headerStyle?: React.CSSProperties;
  children: any;
};
export const AccordionContainer: React.FC<AccordionContainerProps> = ({
  title,
  isActive = true,
  isDeactivated,
  onClick,
  selectionText,
  className,
  childClassName,
  children,
  headerStyle,
}: AccordionContainerProps) => {
  let arrowClassName = "event-detail__header-arrow";
  let childrenClassName = "event-detail__item";
  let headerClassName = "event-detail__header";

  if (isActive) {
    arrowClassName = `${arrowClassName} event-detail__header-arrow--active`;
    childrenClassName = `${childrenClassName} event-detail__item--active`;
  }

  if (isDeactivated) {
    headerClassName = `${headerClassName} event-detail__header--deactivated`;
    childrenClassName = `${childrenClassName} event-detail__item--deactivated`;
  }

  if (className) {
    childrenClassName = `${childrenClassName} ${className}`;
  }

  return (
    <Fragment>
      <button
        className={classnames(headerClassName, className)}
        onClick={!isDeactivated && onClick ? onClick : null}
        style={headerStyle || {}}
      >
        {/* {selectionText || title} */}
        {title}
        <span className="event-detail__selection-text">{selectionText}</span>
        <Arrow className={arrowClassName} />
      </button>

      <div className={classnames(childrenClassName, childClassName)}>
        {children}
      </div>
    </Fragment>
  );
};
