import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { EventTemplateB2C, Order } from "acme-ticketing-client";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

import * as VipService from "../../../services/vipService";
import * as apiRequestor from "../../../services/apiRequestor";
import {
  MembershipDetails,
  MembershipLevelIdentifier,
  MembershipPurchase,
  PurchaseItem,
} from "../../../../common/payloads";
import { IPromotion } from "../../../../common/vipPayloads";
import {
  PaymentError,
  handlePaymentErrors,
} from "../helpers/paymentErrorHandler";
import { CheckInCounter } from "./checkInCounter";
import { CheckInDisclaimer } from "./checkInDisclaimer";
import { EventDetailConversion } from "./eventDetailConversion";
import { EventDetailMembershipUpsell } from "./eventDetailMembershipUpsell";
import { EventDetailPaymentForm } from "./eventDetailPaymentForm";
import { EventDetailRecaptchaButton } from "./eventDetailRecaptchaButton";
import { getOrderConfirmationData } from "../helpers/gtmDataLayerHelper";
import { handleAcmeErrors } from "../helpers/acmeErrorHandler";
import { upsellPriceCalculator } from "../helpers/upsellPriceCalculator";
import { usePaymentMethod } from "./usePaymentMethod";
import { PromotionMessage } from "./promotionMessage";
import { AuthContext } from "../../../contexts/authContext";
import { COMMUNITY } from "../../../constants/permissions";
import {
  createItemsObject,
  filterPurchaseItems,
  getDiscountQuantity,
  getStandardQuantity,
  EventCategoryType,
} from "../helpers/updatePurchaseItemHelper";

type EventDetailCheckInProps = {
  prices: EventTemplateB2C["priceList"]["prices"];
  eventInfo: Partial<PurchaseItem> & {
    eventTime?: string;
    eventCategory?: string;
  };
  setOrder: (order: Order, guestPassExchangeCount: number) => void;
  cardholder?: MembershipDetails["cardholders"][0];
  isVIPCheckout?: boolean;
  membershipLevels?: MembershipLevelIdentifier[];
  recaptchaExecute: () => Promise<string>;
  availableSeats: number;
  setTotalTicketCount;
  totalTicketCount: number;
};

export const EventDetailCheckIn: React.FC<EventDetailCheckInProps> = ({
  prices,
  eventInfo,
  setOrder,
  cardholder,
  isVIPCheckout,
  membershipLevels,
  recaptchaExecute,
  availableSeats,
  setTotalTicketCount,
  totalTicketCount,
}: EventDetailCheckInProps) => {
  const { permsGroup, permissions } = useContext(AuthContext);
  const [availabilityError, setAvailabilityError] = useState(false);
  const [ticketQuantityLimitError, setTicketQuantityLimitError] =
    useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [paymentError, setPaymentError] = useState<PaymentError>(undefined);

  const { paymentMethod, hasBillingMatchMembership, ...payMethodCallbacks } =
    usePaymentMethod(cardholder);
  const [upsell, setUpsell] = useState<MembershipLevelIdentifier | void>(null);
  const [optIn, setOptIn] = useState(false);

  const [promotion, setPromotion] = useState<IPromotion | null>(null);
  const [transactionQualifies, setTransactionQualifies] = useState<
    boolean | null
  >(null);

  // This reduce function creates an object where all potential Ticket Types have a corresponding PurchaseItem.
  const [purchaseItems, setPurchaseItems] = useState<{
    [key: string]: PurchaseItem;
  }>(
    prices.reduce(
      (acc, { personType: { name, id }, price }) => ({
        ...acc,
        [name]: {
          ...eventInfo,
          ticketingTypeName: name,
          ticketingTypeId: id,
          quantity: 0,
          unitPrice: `${price}.00`,
        },
      }),
      {}
    )
  );

  const [promoItem, setPromoItem] = useState<PurchaseItem | null>(null);

  // Get the offer using the code from the query params on mount
  const queries = new URLSearchParams(useLocation().search);

  const [ticketQuantityLimit, setTicketQuantityLimit] =
    useState(availableSeats);
  const [applyTicketLimit, setApplyTicketLimit] = useState(false); // Whether or not a limit on discounted tickets should be applied based on the membership type

  // Update purchaseItems for community pass members with the standard prices on component mount
  useEffect(() => {
    if (permsGroup === COMMUNITY) {
      const standardItems = prices.reduce(
        (acc, { personType: { name, id }, discount }) => ({
          ...acc,
          [`${name}_Standard`]: {
            ...eventInfo,
            ticketingTypeName: name,
            ticketingTypeId: id,
            quantity: 0,
            unitPrice: `${discount}.00`,
          },
        }),
        {}
      );

      setPurchaseItems({ ...purchaseItems, ...standardItems });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const offerCode = queries.get("offer");

    /** Fetch the promotion for the offer code and set it into state */
    const fetchPromotion = async () => {
      const promotion = await VipService.retrievePromotion(offerCode);
      setPromotion(promotion);

      // Check if this event time falls within the promotion - otherwise it'd never be eligible
      const promoStart = new Date(
        new Date(promotion.qualifyingConditions.visitStartDate).toLocaleString(
          "en-US",
          { timeZone: "America/New_York" }
        )
      ).getTime();
      const promoEnd = new Date(
        new Date(promotion.qualifyingConditions.visitEndDate).toLocaleString(
          "en-US",
          { timeZone: "America/New_York" }
        )
      ).getTime();
      const eventTime = new Date(
        new Date(eventInfo.eventTime).toLocaleString("en-US", {
          timeZone: "America/New_York",
        })
      ).getTime();
      setTransactionQualifies(false);

      // The event doesn't fall in the promo window so it'll never qualify
      if ((promoStart <= eventTime && eventTime < promoEnd) === false) {
        setTransactionQualifies(null);
      }
    };

    // If we have an offer, let's fetch the promotion
    if (offerCode) {
      fetchPromotion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check if the promotion applies - only when we have a promotion
  // and the purchase has items
  useEffect(() => {
    // Only check the items when the transaction could possibly qualify
    // If it's still null by this point when we have a promotion available
    // it means the transaction doesn't fall in the promo window
    if (promotion && transactionQualifies !== null) {
      const purchase = filterPurchaseItems(
        purchaseItems,
        permsGroup === COMMUNITY
      );
      // Sum up the tickets from the order that qualify for the promo
      const qualTicketCount = purchase.items.reduce((acc, item) => {
        const { ticketingTypeName, quantity } = item;

        // Store this ticket type if it qualifies for the promo
        if (
          promotion.qualifyingConditions.ticketTypes.includes(ticketingTypeName)
        ) {
          acc += quantity;
        }

        return acc;
      }, 0);

      // Set up the promotion item to be added to the cart when the transaction qualifies
      let promotionItem = promotion.redemptionItems.hasOwnProperty(
        "addOnItemId"
      )
        ? {
            // Add-on redemption takes precedence over the event template/ticket type redemptions
            itemType: "Inventory",
            itemId: promotion.redemptionItems.addOn.id.toString(),
            inventoryId: promotion.redemptionItems.addOn.id,
            inventoryName: promotion.redemptionItems.addOn.name,
            admissionType: "standard",
            unitPrice: promotion.redemptionItems.addOn.price,
            amount: (
              promotion.redemptionItems.addOn.quantity *
              parseInt(promotion.redemptionItems.addOn.price)
            ).toFixed(2),
            quantity: promotion.redemptionItems.quantity,
            ignoreEntitlements: false,
            printTicket: true,
          }
        : {
            itemType: "Inventory",
            eventId: promotion.redemptionItems.eventTemplateId,
            quantity: promotion.redemptionItems.quantity,
            ticketingTypeId: promotion.redemptionItems.ticketTypeId,
            ignoreEntitlements: false,
            unitPrice: "0.00",
          };

      // Transaction qualifies once the minimum quantity of tickets is met
      if (qualTicketCount >= promotion.qualifyingConditions.minQuantity) {
        console.log("Transaction qualifies for promotion");

        if (transactionQualifies === false) {
          // Set the promotion item to later be added to cart
          setPromoItem(promotionItem);
          setTransactionQualifies(true);
        }
      } else {
        console.log("Transaction does not qualify for promotion");

        if (transactionQualifies === true) {
          // If the promotion item exists in the purchase items, remove it
          if (promoItem) {
            setPromoItem(null);
            setTransactionQualifies(false);
          }
        }
      }
    }
  }, [promotion, transactionQualifies, promoItem, purchaseItems, permsGroup]);

  // We are only going to update our quantity for the specific name, which will be lexically scoped inside of our
  // update callback.
  const updatePurchaseItems = useCallback(
    (name: string, quantity: number) => {
      let items = { ...purchaseItems }; // Current list of purchase items, before adding new ticket total
      // Update quantity of the item to the new total
      items[name] = { ...purchaseItems[name], quantity };

      // If there is a max ticket limit (Community pass), add additional tickets over limit to
      // Standard ticket price group while ensuring lowest price
      if (permissions.ticketLimits) {
        const ticketLimit =
          eventInfo.eventCategory === "Art Courses"
            ? permissions.ticketLimits.classes
            : permissions.ticketLimits.events;
        items = createItemsObject(
          items,
          ticketLimit,
          eventInfo.eventCategory as EventCategoryType
        );
      } else {
        items = {
          ...items,
          [name]: {
            ...purchaseItems[name],
            quantity,
          },
        };
      }

      setPurchaseItems(items);
    },
    [permsGroup, purchaseItems, eventInfo]
  );

  // Total price of order.
  const totalPrice = useMemo(() => {
    const purchase = filterPurchaseItems(
      purchaseItems,
      permsGroup === COMMUNITY
    );
    return purchase.items.reduce(
      (acc, { unitPrice, quantity }) => acc + parseInt(unitPrice) * quantity,
      0
    );
  }, [purchaseItems, permsGroup]);

  // Get individual ticket totals
  const getQuantity = useCallback(
    (name: string, type: "Standard" | "Discount" | "Combined"): number => {
      switch (type) {
        case "Standard":
          return getStandardQuantity(purchaseItems, name);
        case "Discount":
          return getDiscountQuantity(purchaseItems, name);
        case "Combined":
          return purchaseItems[name] ? purchaseItems[name].quantity : 0;
      }
    },
    [purchaseItems]
  );

  // Determine if user is over available ticket quantity
  useEffect(() => {
    const purchase = filterPurchaseItems(
      purchaseItems,
      permsGroup === COMMUNITY
    );
    const total = purchase.items.reduce(
      (acc, { quantity }) => acc + quantity,
      0
    );
    setTotalTicketCount(total);
  }, [purchaseItems, permsGroup]);

  useEffect(() => {
    if (permissions.ticketLimits) {
      if (eventInfo.eventCategory === "Art Courses") {
        setTicketQuantityLimit(permissions.ticketLimits.classes);
      } else {
        setTicketQuantityLimit(permissions.ticketLimits.events);
      }
    } else setTicketQuantityLimit(availableSeats);

    setApplyTicketLimit(permsGroup === COMMUNITY);
  }, [
    setTicketQuantityLimit,
    ticketQuantityLimit,
    availableSeats,
    totalTicketCount,
    permsGroup,
    setApplyTicketLimit,
    eventInfo,
    permissions,
  ]);

  // If booking requires payment, we must take the extra step of retrieving payment details.
  // If there are no tickets with an actual price, we can just book tickets without needing to take payment.
  const purchaseRequiresPayment = totalPrice > 0; // TODO: use this constant, if purchaseRequiresPayment just get contact info, no payment
  /** Called when a valid order is being processed */
  const checkOut = async (
    includesGuestPassExchange: boolean,
    token: string,
    includesMarketingOptIn?: boolean
  ) => {
    try {
      const billingEmail = cardholder
        ? cardholder.email
        : paymentMethod.contact.email.value;
      const billingPhoneNumber = cardholder
        ? cardholder.phoneNumber
        : paymentMethod.contact.phoneNumber.value;
      const streetAddress1 = cardholder
        ? cardholder.streetAddress1
        : paymentMethod.address.address1.value;
      const membershipId = cardholder ? cardholder.membershipId : undefined;
      const billingFirstName = cardholder
        ? cardholder.firstName
        : paymentMethod.payment.firstName.value;
      const billingLastName = cardholder
        ? cardholder.lastName
        : paymentMethod.payment.lastName.value;
      const country = "United States"; // this is fixed for right now.
      // const cardholderName = cardholder ? cardholder.name : `${paymentMethod.contact.firstName.value} ${paymentMethod.contact.lastName.value}`;
      const city =
        cardholder && cardholder.city
          ? cardholder.city
          : paymentMethod.address.city.value;
      const state = cardholder
        ? cardholder.state
        : paymentMethod.address.state.value;
      const zipCode =
        cardholder && cardholder.zipCode
          ? cardholder.zipCode
          : paymentMethod.address.zipCode.value;

      const purchase = filterPurchaseItems(
        purchaseItems,
        permsGroup === COMMUNITY
      );

      const cartItems: { items: (MembershipPurchase | PurchaseItem)[] } = upsell
        ? {
            items: [
              {
                quantity: 1,
                itemType: "MembershipPurchase",
                membershipInfo: {
                  membershipCategoryId: upsell.identifiers.membershipCategoryId,
                  membershipOfferingId: upsell.identifiers.membershipOfferingId,
                  pricePointId: upsell.identifiers.pricePointId,
                  isGift: false,
                  membershipCards: [
                    {
                      cardType: "primary",
                      city: paymentMethod.membership.city.value,
                      country: country,
                      email: paymentMethod.membership.email.value,
                      firstName: paymentMethod.membership.firstName.value,
                      lastName: paymentMethod.membership.lastName.value,
                      phoneNumber: paymentMethod.membership.phoneNumber.value,
                      state: paymentMethod.membership.state.value,
                      streetAddress1: streetAddress1,
                      streetAddress2: "",
                      zipCode: paymentMethod.membership.zipCode.value,
                    },
                  ],
                },
              },

              ...purchase.items,
            ],
          }
        : purchase;

      // Add the promotion item to the cart items
      if (promotion && transactionQualifies) {
        cartItems.items.push(promoItem as any);
      }

      // Spread purchase, add membership id from primary cardholder.
      const shoppingCart = {
        ...cartItems,
        membershipId: membershipId ? membershipId.toString() : undefined,
        membershipIds: membershipId ? [membershipId.toString()] : undefined,
      };

      // 1A. Make call to /order/create to retrieve the created Checkout Input
      // let checkoutInput: CheckoutInputObject = await apiRequestor.retrieveCheckoutInput(purchase);

      // 1B. Get Checkout Input from cardholder information and purchase.
      let checkoutInput: any /*  CheckoutInputObject */ = {
        billingFirstName,
        billingLastName,
        billingEmail,
        email: billingEmail,
        billingPhoneNumber,
        phoneNumber: billingPhoneNumber,
        address1: streetAddress1,
        city,
        state,
        zipCode,
        country,
        billingAddress1: streetAddress1,
        billingCity: city,
        billingZipCode: zipCode,
        billingState: state,
        billingCountry: country,
        shoppingCart,
        notes: isVIPCheckout
          ? "#vippath" // For checkouts related to VIP path
          : "#MemberPortal", // For all other checkouts
      };

      // 2. If the order required payment, add in any credit card information
      if (purchaseRequiresPayment) {
        // Build the payment input section
        const ccLastFourDigits = paymentMethod.payment.creditCard.value.substr(
          -4,
          4
        );
        const expDate = paymentMethod.payment.expirationDate.value.replace(
          "/",
          ""
        );
        const manualEntryCardNumber =
          paymentMethod.payment.creditCard.value.replace(/ /g, "");

        const paymentInput = {
          creditCardBrand: paymentMethod.payment.creditCard
            .creditCardType as any,
          manualEntryCardNumber,
          cvc: paymentMethod.payment.cvc.value,
          ccLastFourDigits,
          expDate,
        };
        checkoutInput = { ...checkoutInput, ...paymentInput };
      }

      // 3. If the order involved a guest pass exchange, make call to /order/exchange
      // to mark the correct number of guest passes used.

      // Base case is that we can continue a checkout by default.
      // Only when our guest pass exchange fails is when we can't
      let canContinueCheckout = true;
      let guestPassExchangeCount = 0;

      if (includesGuestPassExchange) {
        // If successful, then the checkout can continue
        const { success, checkedInTickets } =
          await apiRequestor.exchangeGuestPasses(purchase);
        canContinueCheckout = success ? true : false;
        guestPassExchangeCount = checkedInTickets.length;
      }

      // If we are allowed to checkout.
      if (canContinueCheckout) {
        // # TODO => Uncomment below code.
        // CORS request is currently not working from ACME's side.
        // const checkoutResponse = config.env === 'development'
        // 	? await apiRequestor.performTemporaryCheckout(checkoutInput)
        // 	: await acmeRequestor.performCORSCheckout(checkoutInput);

        const checkoutResponse = isVIPCheckout
          ? await VipService.performTemporaryCheckout(
              checkoutInput,
              eventInfo.eventTime,
              token
            )
          : await apiRequestor.performTemporaryCheckout(checkoutInput, token);

        setOrder(checkoutResponse, guestPassExchangeCount);

        // Add order information to the GTM data layer
        TagManager.dataLayer(
          getOrderConfirmationData(checkoutResponse, promotion)
        );

        // If the user opted into our marketing campaign, we'll notify the Lambda
        if (includesMarketingOptIn) {
          VipService.performMarketingOptIn(checkoutResponse.orderNumber);
        }
      }
    } catch (e) {
      // Log out error.
      console.error(e);

      // If exception is a payment error, handle it.
      if (e.data && e.data.message && e.data.errorCode) {
        // Determine payment errors and update state.
        const paymentErrors = handlePaymentErrors(e.data);
        setPaymentError(paymentErrors);
      }
    }
  };

  /** Called when someone clicks on the "Book Tickets" button */
  const onBookClick = async (token: string) => {
    // Set up state on click, this will clear any errors and set up our search styling.
    setErrors({});
    setPaymentError(undefined);
    setAvailabilityError(false);
    setTicketQuantityLimitError(false);

    // Check if there's enough seats for this order
    if (totalTicketCount > availableSeats) {
      setAvailabilityError(true);
      if (totalTicketCount > ticketQuantityLimit) {
        setTicketQuantityLimitError(true);
      }
    } else {
      try {
        const streetAddress1 = cardholder
          ? cardholder.streetAddress1
          : paymentMethod.address.address1.value;
        const country = "United States"; // this is fixed for right now.
        const purchase = filterPurchaseItems(
          purchaseItems,
          permsGroup === COMMUNITY
        );

        const cartItems: {
          items: MembershipPurchase[] | PurchaseItem[];
        } = upsell
          ? {
              items: [
                {
                  quantity: 1,
                  itemType: "MembershipPurchase",
                  membershipInfo: {
                    membershipCategoryId:
                      upsell.identifiers.membershipCategoryId,
                    membershipOfferingId:
                      upsell.identifiers.membershipOfferingId,
                    pricePointId: upsell.identifiers.pricePointId,
                    isGift: false,
                    membershipCards: [
                      {
                        cardType: "primary",
                        city: paymentMethod.membership.city.value,
                        country: country,
                        email: paymentMethod.membership.email.value,
                        firstName: paymentMethod.membership.firstName.value,
                        lastName: paymentMethod.membership.lastName.value,
                        phoneNumber: paymentMethod.membership.phoneNumber.value,
                        state: paymentMethod.membership.email.value,
                        streetAddress1: streetAddress1,
                        streetAddress2: "",
                        zipCode: paymentMethod.membership.zipCode.value,
                      },
                    ],
                  },
                },
              ],
            }
          : (purchase as { items: PurchaseItem[] });

        // Receive validation from endpoint
        const {
          orderIsValid,
          acmeValidation,
          guestPassValidation,
          includesGuestPassExchange,
        } = isVIPCheckout
          ? await VipService.validateOrder(cartItems)
          : await apiRequestor.validateOrder(
              cartItems as { items: PurchaseItem[] }
            );

        // If the order is invalid
        if (!orderIsValid) {
          let errors: { [key: string]: string[] } = {};
          let guestPassTicketTypeId: string;

          if (permissions.guestPasses) {
            const guestPass = prices.filter(({ personType: { name } }) =>
              name.includes("Member Guest")
            );
            guestPassTicketTypeId = guestPass[0] && guestPass[0].personType.id;

            // Handle guess pass orders.
            // guestPassValidation will be undefined unless we are using a guest pass, otherwise it will be an object.
            if (
              guestPassValidation &&
              !guestPassValidation.validGuestPassExchange
            ) {
              errors = {
                ...errors,
                [guestPassTicketTypeId]: [guestPassValidation.message],
              };
            }
          }

          // Handle acme errors
          if (!acmeValidation.valid) {
            const acmeErrors = handleAcmeErrors(acmeValidation);

            if (Object.values(acmeErrors).length) {
              // We need to spread over our guest pass errors if they exist and account for potential key collision/overwrite.
              // To account for this, we spread the existing errors[guestPassTicketTypeId] with acmeErrors[guestPassTicketTypeId]
              // into a single array.
              // Spreading (acmeErrors[guestPassTicketTypeId] || []) will prevent spreading undefined.
              errors = {
                ...acmeErrors,
                [guestPassTicketTypeId]: errors[guestPassTicketTypeId]
                  ? [
                      ...errors[guestPassTicketTypeId],
                      ...(acmeErrors[guestPassTicketTypeId] || []),
                    ]
                  : acmeErrors[guestPassTicketTypeId],
              };
            }
          }

          setErrors(errors);
        }

        // Otherwise, order has been validated
        else {
          // Otherwise, we can immediately send the order to ACME for checkout processing
          await checkOut(includesGuestPassExchange, token, optIn);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const toggleOptIn = () => {
    setOptIn(!optIn);
  };

  return (
    <>
      <div className="event-detail__select-area check-in">
        <span className="check-in__availability">
          This time slot has {availableSeats || "no"} ticket
          {availableSeats === 1 ? "" : "s"} available.
        </span>

        {prices.map(
          ({ personType: { name, description, id }, price, discount }) => {
            let checkInTicketTypeClassName = "check-in__ticket-type";
            if (errors[id]) {
              checkInTicketTypeClassName = `${checkInTicketTypeClassName} check-in__ticket-type--guest-pass-error`;
            }

            return (
              <Fragment key={name}>
                <CheckInCounter
                  update={(quantity) => {
                    updatePurchaseItems(name, quantity);
                  }}
                  value={purchaseItems[name].quantity}
                  availableSeats={availableSeats}
                  totalTicketCount={totalTicketCount}
                  name={name}
                  description={description}
                  price={price}
                  discount={discount}
                  className={checkInTicketTypeClassName}
                  applyTicketLimit={applyTicketLimit}
                  ticketQuantityLimit={ticketQuantityLimit}
                  getQuantity={getQuantity}
                  errors={errors[id]}
                  isKiosk={false}
                />
              </Fragment>
            );
          }
        )}
        {totalTicketCount > ticketQuantityLimit && applyTicketLimit && (
          <span className="check-in__discount">
            Note: Your {permsGroup} Member Discount will apply to your first{" "}
            {ticketQuantityLimit} tickets. Standard Prices apply to all others.
          </span>
        )}
      </div>

      {
        /** Display the promotion message if there's an available promotion */
        promotion && // Ensure we have an available promotion
          transactionQualifies !== null && ( // Transaction not being null means the transaction can possibly qualify
            <PromotionMessage
              promotion={promotion}
              transactionQualifies={transactionQualifies}
            />
          )
      }

      {/** Upsell goes here */}
      {isVIPCheckout && membershipLevels && (
        <EventDetailMembershipUpsell
          purchaseItems={purchaseItems}
          totalPrice={totalPrice}
          setUpsell={setUpsell}
          membershipLevels={membershipLevels}
          isKiosk={false}
        />
      )}

      <EventDetailConversion
        purchaseItems={purchaseItems}
        upsellInfo={upsell ? upsell.info : undefined}
        promoItem={promoItem}
        isKioskCheckout={false}
      />

      <div className="event-detail__select-area check-in">
        <div className="check-in__disclaimer">
          <div className="check-in__total">
            Total: $
            {upsell
              ? parseInt(upsell.info.price) +
                upsellPriceCalculator(purchaseItems, upsell.info.name).price
              : totalPrice}
            .00
          </div>
        </div>
        <EventDetailPaymentForm
          mustBeValidated={purchaseRequiresPayment}
          isVIPCheckout={isVIPCheckout}
          isUpsell={Boolean(upsell)}
          hasBillingMatchMembership={hasBillingMatchMembership}
          contactOnly={
            isVIPCheckout && !purchaseRequiresPayment && totalTicketCount > 0
          } // if mustBeValidated is false, and isVIPCheckout is true, then only collect contact info
          paymentMethod={paymentMethod}
          paymentError={paymentError}
          isMemberRenewal={false}
          optIn={optIn}
          toggleOptIn={toggleOptIn}
          {...payMethodCallbacks}
        />
        {(Object.values(errors).length || paymentError) && (
          <span className="check-in__error-message">
            There was a problem with the order:{" "}
            {paymentError
              ? paymentError[1]
              : "please check your ticket selection."}
          </span>
        )}
        {availabilityError && (
          <span className="check-in__error-message">
            Your order of {totalTicketCount} tickets exceeds the number of
            available tickets for this timeslot. There are {availableSeats}{" "}
            tickets remaining. Please lower the number of tickets or select
            another timeslot.
          </span>
        )}
        {ticketQuantityLimitError && !availabilityError && (
          <span className="check-in__error-message">
            Your order of {totalTicketCount} tickets exceeds the number of
            allowed tickets for this visit. You are limited to{" "}
            {ticketQuantityLimit} tickets per visit. Please lower the number of
            tickets or select another timeslot.
          </span>
        )}
        <EventDetailRecaptchaButton
          onBookClick={onBookClick}
          purchase={filterPurchaseItems(
            purchaseItems,
            permsGroup === COMMUNITY
          )}
          purchaseRequiresPayment={purchaseRequiresPayment}
          paymentMethod={paymentMethod}
          recaptchaExecute={recaptchaExecute}
        />
      </div>

      <CheckInDisclaimer />
    </>
  );
};
