import { LogoutIcon } from "./icons";
import { NavLink, useHistory } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";

import AuthenticateService from "../../services/authenticationService";

import { ReactComponent as CloseIcon } from "../shared/icons/close.svg";

import { MenuContext } from "../../contexts/menuContext";
import { AuthContext } from "../../contexts/authContext";
import { ScrollLockContext } from "../../contexts/scrollLockContext";
import { MIDDLE_NAV_LINKS, TOP_NAV_LINKS } from "../../constants/navLinks";

const ANIMATION_TIME = 500;

export const SideMenu: React.FC = () => {
  const authService = useRef(new AuthenticateService());
  const history = useHistory();
  const { permissions } = useContext(AuthContext);
  const { lockScroll } = useContext(ScrollLockContext);

  // For menu activation and animation completion.
  const [isAnimationComplete, setIsAnimationComplete] = useState(true);
  const { isSideMenuActive, setIsSideMenuActive } = useContext(MenuContext);
  // Logout mechanism.
  const logout = async () => {
    try {
      await authService.current.logout();
      history.push("/");
    } catch (e) {
      console.log(e);
    }
  };

  const FILTERED_TOP_NAV_LINKS = TOP_NAV_LINKS.filter((navLink) => {
    if (navLink.permission) {
      // get the user's permissions for the nav link's permission
      return permissions[navLink.permission];
    } else {
      // default to returning true if there are no permissions set for this nav item
      return true;
    }
  });

  // Set up scroll lock on isSideMenuActive change.
  useEffect(() => {
    lockScroll(isSideMenuActive);
  }, [isSideMenuActive, lockScroll]);

  // On menu active change, set animation complete to false.
  // Change the state back to true after animation has complete,
  // approximated roughly with ANIMATION_TIME.
  useEffect(() => {
    let sto: ReturnType<typeof setTimeout>;
    if (!isSideMenuActive) {
      setIsAnimationComplete(false);
      sto = setTimeout(() => {
        setIsAnimationComplete(true);
      }, ANIMATION_TIME);
    } else {
      setIsAnimationComplete(false);
    }
    return () => {
      if (sto) {
        clearTimeout(sto);
      }
    };
  }, [isSideMenuActive]);

  let menuClassName = "menu";
  let overlayClassName = "menu-overlay";
  if (isSideMenuActive) {
    menuClassName = `${menuClassName} menu--active`;
    overlayClassName = `${overlayClassName} menu-overlay--active`;
  }
  if (isAnimationComplete) {
    overlayClassName = `${overlayClassName} menu-overlay--hidden`;
  }

  return (
    <>
      {/** Overlay */}
      <div
        onClick={() => setIsSideMenuActive(false)}
        className={overlayClassName}
      />
      {/** Side Menu */}
      <div className={menuClassName}>
        <div className="menu__inner">
          <div className="menu__content">
            <div className="menu__top-content">
              <button
                className="menu__close"
                onClick={() => setIsSideMenuActive(false)}
              >
                <CloseIcon />
              </button>
            </div>
            <nav className="menu__links">
              {FILTERED_TOP_NAV_LINKS.map(({ href, name, ...props }) => (
                <NavLink
                  key={name}
                  className="menu__item"
                  onClick={() => setIsSideMenuActive(!isSideMenuActive)}
                  to={href}
                  activeClassName="menu__item--active"
                  // Spread props, if they exist.
                  {...props}
                >
                  {name}
                </NavLink>
              ))}
            </nav>
          </div>
          <div className="menu__bottom-content">
            {MIDDLE_NAV_LINKS.map(({ href, name }, i) => (
              <NavLink
                key={name}
                className="menu__item menu__item--bottom"
                onClick={() => setIsSideMenuActive(!isSideMenuActive)}
                to={href}
                activeClassName="menu__item--active"
              >
                <span className={i === 0 ? "menu__bottom-line" : ""}>
                  {name}
                </span>
              </NavLink>
            ))}
            <button
              className="log-out menu__item menu__item--log-out"
              onClick={logout}
            >
              <LogoutIcon className="log-out__icon" />
              <span className="log-out__text">Log Out</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
