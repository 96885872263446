import classnames from "classnames";
import React, { useState } from "react";
import { formatDollarAmount } from "../../../../util/formatting";
import { validateDollarAmount } from "../../../../util/validation";
import { DonationOptions } from "./donationOptions";

type DonationFormProps = {
  includesDonation?: boolean;
  setIncludesDonation?: (includesDonation: boolean) => void;
  donationAmount?: string;
  setDonationAmount?: (donationAmount: string) => void;
  handleAddDonation: (data: any) => void;
};

export const DonationForm: React.FC<DonationFormProps> = ({
  includesDonation,
  setIncludesDonation,
  donationAmount,
  setDonationAmount,
  handleAddDonation,
}: DonationFormProps) => {
  const [invalidDonation, setInvalidDonation] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [otherAmount, setOtherAmount] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!includesDonation && validateDollarAmount(donationAmount)) {
      setIncludesDonation(true);
      const formattedDonationAmount = formatDollarAmount(donationAmount);
      handleAddDonation(formattedDonationAmount);
    } else {
      setIncludesDonation(false);
      setDonationAmount("0");
    }
  };

  const contentWrapperClassName = "value-proposition__content-wrapper";
  const overlayClassName = "value-proposition__overlay";
  const buttonClassName =
    "value-proposition__button value-proposition__button--upsell";

  return (
    <div className="check-in__value-proposition value-proposition-wrapper">
      {/** Confetti 🎊 */}
      <div className="value-proposition__glitter-wrapper">
        {[...Array(30)].map((x, i) => (
          <div
            key={`glitter${i}`}
            className={`value-proposition__glitter--${i}`}
          />
        ))}
      </div>

      <div className="value-proposition value-proposition--clear">
        <div
          className={classnames(contentWrapperClassName, {
            "value-proposition__content-wrapper--active": includesDonation,
          })}
        >
          <div className="value-proposition__content">
            <br />
            <br />
            <h2 className="value-proposition__header">
              Support the Barnes Foundation by making a tax-deductible gift
            </h2>
            <p className="value-proposition__subheader">
              No gift is too small to make a difference. All gifts are fully
              tax-deductible.
            </p>
            <br />
            <br />
            <DonationOptions
              setInvalidDonation={setInvalidDonation}
              setButtonDisabled={setButtonDisabled}
              otherAmount={otherAmount}
              setOtherAmount={setOtherAmount}
              donationAmount={donationAmount}
              setDonationAmount={setDonationAmount}
            />
          </div>

          <div className="value-proposition__thanks">
            <h1 className="value-proposition__header value-proposition__header--thanks">
              Thank You!
            </h1>
            <br />
            <p className="value-proposition__subheader">
              We&apos;ve added your donation to your cart. This year, the Barnes
              celebrates our centennial year and the 10th anniversary of our
              home in the heart of Philadelphia.
              <br />
            </p>

            <p className="value-proposition__subheader">
              Your support of the Barnes&apos;s Annual Fund advances our
              staff&apos;s work to bring the power of art to audiences near and
              far. Your contribution will help sustain our efforts into the
              future.
            </p>
          </div>
        </div>

        <div className="value-proposition__button-wrapper">
          <br />
          <p>{invalidDonation}</p>
          <br />
          <button
            className={classnames(buttonClassName, {
              "value-proposition__button--active": includesDonation,
              "value-proposition__button--error": invalidDonation,
            })}
            onClick={handleSubmit}
            disabled={buttonDisabled}
          >
            {!includesDonation
              ? "Add Donation to Total"
              : "I've changed my mind"}
          </button>
        </div>
      </div>
      <div
        className={classnames(overlayClassName, {
          "value-proposition__overlay--active": includesDonation,
        })}
      />
    </div>
  );
};
