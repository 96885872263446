import * as firebase from "firebase/app";
import "firebase/messaging";
import "firebase/analytics";

/** Initialize the Firebase application */
const initializedFirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyCob6I55blt6_Z36zWqZ7xzmgy6p_P33SY",
  authDomain: "bf-member-site-a8c1d.firebaseapp.com",
  databaseURL: "https://bf-member-site-a8c1d.firebaseio.com",
  projectId: "bf-member-site-a8c1d",
  storageBucket: "bf-member-site-a8c1d.appspot.com",
  messagingSenderId: "337626161789",
  appId: "1:337626161789:web:1a685267ee0bed5f2b4714",
  measurementId: "G-2J99NDT0XC",
});

const isSupported = firebase.messaging.isSupported();
let messaging;

// Initialize Firebase Analytics
const analytics = initializedFirebaseApp.analytics();

// Initialize messaging analytics and messaging if supported
if (isSupported) {
  // Initiialize Cloud Messaging
  messaging = initializedFirebaseApp.messaging();
  messaging.usePublicVapidKey(
    "BBhRa7MvW5hSU1E_SFH57Dshh4xwJEfdBtA3a8qDqVf9i7iI1e4o4R9d9oXsraot6pp7MjKwu3aGnwQOBUUdfSE"
  );
}

export { messaging, analytics, isSupported };
