import React from "react";

const MAIN_WEBSITE_DOMAIN =
  process.env.REACT_APP_WWW_URL || "//www.barnesfoundation.org";

// Static info mapped to static Logo JSX element.
const logoSizes = [
  { size: "s", width: 121, height: 37 },
  { size: "m", width: 146, height: 45 },
  { size: "l", width: 164.958, height: 50 },
  { size: "xl", width: 200, height: 62 },
];

/**
 * JSX element for Barnes logos.
 */

type LogoProps = {
  size: string;
  width?: number;
  height?: number;
  logo?: string;
  className?: string;
};

export const Logo: React.FC<LogoProps> = ({
  size,
  width,
  height,
  logo,
  className,
}: LogoProps) => (
  <svg
    className={`a-logo__svg a-logo__svg--${size} ${className ? className : ""}`}
    width={width}
    height={height}
    aria-labelledby="logo-title"
  >
    <title id="logo-title">Barnes</title>
    <use xlinkHref={`#icon--logo-${logo ? logo : size}`}></use>
  </svg>
);

type LogosProps = {
  link?: string;
};

export const Logos: React.FC<LogosProps> = ({ link }: LogosProps) => (
  <a className="a-logo g-header__logo" href={link ? link : MAIN_WEBSITE_DOMAIN}>
    {logoSizes.map((logo) => (
      <Logo key={logo.size} {...logo} />
    ))}
  </a>
);
