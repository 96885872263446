import { Description, Slider, SliderCard } from "../shared/slider";

import { MembershipDetails } from "../../../common/payloads";
import React from "react";
import { formatSingleDate } from "../../util/formatting";
import { useQR } from "../../hooks/customHooks";

type CardholderProps = {
  cardholder: MembershipDetails["cardholders"][0];
  memberSince: string;
  category: string;
  isARMNMember: boolean;
  membershipId: number;
};

const SingleCardholder: React.FC<CardholderProps> = ({
  cardholder: { acmeBarcode, expirationDate, name },
  memberSince,
  category,
  isARMNMember,
  membershipId,
}: CardholderProps) => {
  // Get QR code from acme.
  const userQR = useQR(JSON.stringify({ acmeBarcode: membershipId }));

  // Modifier is the additional BEM modifier being passed.
  // Value is the actual value from the user object, and fieldName is the key name.
  const descriptions: Description[] = [
    { value: name, modifier: "title" },
    { value: category, modifier: "sub-header" },
    {
      fieldName: "Member Since",
      value: formatSingleDate(memberSince),
      modifier: "detail",
    },
    {
      fieldName: "Card Expiration",
      value: formatSingleDate(expirationDate),
      modifier: "detail",
    },
    {
      fieldName: "Membership ID",
      value: `${membershipId}`,
      modifier: "detail",
    },
  ];

  return (
    Boolean(userQR) && (
      <SliderCard
        qr={userQR}
        descriptions={descriptions}
        includeRoamLogo={isARMNMember}
      />
    )
  );
};

/** Cardholders component, maps users cardholder property. */
type CardholdersProps = { user: MembershipDetails; membershipId };
export const Cardholders = ({ user, membershipId }: CardholdersProps) => (
  <div className="cardholders">
    {user && user.cardholders && user.cardholders.length ? (
      <Slider>
        {user.cardholders.map((cardholder, i) => (
          <SingleCardholder
            key={`${cardholder.acmeBarcode}${i}`}
            cardholder={cardholder}
            memberSince={user.membershipJoinedDate}
            category={user.category}
            isARMNMember={user.isARMNMember}
            membershipId={membershipId}
          />
        ))}
      </Slider>
    ) : (
      <div>No cardholders found.</div>
    )}
  </div>
);
